import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import CollectionContext from '../providers/collection-context';
import MarketplaceContext from '../providers/marketplace-context';
import AuctionContext from '../providers/auction-context';
import { formatCategory, formatPrice } from '../helpers/utils';
import { settings } from '../helpers/settings';
import { useTranslation } from 'react-i18next';
// COMPONENTS
import PageBanner from '../components/general/PageBanner';
import NftItem from '../components/general/NftItem';
import AuctionItem from '../components/general/AuctionItem';
import FullScreenLoader from '../components/general/FullScreenLoader';
import { CATEGORY_EACH_PAGE_NUMS, CATEGORY_INIT_NUMS } from '../helpers/pages';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import InfiniteScroll from 'react-infinite-scroller';
import { GraphqlClient } from '../helpers/graphqlClient';

function Category() {
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const [currentPage] = useState(1);
    const [itemsPerPage] = useState(20);
    const { category } = useParams();
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = collectionCtx.collection.slice(
        indexOfFirstItem,
        indexOfLastItem
    );

    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const { t } = useTranslation(['category']);
    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${formatCategory(category)} | ${
            settings.UISettings.marketplaceBrandName
        }`;
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const loadTokens = async () => {
            const tokens = await getTokens({
                skip: 0,
                first: CATEGORY_INIT_NUMS,
            });

            const { updatedTokens, owners } = updateOwnersAndPrice(tokens);
            const users = await GraphqlClient.getUsersByAccounts(owners);

            const withMetaDataTokens = await Promise.all(
                constructTokens(updatedTokens, users)
            );

            setItems(withMetaDataTokens);
            setHasMore(tokens.length === CATEGORY_INIT_NUMS);
        };

        loadTokens().catch(console.error);
    }, []);

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            if (token.onAuction) {
                const validAuction = token.auctions.filter((auction) => {
                    return auction.active && !auction.cancel;
                })[0];

                const bids = validAuction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                let updatedToken = JSON.parse(JSON.stringify(token));
                updatedToken.auctionId = validAuction.id;
                updatedToken.endTime = parseInt(validAuction.endTime);
                updatedToken.cancelled = validAuction.cancel;
                updatedToken.active = validAuction.active;
                updatedToken.realOwner = validAuction.creator.id;
                updatedToken.bids = bids;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            } else {
                const validOffer = token.offers.filter((offer) => {
                    return !offer.fulfilled && !offer.cancelled;
                })[0];

                let updatedToken = JSON.parse(JSON.stringify(token));

                updatedToken.realOwner = validOffer
                    ? validOffer.creator.id
                    : token.owner.id;
                updatedToken.price = validOffer ? validOffer.price : 0;
                updatedToken.offerId = validOffer ? validOffer.id : null;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            }
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const getTokens = async (paging) => {
        const GET_TOKENS = gql`
            query tokens($skip: Int, $first: Int, $category: String) {
                erc721Tokens(
                    skip: $skip
                    first: $first
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: { category: $category }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
                }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_TOKENS,
            'erc721Tokens',
            paging,
            { category }
        );
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];
            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                if (token.onAuction) {
                    extendedToken = {
                        tokenId: token.id,
                        auctionId: token.auctionId,
                        title: metadata.properties.name.description,
                        img: metadata.properties.file.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        unlockable: metadata.properties.unlockable.description,
                        endAt: token.endTime,
                        bids: token.bids,
                        owner: token.owner.id,
                        cancelled: token.cancel,
                        active: token.active,
                        user: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                    };
                } else {
                    extendedToken = {
                        id: token.id,
                        title: metadata.properties.name.description,
                        file:
                            metadata.properties?.file?.description ||
                            metadata.properties?.image?.description,
                        thumbnail:
                            metadata.properties?.thumbnail?.description ||
                            metadata.properties?.preview?.description ||
                            metadata.properties?.image?.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        unlockable: metadata.properties.unlockable.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        owner: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        price: token.price,
                        offerId: token.offerId,
                    };
                }

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked');
            }
        });
        return result;
    };

    const loadMore = async () => {
        const tokens = await getTokens({
            skip: items.length,
            first: CATEGORY_EACH_PAGE_NUMS,
        });
        const { updatedTokens, owners } = updateOwnersAndPrice(tokens);
        const users = await GraphqlClient.getUsersByAccounts(owners);

        const withMetaDataTokens = await Promise.all(
            constructTokens(updatedTokens, users)
        );
        setItems([...items, ...withMetaDataTokens]);
        setHasMore(tokens.length === CATEGORY_EACH_PAGE_NUMS);
    };

    return (
        <>
            {auctionCtx.fetchingLoading ? (
                <FullScreenLoader heading="Loading" />
            ) : null}
            {auctionCtx.auctionTransactionLoading ? (
                <FullScreenLoader heading="loading" />
            ) : null}
            {marketplaceCtx.mktIsLoading ? (
                <FullScreenLoader heading="loading" />
            ) : null}

            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                initialLoad={false}
            >
                <PageBanner
                    heading={t(`${formatCategory(category)}`, {
                        ns: 'category',
                    })}
                />
                {/* <PageBanner heading={`${formatCategory(category)} NFTs`} /> */}
                <section className="py-5">
                    <div className="container py-5">
                        {items.length !== 0 ? (
                            <>
                                <div className="row gy-4 mb-5 align-items-stretch">
                                    {items.map((NFT, key) => {
                                        if (NFT.auctionId) {
                                            return (
                                                <div
                                                    className="col-xl-4 col-md-6"
                                                    key={key}
                                                >
                                                    <AuctionItem
                                                        {...NFT}
                                                        nftKey={key}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            const index = NFT.offerId
                                                ? NFT.offerId
                                                : -1;
                                            const owner = NFT.owner;
                                            const price =
                                                index !== -1
                                                    ? formatPrice(
                                                          NFT.price
                                                      ).toFixed(2)
                                                    : null;

                                            return (
                                                <div
                                                    className={`col-xl-4 col-md-6 ${NFT.category}`}
                                                    key={NFT.id}
                                                >
                                                    <NftItem
                                                        {...NFT}
                                                        noAnimation={true}
                                                        index={index}
                                                        owner={owner}
                                                        price={price}
                                                        nftKey={key}
                                                        offerPriceBN={NFT.price}
                                                    />
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </>
                        ) : (
                            <div className="text-center">
                                <h4 className="text-center">
                                    {t('noNFT', { ns: 'category' })}
                                </h4>
                                <p className="text-muted mb-3">
                                    {t('onceCreated', { ns: 'category' })}
                                </p>
                                <Link
                                    className="btn btn-gradient-primary"
                                    to="/"
                                >
                                    {t('return', { ns: 'category' })}
                                </Link>
                            </div>
                        )}
                    </div>
                </section>
            </InfiniteScroll>
        </>
    );
}

export default Category;
