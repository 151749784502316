import React, { useContext } from 'react';
import i18n from '../../i18n';
import Button from 'react-bootstrap/Button';
import MarketplaceContext from '../../providers/marketplace-context';

const SwitchLanguageContainer = () => {
    const marketplaceCtx = useContext(MarketplaceContext);

    const handleClick = () => {
        if (i18n.language === 'en') {
            i18n.changeLanguage('cn');
        } else {
            i18n.changeLanguage('en');
        }
    };

    return (
        <Button
            size={'sm'}
            variant={marketplaceCtx.themeMode}
            onClick={handleClick}
        >
            <span
                style={{
                    fontWeight: i18n.language === 'en' ? 'bold' : 'normal',
                }}
            >
                Eng
            </span>
            &nbsp;/&nbsp;
            <span
                style={{
                    fontWeight: i18n.language === 'cn' ? 'bold' : 'normal',
                }}
            >
                中文
            </span>
        </Button>
    );
};

export default SwitchLanguageContainer;
