import React from 'react';
import UserBidsTable from '../../components/tables/UserBidsTable';
import Box from '@mui/material/Box';

const BidPanel = () => {
    return (
        <Box marginTop={'1rem'}>
            <UserBidsTable />
        </Box>
    );
};

export default BidPanel;
