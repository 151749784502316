import React, { useContext, useEffect, useState } from 'react';
import { settings } from '../../helpers/settings';
import MarketplaceContext from '../../providers/marketplace-context';
import { useTranslation } from 'react-i18next';

function ViewOnlyAlert() {
    const [viewport, setViewport] = useState('desktop');
    const marketplaceCtx = useContext(MarketplaceContext);
    const { t } = useTranslation(['viewOnlyAlert']);

    useEffect(() => {
        if (window.outerWidth > 991) {
            setViewport('desktop');
        } else {
            setViewport('mobile');
        }
        window.addEventListener('resize', function () {
            if (window.outerWidth > 991) {
                setViewport('desktop');
            } else {
                setViewport('mobile');
            }
        });
    }, []);

    return (
        <div className="viewonly-mode">
            <div className="container">
                <div
                    className="p-4 rounded-xl bg-white shadow-lg"
                    style={{
                        border:
                            marketplaceCtx.themeMode === 'light'
                                ? '3px solid #e9ecef'
                                : '3px solid #282830',
                    }}
                >
                    {viewport === 'desktop' ? (
                        <div className="d-flex align-items-center">
                            <img
                                src="/images/metamask.png"
                                alt="Metamask"
                                className="flex-shrink-0"
                                width="40"
                            />
                            <div className="ms-3">
                                <h5 className="mb-0">
                                    {t('pleaseConnect', {
                                        ns: 'viewOnlyAlert',
                                    })}{' '}
                                    <span className="text-primary">
                                        {settings.UISettings.usedNetworkName}
                                    </span>
                                </h5>
                                <p className="text-muted mb-0">
                                    {t('noMetaMask', { ns: 'viewOnlyAlert' })}{' '}
                                    {settings.UISettings.usedNetworkName}
                                    {t('reloadApp', { ns: 'viewOnlyAlert' })}
                                </p>
                            </div>
                            <div className="ms-auto">
                                <a
                                    href="https://decentralizedcreator.com/create-metamask-wallet/"
                                    className="btn btn-gradient-primary text-nowrap"
                                    rel="noreferrer"
                                    target="_blank noopener"
                                >
                                    {t('setupWallet', { ns: 'viewOnlyAlert' })}
                                </a>
                            </div>
                        </div>
                    ) : (
                        <div className="d-flex align-items-center">
                            <img
                                src="/images/metamask.png"
                                alt="Metamask"
                                className="flex-shrink-0"
                                width="40"
                            />
                            <div className="ms-3">
                                <h6 className="mb-0">
                                    {t('open', { ns: 'viewOnlyAlert' })}
                                </h6>
                                <p className="text-muted mb-0 small">
                                    {t('openInMobile', { ns: 'viewOnlyAlert' })}{' '}
                                    {settings.UISettings.usedNetworkName}
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ViewOnlyAlert;
