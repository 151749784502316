let rpcUrl, usedNetworkName, blockExplorerUrls, networkId;

if (process.env.REACT_APP_OFFLINE === 'true') {
    rpcUrl = 'http://127.0.0.1:7545';
    usedNetworkName = 'Ganache Local Network';
    blockExplorerUrls = '';
    networkId = 1337;
} else {
    rpcUrl = 'https://goerli.infura.io/v3/4aa3f5d7765448f78441186a523e8288';
    usedNetworkName = 'Goerli Network';
    blockExplorerUrls = 'https://goerli.etherscan.io/';
    networkId = 5;
}

export const settings = {
    UISettings: {
        logo: '/images/logo_light.png',
        logoLight: '/images/logo_dark.png',
        logoPortraitLight: '/images/logo_portrait_dark.png',
        logoPortraitDark: '/images/logo_portrait_light.png',
        marketplaceBrandName: 'Go Ocean | NFT Marketplace',
        marketplaceBrandDescription: 'NFT Marketplace',
        marketplaceAuthor: 'goocean',
        contactFormAddressId: 'xyyodedw',
        newsletterAddressId: 'xlezgplp',
        usedNetworkName,
        featuredItems: [20, 2, 3, 4],
        editorRecommendation: [20],
    },
    currency: 'GoerliETH',
    rpcUrl, //http://162.62.224.45:8545',
    networkId, //5777,
    blockExplorerUrls,
    NFTmaxSize: 102400, // 100MB
    thumbnailImageSize: 10240,
    royalties: [
        { label: '0%', value: 0 },
        { label: '5%', value: 5 },
        { label: '10%', value: 10 },
        { label: '15%', value: 15 },
        { label: '20%', value: 20 },
    ],
    saleCommission: 25, // 1000 represents 100%, e.g. 25 represents 2.5% etc...
};
