import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'aos/dist/aos.css';
import Web3Provider from './providers/Web3Provider';
import CollectionProvider from './providers/CollectionProvider';
import MarketplaceProvider from './providers/MarketplaceProvider';
import UserProvider from './providers/UserProvider';
import AuctionProvider from './providers/AuctionProvider';
import {
    ApolloClient,
    ApolloLink,
    ApolloProvider,
    HttpLink,
    InMemoryCache,
} from '@apollo/client';
import * as bootstrap from 'bootstrap';
import './i18n';
import { databaseGraphqlURL, theGraphGraphqlURL } from './helpers/constants';

window.bootstrap = bootstrap;

const databaseLink = new HttpLink({
    uri: databaseGraphqlURL,
});

const theGraphLink = new HttpLink({
    uri: theGraphGraphqlURL,
});

export const apolloClient = new ApolloClient({
    link: ApolloLink.split(
        (operation) => operation.getContext().clientName === 'theGraph',
        theGraphLink, //if above
        databaseLink
    ),
    cache: new InMemoryCache({
        addTypename: false,
    }),
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Web3Provider>
        <ApolloProvider client={apolloClient}>
            <CollectionProvider>
                <MarketplaceProvider>
                    <AuctionProvider>
                        <UserProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </UserProvider>
                    </AuctionProvider>
                </MarketplaceProvider>
            </CollectionProvider>
        </ApolloProvider>
    </Web3Provider>
);
