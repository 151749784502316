import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    MouseParallaxChild,
    MouseParallaxContainer,
} from 'react-parallax-mouse';
import MarketplaceContext from '../../providers/marketplace-context';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';
import NftItemSkeleton from '../Skeletons/NftItemSkeleton/NftItemSkeleton';
import NftCard from '../general/NftCard';
import Box from '@mui/material/Box';

const heroBg = 'linear-gradient(to bottom, #f5f5f5, #f5f5f5, #ffffff)';

function HomeBanner({ dailyNFT }) {
    const marketplaceCtx = useContext(MarketplaceContext);
    const { t } = useTranslation(['homeBanner']);
    const [topSellers, setTopSellers] = useState(null);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    useEffect(() => {
        if (marketplaceCtx.topSellers && marketplaceCtx.topSellers.length > 0) {
            setTopSellers(marketplaceCtx.topSellers);
        }
    }, [marketplaceCtx.topSellers]);

    return (
        <section
            className="hero py-sm-5 pt-4"
            style={{
                backgroundImage:
                    marketplaceCtx.themeMode === 'light' ? heroBg : '',
            }}
        >
            {marketplaceCtx.themeMode === 'dark' && (
                <div className="glow bottom-right"></div>
            )}
            <div className="shape-1"></div>
            <div className="shape-2"></div>
            <div className="container py-sm-5 z-index-20 position-relative mt-5">
                <div className="row gy-5 align-items-center">
                    <div className="col-lg-6 text-center text-lg-start order-2 order-lg-1">
                        <h1
                            data-aos="fade-up"
                            data-aos-delay="100"
                            className="position-relative lh-1"
                        >
                            {t('title')}
                            <div className="shape-3"></div>
                        </h1>
                        <p
                            className="text-muted lead pb-4"
                            data-aos="fade-up"
                            data-aos-delay="200"
                        >
                            {t('subtitle')}
                        </p>
                        <Box
                            component={'ul'}
                            className="list-inline"
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            <li
                                className="list-inline-item"
                                data-aos="fade-up"
                                data-aos-delay="300"
                            >
                                <Link
                                    className="btn py-2 btn-gradient-primary"
                                    to="/mint"
                                >
                                    <i className="lab la-ethereum me-1"></i>
                                    {t('createNftButton')}
                                </Link>
                            </li>
                            <li
                                className="list-inline-item"
                                data-aos="fade-up"
                                data-aos-delay="400"
                            >
                                <Link
                                    className="btn py-2 btn-dark"
                                    to="/explore"
                                >
                                    <i className="las la-compass me-1"></i>
                                    {t('discoverButton')}
                                </Link>
                            </li>
                        </Box>
                    </div>

                    <Box
                        className="col-lg-6 order-1 order-lg-2"
                        data-aos="fade-left"
                        data-aos-delay="100"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <MouseParallaxContainer
                            className="image-perspective-box"
                            containerStyles={{ overflow: 'visible' }}
                        >
                            <MouseParallaxChild
                                factorX={0.05}
                                factorY={0.05}
                                factorZ={0.1}
                            >
                                {dailyNFT ? (
                                    <NftCard
                                        {...dailyNFT}
                                        isTopSeller={topSellers?.includes(
                                            dailyNFT.address
                                        )}
                                    />
                                ) : (
                                    <NftItemSkeleton />
                                )}
                            </MouseParallaxChild>
                        </MouseParallaxContainer>
                    </Box>
                </div>
            </div>
        </section>
    );
}

export default HomeBanner;
