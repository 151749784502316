import React, { useContext, useEffect, useState } from 'react';
import { formatPrice } from '../helpers/utils';
import { Link } from 'react-router-dom';
import { categorySelectBoxCn, categorySelectBoxEn } from '../helpers/constants';
import { settings } from '../helpers/settings';
import Select from 'react-dropdown-select';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// COMPONENTS
import PageBanner from '../components/general/PageBanner';
import NftItem from '../components/general/NftItem';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import { EXPLORE_EACH_PAGE_NUMS, EXPLORE_INIT_NUMS } from '../helpers/pages';
import InfiniteScroll from 'react-infinite-scroller';
import { GraphqlClient } from '../helpers/graphqlClient';
import FullScreenLoader from '../components/general/FullScreenLoader';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import CollectionContext from '../providers/collection-context';
import MarketplaceContext from '../providers/marketplace-context';
import AuctionContext from '../providers/auction-context';
import NftItemSkeleton from '../components/Skeletons/NftItemSkeleton/NftItemSkeleton';
import Stack from '@mui/material/Stack';

// SELECT OPTIONS
const priceOptionsEn = [
    { label: 'Default', value: 'default' },
    { label: 'Only on Sale', value: 'saleOnly' },
    { label: 'Not for Sale', value: 'notForSale' },
];
const priceOptionsCn = [
    { label: '默认', value: 'default' },
    { label: '仅出售中', value: 'saleOnly' },
    { label: '非出售中', value: 'notForSale' },
];

const sortingEn = [
    { label: 'Default', value: 'default' },
    { label: 'Newest First', value: 'newest' },
    { label: 'Oldest First', value: 'oldest' },
    { label: 'Highest Price', value: 'highPrice' },
    { label: 'Lowest Price', value: 'lowPrice' },
];
const sortingCn = [
    { label: '默认', value: 'default' },
    { label: '优先最新', value: 'newest' },
    { label: '优先最老', value: 'oldest' },
    { label: '价格最高', value: 'highPrice' },
    { label: '价格最低', value: 'lowPrice' },
];

function Explore() {
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);

    const [isLoading, setIsLoading] = useState(true);

    const [baseFilter, setBaseFilter] = useState('trending');
    const [sortFilter, setSortFilter] = useState('default');
    const [priceFilter, setPriceFilter] = useState('default');

    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);

    const { t } = useTranslation(['explore']);

    useEffect(() => {
        document.title = `Explore NFTs | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    useEffect(() => {
        const loadTokensWithPrice = async () => {
            const tokens = await getTokensWithFilters(
                baseFilter,
                priceFilter,
                sortFilter,
                { skip: 0, first: EXPLORE_INIT_NUMS }
            );

            const { updatedTokens, owners } = updateOwnersAndPrice(tokens);
            const users = await GraphqlClient.getUsersByAccounts(owners);

            const withPriceMetaDataTokens = await Promise.all(
                constructTokens(updatedTokens, users)
            );

            setItems(withPriceMetaDataTokens);
            setHasMore(tokens.length === EXPLORE_INIT_NUMS);
        };

        loadTokensWithPrice()
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }, [baseFilter, priceFilter, sortFilter]);

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            const validOffer = token.offers.filter((offer) => {
                return !offer.fulfilled && !offer.cancelled;
            })[0];

            let updatedToken = JSON.parse(JSON.stringify(token));

            updatedToken.realOwner = validOffer
                ? validOffer.creator.id
                : token.owner.id;
            updatedToken.price = validOffer ? validOffer.price : 0;
            updatedToken.offerId = validOffer ? validOffer.id : null;
            owners.add(updatedToken.realOwner);
            return updatedToken;
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const getTokensWithFilters = async (
        baseFilter,
        priceFilter,
        sortFilter,
        paging
    ) => {
        let parameters = '';
        let conditions = ', where: {onAuction: false}';
        let args = {};
        // Apply Filter
        if (baseFilter !== 'trending') {
            parameters = parameters + ', $category: String';
            conditions = conditions.slice(0, -1) + ', category: $category}';
            args.category = baseFilter;
        }

        if (priceFilter === 'saleOnly') {
            conditions = conditions.slice(0, -1) + ', onSale: true}';
        } else if (priceFilter === 'notForSale') {
            conditions = conditions.slice(0, -1) + ', onSale: false}';
        }

        if (sortFilter === 'newest') {
            conditions =
                conditions +
                ', orderBy: createdTimestamp, orderDirection: desc';
        } else if (sortFilter === 'oldest') {
            conditions =
                conditions + ', orderBy: createdTimestamp, orderDirection: asc';
        } else if (sortFilter === 'highPrice') {
            conditions =
                conditions + ', orderBy: onSalePrice, orderDirection: desc';
        } else if (sortFilter === 'lowPrice') {
            conditions =
                conditions + ', orderBy: onSalePrice, orderDirection: asc';
        }

        const GET_TOKENS = gql`
            query tokens($skip: Int, $first: Int${parameters}){
              erc721Tokens(skip: $skip, first: $first${conditions}) {
                id
                owner {
                  id
                }
                uri
                offers {
                  id
                  creator {
                    id
                  }
                  fulfilled
                  cancelled
                  price
                }
                onAuction
                category
                onSale
                onSalePrice
              }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_TOKENS,
            'erc721Tokens',
            paging,
            args
        );
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];
            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken = {
                    id: token.id,
                    title: metadata.properties.name.description,
                    file:
                        metadata.properties?.file?.description ||
                        metadata.properties?.image?.description,
                    thumbnail:
                        metadata.properties?.thumbnail?.description ||
                        metadata.properties?.preview?.description ||
                        metadata.properties?.image?.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    unlockable: metadata.properties.unlockable.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    owner: token.realOwner,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                    price: token.price,
                    offerId: token.offerId,
                };

                return extendedToken;
            } catch {
                console.log('an NFT was blocked');
            }
        });
        return result;
    };

    const loadMore = async () => {
        const tokens = await getTokensWithFilters(
            baseFilter,
            priceFilter,
            sortFilter,
            { skip: items.length, first: EXPLORE_EACH_PAGE_NUMS }
        );
        const { updatedTokens, owners } = updateOwnersAndPrice(tokens);
        const users = await GraphqlClient.getUsersByAccounts(owners);

        const extendedTokens = await Promise.all(
            constructTokens(updatedTokens, users)
        );
        setItems([...items, ...extendedTokens]);
        setHasMore(tokens.length === EXPLORE_EACH_PAGE_NUMS);
    };

    return (
        <>
            {auctionCtx.fetchingLoading ? (
                <FullScreenLoader heading="Updating NFTs" />
            ) : null}
            {marketplaceCtx.mktIsLoading ? (
                <FullScreenLoader heading="Fetching NFTs" />
            ) : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                initialLoad={false}
            >
                <PageBanner heading={t('explore', { ns: 'explore' })} />
                <section className="pb-5">
                    {/* FILTER CONTROLS */}
                    <div className="container pt-5">
                        <header className="mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item mb-3 me-3">
                                    <p className="text-sm fw-bold pe-lg-4 mb-3">
                                        {t('filterByCat', { ns: 'explore' })}
                                    </p>
                                    <div className="input-icon flex-nowrap category-select">
                                        <div className="input-icon-text bg-none">
                                            <i className="las la-icons text-primary z-index-20"></i>
                                        </div>
                                        <Select
                                            placeholder={t('select', {
                                                ns: 'explore',
                                            })}
                                            searchable={false}
                                            options={
                                                i18next.language === 'cn'
                                                    ? categorySelectBoxCn
                                                    : categorySelectBoxEn
                                            }
                                            className="form-select rounded-xl border-gray-300 shadow-0 bg-white"
                                            value={baseFilter}
                                            onChange={(values) => {
                                                setBaseFilter(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                );
                                            }}
                                        />
                                    </div>
                                </li>
                                <li className="list-inline-item mb-3 me-3">
                                    <p className="text-sm fw-bold pe-lg-4 mb-3">
                                        {t('filterByPrice', { ns: 'explore' })}
                                    </p>
                                    <div className="input-icon flex-nowrap category-select">
                                        <div className="input-icon-text bg-none">
                                            <i className="lab la-ethereum text-primary z-index-20"></i>
                                        </div>
                                        <Select
                                            placeholder={t('select', {
                                                ns: 'explore',
                                            })}
                                            searchable={false}
                                            options={
                                                i18next.language === 'cn'
                                                    ? priceOptionsCn
                                                    : priceOptionsEn
                                            }
                                            className="form-select rounded-xl border-gray-300 shadow-0 bg-white"
                                            value={priceFilter}
                                            onChange={(values) =>
                                                setPriceFilter(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                )
                                            }
                                        />
                                    </div>
                                </li>
                                <li className="list-inline-item mb-3">
                                    <p className="text-sm fw-bold pe-lg-4 mb-3">
                                        {t('sortBy', { ns: 'explore' })}
                                    </p>
                                    <div className="input-icon flex-nowrap category-select">
                                        <div className="input-icon-text bg-none">
                                            <i className="lab la-ethereum text-primary z-index-20"></i>
                                        </div>
                                        <Select
                                            placeholder={t('select', {
                                                ns: 'explore',
                                            })}
                                            searchable={false}
                                            options={
                                                i18next.language === 'cn'
                                                    ? sortingCn
                                                    : sortingEn
                                            }
                                            className="form-select rounded-xl border-gray-300 shadow-0 bg-white"
                                            value={sortFilter}
                                            onChange={(values) =>
                                                setSortFilter(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                )
                                            }
                                        />
                                    </div>
                                </li>
                            </ul>
                        </header>

                        {isLoading ? (
                            <Stack
                                direction={{ sm: 'column', md: 'row' }}
                                width={'100%'}
                                spacing={2}
                            >
                                <NftItemSkeleton />
                                <NftItemSkeleton />
                                <NftItemSkeleton />
                            </Stack>
                        ) : items.length !== 0 ? (
                            <>
                                <div className="row gy-4 mb-5 align-items-stretch">
                                    {items.map((NFT, key) => {
                                        const index = NFT.offerId
                                            ? NFT.offerId
                                            : -1;
                                        const owner = NFT.owner;
                                        const price =
                                            index !== -1
                                                ? formatPrice(
                                                      NFT.price
                                                  ).toFixed(2)
                                                : null;

                                        return (
                                            <div
                                                className={`col-xl-4 col-md-6 ${NFT.category}`}
                                                key={NFT.id}
                                            >
                                                <NftItem
                                                    {...NFT}
                                                    noAnimation={true}
                                                    index={index}
                                                    owner={owner}
                                                    price={price}
                                                    nftKey={key}
                                                    offerPriceBN={NFT.price}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="text-center">
                                    <h4 className="text-center">
                                        {t('noNFT', { ns: 'explore' })}
                                    </h4>
                                    <p className="text-muted mb-3">
                                        {t('onceCreate', { ns: 'explore' })}
                                    </p>
                                    <Link
                                        className="btn btn-gradient-primary mb-5"
                                        to="/mint"
                                    >
                                        {t('create', { ns: 'explore' })}
                                    </Link>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </InfiniteScroll>
        </>
    );
}

export default Explore;
