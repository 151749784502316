import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import web3 from '../../connect-web3/web3';
import Web3 from 'web3';
import Web3Context from '../../providers/web3-context';
import CollectionContext from '../../providers/collection-context';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import UserContext from '../../providers/user-context';
import { formatDate, truncateStart } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

// Components
import NftCategory from './NftCategory';
import Modal from './Modal';

import * as bootstrap from 'bootstrap';
import { deleteNftFromDB, mintNft } from '../../helpers/nftUtils';
import { useSnackbar } from 'notistack';
import { connectWalletHandler } from '../../helpers/wallet';
import Skeleton from '@mui/material/Skeleton';

window.bootstrap = bootstrap;

const melodyStyle = {
    fontSize: '5rem',
    color: '#fff',
    position: 'absolute',
    top: '4rem',
    left: '50%',
    transform: 'translateX(-50%)',
};

function NftItem({
    file,
    thumbnail,
    title,
    owner,
    price,
    description,
    formate,
    category,
    dateCreated,
    id,
    index,
    nftKey,
    noAnimation,
    unlockable,
    royalties,
    type,
    fromDB,
    openMetaMaskLoader,
    closeMetaMaskLoader,
    successMint,
    ownerName,
    ownerAvatar,
    offerPriceBN,
    isLoading,
}) {
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const web3Ctx = useContext(Web3Context);
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const userCtx = useContext(UserContext);
    const auctionCtx = useContext(AuctionContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
        useState(false);
    const [saleType, setSaleType] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [endDate, setEndDate] = useState(new Date().getTime());
    const [networkId, setNetworkId] = useState(0);
    const previewWrapperEl = useRef(null);

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(
                    new Web3(window.ethereum)
                );
                setNetworkId(networkId);
            }
        }

        getNetworkId();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIAMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000, offset: 10 });
    }, []);

    useEffect(() => {
        if (previewWrapperEl.current && type === 'audio' && thumbnail) {
            previewWrapperEl.current.style.backgroundImage = `url(${process.env.REACT_APP_IPFS_GATEWAY}${thumbnail})`;
            previewWrapperEl.current.style.backgroundSize = 'cover';
            previewWrapperEl.current.style.backgroundPosition = 'center center';
        }
    }, [previewWrapperEl]);

    /*** =============================================== */
    //      DECLARE PRICE REFERENCE
    /*** =============================================== */
    const priceRef = useRef(null);

    /*** =============================================== */
    //      CREATE SALE FUNCTION
    /*** =============================================== */
    const makeOfferHandler = (event, id, nftKey) => {
        event.preventDefault();

        const enteredPrice = web3.utils.toWei(priceRef.current.value, 'ether');

        collectionCtx.contract.methods
            .approve(marketplaceCtx.contract.options.address, id)
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setIsModalOpen(false);
            })
            .once('sending', () => {
                collectionCtx.setNftTransactionLoading(true);
                setIsModalOpen(false);
            })
            .once('error', (e) => {
                collectionCtx.setNftTransactionLoading(false);
                setIsModalOpen(false);
            })
            .on('receipt', () => {
                marketplaceCtx.contract.methods
                    .addPrice(id, enteredPrice)
                    .send({ from: web3Ctx.account })
                    .once('sending', () => {
                        collectionCtx.setNftTransactionLoading(true);
                    })
                    .on('receipt', () => {
                        setTimeout(() => {
                            collectionCtx.setNftTransactionLoading(false);
                            setIsModalOpen(false);
                            history.push(`/assets/${id}`);
                        }, 15000);
                    })
                    .on('error', (error) => {
                        collectionCtx.setNftTransactionLoading(false);
                        setIsModalOpen(false);
                        enqueueSnackbar(t('error', { ns: 'nftItem' }), {
                            variant: 'error',
                        });
                    });
                collectionCtx.setNftTransactionLoading(false);
                setIsModalOpen(false);
            });
    };

    /*** =============================================== */
    //      BUY NFT FUNCTION
    /*** =============================================== */
    const buyHandler = (event) => {
        const offerId = event.target.value;
        if (offerId !== -1) {
            marketplaceCtx.contract.methods
                .buyNFT(offerId)
                .send({
                    from: web3Ctx.account,
                    value: offerPriceBN,
                })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('transactionHash', (hash) => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('receipt', () => {
                    setTimeout(() => {
                        collectionCtx.setNftTransactionLoading(false);
                        history.push(`/assets/${id}`);
                    }, 15000);
                })
                .on('error', (error) => {
                    enqueueSnackbar(t('error', { ns: 'nftItem' }), {
                        variant: 'error',
                    });
                    collectionCtx.setNftTransactionLoading(false);
                });
        }
    };

    /*** =============================================== */
    //      CANCEL SALE FUNCTION
    /*** =============================================== */
    const cancelHandler = (event) => {
        const offerId = event.target.value;
        if (offerId !== -1) {
            marketplaceCtx.contract.methods
                .cancelSale(offerId)
                .send({ from: web3Ctx.account })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('transactionHash', (hash) => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('receipt', () => {
                    setTimeout(() => {
                        collectionCtx.setNftTransactionLoading(false);
                        history.push(`/assets/${id}`);
                    }, 15000);
                })
                .on('error', (error) => {
                    collectionCtx.setNftTransactionLoading(false);
                    enqueueSnackbar(t('error', { ns: 'nftItem' }), {
                        variant: 'error',
                    });
                });
        }
    };

    /*** =============================================== */
    //      SUBMIT MINTING FORM
    /*** =============================================== */
    const submitMintHandler = (event) => {
        event.preventDefault();

        let formIsValid = title && description && file && thumbnail;

        formIsValid &&
            mintNft({
                web3Ctx,
                collectionCtx,
                userCtx,
                openMetaMaskLoader,
                closeMetaMaskLoader,
                enqueueSnackbar,
                deleteNftFromDB,
                successMint,
                history,
                id,
                title,
                description,
                category,
                royalties,
                unlockable,
                type,
                formate,
            });
    };

    /*** =============================================== */
    //      DELETE MINTED NFT FROM DB
    /*** =============================================== */
    const deleteNftFromDBHandler = (event) => {
        event.preventDefault();
        setIsDeleteConfirmModalOpen(false);
        deleteNftFromDB({ web3Ctx, id, userCtx });
        setTimeout(() => {
            history.go(0);
        }, 2500);
    };

    /*** =============================================== */
    //      MAKE AUCTION FUNCTION
    /*** =============================================== */
    const makeAuctionHandler = (event, endDate, id) => {
        event.preventDefault();

        if (new Date(endDate).getTime() > new Date().getTime()) {
            collectionCtx.contract.methods
                .approve(auctionCtx.contract.options.address, id)
                .send({ from: web3Ctx.account })
                .on('transactionHash', (hash) => {
                    setIsModalOpen(false);
                })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                    setIsModalOpen(false);
                })
                .once('error', (e) => {
                    collectionCtx.setNftTransactionLoading(false);
                    setIsModalOpen(false);
                })
                .on('receipt', () => {
                    auctionCtx.contract.methods
                        .createAuction(
                            parseInt(id),
                            new Date(endDate).getTime() + 400000
                        )
                        .send({ from: web3Ctx.account })
                        .once('sending', () => {
                            auctionCtx.setAuctionTransactionLoading(true);
                        })
                        .on('receipt', () => {
                            setTimeout(() => {
                                auctionCtx.setAuctionTransactionLoading(false);
                                setIsModalOpen(false);
                                setSaleType('');
                                history.push(`/nftauction/${id}`);
                            }, 15000);
                        })
                        .on('error', (error) => {
                            auctionCtx.setAuctionTransactionLoading(false);
                            setIsModalOpen(false);
                            setSaleType('');
                            enqueueSnackbar(t('error', { ns: 'nftItem' }), {
                                variant: 'error',
                            });
                        });
                    auctionCtx.setAuctionTransactionLoading(false);
                    collectionCtx.setNftTransactionLoading(false);
                    setIsModalOpen(false);
                });
        } else {
            enqueueSnackbar(t('endDate', { ns: 'nftItem' }), {
                variant: 'error',
            });
        }
    };

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
        setSaleType('');
    }

    /*** =============================================== */

    //      CLOSE DELETE NFT MODAL FUNCTION
    /*** =============================================== */
    function closeDeleteConfirmModalHandler() {
        setIsDeleteConfirmModalOpen(false);
    }

    const renderIcon = () => {
        let icon = '';
        switch (type) {
            case 'image':
                icon = 'la-image';
                break;
            case 'audio':
                icon = 'la-record-vinyl';
                break;
            case 'video':
                icon = 'la-video';
                break;
            case '3d':
                icon = 'la-cube';
                break;
            default:
                break;
        }

        return <i className={`las ${icon} text-primary ms-1`}></i>;
    };

    const renderPreview = () => {
        switch (type) {
            case 'image':
                return (
                    <div
                        className="w-100 h-100 card-img-holder-inner"
                        style={{
                            backgroundImage: `url(${
                                fromDB ? '' : process.env.REACT_APP_IPFS_GATEWAY
                            }${thumbnail})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    ></div>
                );
            case 'audio':
                return (
                    <>
                        <i className="las la-music" style={melodyStyle}></i>
                        <AudioPlayer
                            src={`${
                                fromDB ? '' : process.env.REACT_APP_IPFS_GATEWAY
                            }${file}`}
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                        />
                    </>
                );
            case 'video':
                return (
                    <ReactPlayer
                        url={`${
                            fromDB ? '' : process.env.REACT_APP_IPFS_GATEWAY
                        }${file}`}
                        controls={true}
                        width="100%"
                        height="auto"
                    />
                );
            case '3d':
                return (
                    <div
                        className="w-100 h-100 card-img-holder-inner"
                        style={{
                            backgroundImage: `url(${
                                fromDB ? '' : process.env.REACT_APP_IPFS_GATEWAY
                            }${thumbnail})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    ></div>
                );
            default:
                return null;
        }
    };

    const { t } = useTranslation(['nftItem']);

    return (
        <div
            className={`card rounded card-hover-image position-relative ${category}`}
            data-aos={`${noAnimation ? '' : 'fade-up'}`}
            data-aos-once="true"
            data-aos-delay={(nftKey + 1) * 100}
        >
            <div className="card-body p-3 position-relative">
                <div className="position-relative mb-4 shadow">
                    <div
                        ref={previewWrapperEl}
                        className={'card-img-holder rounded overflow-hidden'}
                    >
                        {isLoading ? (
                            <Skeleton
                                animation="wave"
                                width={'100vw'}
                                height={'100vh'}
                            />
                        ) : (
                            renderPreview()
                        )}
                    </div>

                    {unlockable !== '' && owner === web3Ctx.account && (
                        <div className="position-absolute top-0 end-0 m-3">
                            {isLoading ? (
                                <Skeleton animation="wave" />
                            ) : (
                                <a
                                    href={unlockable}
                                    className="btn btn-info px-3"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    <i className="las la-cloud"></i>
                                </a>
                            )}
                        </div>
                    )}
                </div>

                <div className="fw-bold lead mb-3 d-flex align-items-center justify-content-between">
                    {isLoading ? (
                        <Skeleton animation={'wave'} width={60} />
                    ) : (
                        <Link className="text-reset" to={`/assets/${id}`}>
                            {truncateStart(title, 25)} {renderIcon()}
                        </Link>
                    )}
                    {isLoading ? (
                        <Skeleton animation={'wave'} width={50} />
                    ) : (
                        <div className="ms-3">
                            <NftCategory category={category} />
                        </div>
                    )}
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                    <div className="author position-static z-index-20 d-flex align-items-center">
                        <Link className="text-reset" to={`/users/${owner}`}>
                            {isLoading ? (
                                <Skeleton
                                    variant={'circular'}
                                    width={40}
                                    height={40}
                                />
                            ) : (
                                <div className="author-avatar">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(${
                                                ownerAvatar !== ''
                                                    ? ownerAvatar
                                                    : '/images/astronaut.png'
                                            })`,
                                        }}
                                    ></span>
                                </div>
                            )}
                        </Link>
                        <div className="ms-2">
                            <p className="text-muted fw-normal mb-0 lh-1">
                                <span className="text-xs">
                                    {t('own', { ns: 'nftItem' })}
                                </span>
                                <strong className="d-block fw-bold h6 text-dark mb-0">
                                    {isLoading ? (
                                        <Skeleton
                                            animation={'wave'}
                                            width={40}
                                        />
                                    ) : (
                                        <Link
                                            className="text-reset"
                                            to={`/users/${owner}`}
                                        >
                                            {truncateStart(ownerName, 10)}
                                        </Link>
                                    )}
                                </strong>
                            </p>
                        </div>
                    </div>

                    {!fromDB && (
                        <p className="text-muted fw-normal mb-0 lh-1">
                            <span className="text-xs">
                                {t('currentPrice', { ns: 'nftItem' })}
                            </span>
                            {isLoading ? (
                                <Skeleton
                                    animation={'wave'}
                                    width={70}
                                    className="d-block fw-bold lead text-dark h2 mb-0"
                                />
                            ) : index !== -1 ? (
                                owner !== web3Ctx.account ? (
                                    <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                        {price} {settings.currency}
                                    </strong>
                                ) : (
                                    <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                        {price} {settings.currency}
                                    </strong>
                                )
                            ) : owner === web3Ctx.account ? (
                                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                    {t('notSet', { ns: 'nftItem' })}
                                </strong>
                            ) : (
                                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                    {t('notSet', { ns: 'nftItem' })}
                                </strong>
                            )}
                        </p>
                    )}
                </div>
                {index !== -1 ? (
                    owner !== web3Ctx.account ? (
                        <>
                            <div className="card-ribbon top-0 mt-4 pt-2">
                                <span className="bg-danger px-2 py-1 rounded-sm">
                                    {t('onSale', { ns: 'nftItem' })}
                                </span>{' '}
                                {unlockable !== '' && (
                                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                        {t('unlockable', { ns: 'nftItem' })}
                                    </span>
                                )}
                            </div>
                            {!isLoading && (
                                <div className="card-action">
                                    {userCtx.userIsRegistered ? (
                                        <button
                                            type="button"
                                            className="btn btn-primary text-nowrap"
                                            value={index}
                                            onClick={buyHandler}
                                        >
                                            <i className="lab la-ethereum me-2"></i>
                                            {t('purchaseNFT', {
                                                ns: 'nftItem',
                                            })}
                                        </button>
                                    ) : (
                                        <>
                                            {web3Ctx.account ? (
                                                <Link
                                                    className="btn btn-primary text-nowrap"
                                                    value={index}
                                                    to="/register"
                                                >
                                                    <i className="las la-user me-2"></i>
                                                    {t('register', {
                                                        ns: 'nftItem',
                                                    })}
                                                </Link>
                                            ) : (
                                                <>
                                                    {window.ethereum &&
                                                        networkId ===
                                                            settings.networkId && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-gradient-primary text-nowrap"
                                                                onClick={() =>
                                                                    connectWalletHandler(
                                                                        web3Ctx,
                                                                        userCtx
                                                                    )
                                                                }
                                                            >
                                                                {t(
                                                                    'connectWallet',
                                                                    {
                                                                        ns: 'nftItem',
                                                                    }
                                                                )}
                                                            </button>
                                                        )}
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="card-action">
                                <button
                                    type="button"
                                    value={index}
                                    className="btn btn-danger text-nowrap"
                                    onClick={cancelHandler}
                                >
                                    {t('unlist', { ns: 'nftItem' })}
                                </button>
                            </div>
                        </>
                    )
                ) : owner === web3Ctx.account ? (
                    fromDB ? (
                        <>
                            <div className="card-ribbon top-0 mt-4 pt-2">
                                <span className="bg-primary px-2 py-1 rounded-sm">
                                    {t('owned', { ns: 'nftItem' })}
                                </span>{' '}
                            </div>
                            <div className="card-action">
                                <form onSubmit={(e) => submitMintHandler(e)}>
                                    <button
                                        type="submit"
                                        className="btn btn-primary w-100 rounded-sm mb-2"
                                    >
                                        {t('mintNFT', { ns: 'nftItem' })}
                                    </button>
                                </form>

                                <button
                                    type="button"
                                    className="btn btn-info w-100 rounded-sm mb-2"
                                    onClick={() => {
                                        setIsDeleteConfirmModalOpen(true);
                                    }}
                                >
                                    {t('deleteNFT', { ns: 'nftItem' })}
                                </button>
                            </div>
                            <Modal
                                status={isDeleteConfirmModalOpen}
                                variant="modal-card-inner"
                                modalClose={closeDeleteConfirmModalHandler}
                                layout={{
                                    width: '400px',
                                    maxWidth: '100%',
                                }}
                            >
                                <div className="card-body text-center py-lg-5">
                                    <h4 className="mb-1">
                                        {t('sureToDelete', {
                                            ns: 'nftItem',
                                        })}
                                    </h4>
                                    <div className="d-flex flex-column">
                                        <form
                                            onSubmit={(e) =>
                                                deleteNftFromDBHandler(e)
                                            }
                                        >
                                            <button
                                                className="btn btn-info m-1 w-100"
                                                type="submit"
                                            >
                                                {t('confirm', {
                                                    ns: 'nftItem',
                                                })}
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </Modal>
                        </>
                    ) : (
                        <>
                            <div className="card-ribbon top-0 mt-4 pt-2">
                                <span className="bg-primary px-2 py-1 rounded-sm">
                                    {t('owned', { ns: 'nftItem' })}
                                </span>{' '}
                                {unlockable !== '' && (
                                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                        {t('unlockable', { ns: 'nftItem' })}
                                    </span>
                                )}
                            </div>
                            <div className="card-action">
                                <button
                                    className="btn btn-primary text-nowrap"
                                    type="button"
                                    onClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                >
                                    {t('createSale', { ns: 'nftItem' })}
                                </button>
                            </div>
                            <Modal
                                status={isModalOpen}
                                variant="modal-card-inner"
                                modalClose={closeModalHandler}
                                layout={{
                                    width: '400px',
                                    maxWidth: '100%',
                                }}
                            >
                                <div className="card-body text-center py-lg-5">
                                    <h4 className="mb-1">
                                        {t('listToSale', { ns: 'nftItem' })}
                                    </h4>
                                    {saleType === 'fixedPrice' && (
                                        <p className="text-muted mb-4">
                                            {t('addPriceToWith', {
                                                ns: 'nftItem',
                                            })}{' '}
                                            {settings.currency}
                                        </p>
                                    )}
                                    {saleType === 'auction' && (
                                        <p className="text-muted mb-4">
                                            {t('addEndDate', {
                                                ns: 'nftItem',
                                            })}
                                        </p>
                                    )}

                                    {saleType === '' && (
                                        <div className="d-flex flex-column">
                                            <button
                                                className="btn btn-primary m-1 w-100"
                                                type="button"
                                                onClick={() =>
                                                    setSaleType('fixedPrice')
                                                }
                                            >
                                                {t('fixedPrice', {
                                                    ns: 'nftItem',
                                                })}
                                            </button>
                                            <button
                                                className="btn btn-info m-1 w-100"
                                                type="button"
                                                onClick={() =>
                                                    setSaleType('auction')
                                                }
                                            >
                                                {t('openForBids', {
                                                    ns: 'nftItem',
                                                })}
                                            </button>
                                        </div>
                                    )}

                                    {saleType === 'fixedPrice' && (
                                        <form
                                            onSubmit={(e) =>
                                                makeOfferHandler(e, id, nftKey)
                                            }
                                        >
                                            <input
                                                type="number"
                                                step="0.001"
                                                min="0.0000000000000000000000001"
                                                placeholder={`Price with ${settings.currency}...`}
                                                className="form-control mb-2"
                                                ref={priceRef}
                                                required={true}
                                                autoFocus={true}
                                                value={offerPrice}
                                                onChange={(e) =>
                                                    setOfferPrice(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-100 rounded-sm mb-2"
                                            >
                                                {t('createSale2', {
                                                    ns: 'nftItem',
                                                })}
                                            </button>
                                            <p className="mb-0 text-center text-muted">
                                                {t('willGet', {
                                                    ns: 'nftItem',
                                                })}
                                                <span className="text-primary fw-normal mx-1">
                                                    {offerPrice
                                                        ? offerPrice -
                                                          (parseFloat(
                                                              offerPrice
                                                          ) *
                                                              settings.saleCommission) /
                                                              1000
                                                        : 0}
                                                </span>
                                                {settings.currency}
                                                {t('afterCommission', {
                                                    ns: 'nftItem',
                                                })}
                                            </p>
                                        </form>
                                    )}

                                    {saleType === 'auction' && (
                                        <form
                                            onSubmit={(e) =>
                                                makeAuctionHandler(
                                                    e,
                                                    endDate,
                                                    id
                                                )
                                            }
                                        >
                                            <div className="row gy-3 text-start">
                                                <div className="col-12">
                                                    <label className="form-label text-start fw-bold">
                                                        {t('auctionEndsAt', {
                                                            ns: 'nftItem',
                                                        })}
                                                    </label>
                                                    <input
                                                        className="form-control"
                                                        type="date"
                                                        min={
                                                            new Date()
                                                                .toISOString()
                                                                .split('T')[0]
                                                        }
                                                        max={
                                                            new Date(
                                                                Date.now() +
                                                                    3 *
                                                                        24 *
                                                                        60 *
                                                                        60 *
                                                                        1000
                                                            )
                                                                .toISOString()
                                                                .split('T')[0]
                                                        }
                                                        value={endDate}
                                                        autoFocus={true}
                                                        onChange={(e) =>
                                                            setEndDate(
                                                                e.target.value
                                                            )
                                                        }
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary w-100 rounded-sm mb-2"
                                                    >
                                                        {t('createSale2', {
                                                            ns: 'nftItem',
                                                        })}
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </Modal>
                        </>
                    )
                ) : (
                    !fromDB && (
                        <>
                            <div className="card-ribbon top-0 mt-4 pt-2">
                                <span className="bg-danger px-2 py-1 rounded-sm">
                                    {t('notForSale', { ns: 'nftItem' })}
                                </span>{' '}
                                {unlockable !== '' && (
                                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                        {t('unlockable', { ns: 'nftItem' })}
                                    </span>
                                )}
                            </div>
                        </>
                    )
                )}

                <div className="text-muted fw-normaltext-sm d-flex align-items-center mt-4 justify-content-between">
                    <p className="mb-0 text-xs d-flex align-items-center">
                        <i className="las la-percentage me-1"></i>
                        {isLoading ? (
                            <Skeleton animation={'wave'} width={50} />
                        ) : (
                            <>
                                <span className="me-1 text-primary">
                                    {royalties}%
                                </span>
                                {t('royalties', { ns: 'nftItem' })}
                            </>
                        )}
                    </p>
                    <p className="text-xs mb-0 d-flex align-items-center">
                        <i className="la-sm text-primary las la-clock mx-1 text-primary"></i>
                        {isLoading ? (
                            <Skeleton animation={'wave'} width={30} />
                        ) : (
                            <>
                                {formatDate(dateCreated).count}{' '}
                                {t(formatDate(dateCreated).unit, {
                                    ns: 'nftItem',
                                })}
                                {t('ago', { ns: 'nftItem' })}
                            </>
                        )}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default NftItem;
