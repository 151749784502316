import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Select from 'react-dropdown-select';
import Web3 from 'web3';
import Web3Context from '../providers/web3-context';
import UserContext from '../providers/user-context';
import { categoryOptionsCn, categoryOptionsEn } from '../helpers/constants';
import { settings } from '../helpers/settings';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import AOS from 'aos';
import Compressor from 'compressorjs';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
// COMPONENTS
import PageBanner from '../components/general/PageBanner';
import ItemPreview from '../components/create/ItemPreview';
import SuccessMessage from '../components/general/SuccessMessage';
import ModelViewer from '../components/ModelViewer/ModelViewer';
import { GraphqlClient } from '../helpers/graphqlClient';
import FullScreenLoader from '../components/general/FullScreenLoader';
import { fetchClient } from '../helpers/fetchClient';
import TabButton from '../components/TabButton/TabButton';
import { useSnackbar } from 'notistack';
import ItemLightbox from '../components/item/ItemLightbox';
import ItemToolBox from '../components/item/ItemToolBox';

function CreateItem() {
    const { t } = useTranslation(['createItem']);
    const types = [
        {
            type: 'image',
            name: 'Image',
        },
        /* TODO hide the features for now
        {
            type: 'audio',
            name: 'Audio',
        },
        {
            type: 'video',
            name: 'Video',
        },
         */
        {
            type: '3d',
            name: '3D Model',
        },
    ];

    const uploadRef = useRef(null);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const [mintSuccess, setMintSuccess] = useState(false);
    const [enteredName, setEnteredName] = useState('');
    const [descriptionIsValid, setDescriptionIsValid] = useState(true);
    const [enteredDescription, setEnteredDescription] = useState('');
    const [nameIsValid, setNameIsValid] = useState(true);
    const [categoryIsValid, setCategoryIsValid] = useState(true);
    const [royaltyIsValid, setRoyaltyIsValid] = useState(true);
    const [selectedFile, setSelectedFile] = useState();
    const [selectedThumbnailImage, setSelectedThumbnailImage] = useState();
    const [withUnlockable, setWithUnlockable] = useState(false);
    const [unlockable, setUnlockable] = useState('');
    const [royalties, setRoyalties] = useState(null);
    const [isCreatingNft, setIsCreatingNft] = useState(false);
    const [fileIsValid, setFileIsValid] = useState(true);
    const [fileValidationMsg, setFileValidationMsg] = useState('');
    const [fileUploadHint, setFileUploadHint] = useState(
        t('imageHint', { ns: 'createItem' })
    );
    const [thumbnailImageIsValid, setThumbnailImageIsValid] = useState(false);
    const [thumbnailImageValidationMsg, setThumbnailImageValidationMsg] =
        useState('');
    const [thumbnail, setThumbnail] = useState('');
    const [selectedFileContent, setSelectedFileContent] = useState('');
    const [nftType, setNftType] = useState('image');
    const [enteredCategory, setEnteredCategory] = useState(null);
    const [formate, setFormate] = useState('');
    const [networkId, setNetworkId] = useState(0);
    const [toggler, setToggler] = useState(false);

    const onFullscreenClick = (e) => {
        e.preventDefault();
        setToggler(!toggler);
    };
    const onReselectClick = (e) => {
        e.preventDefault();
        uploadRef.current.click();
    };

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(
                    new Web3(window.ethereum)
                );
                setNetworkId(networkId);
            }
        }

        getNetworkId();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Mint an NFT | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    /*** =============================================== */
    //      CATCH NFT IMAGE
    /*** =============================================== */
    useEffect(() => {
        if (!selectedFile) {
            setSelectedFileContent(undefined);
            return;
        }

        // generate thumbnail from selected image
        if (nftType === 'image') {
            new Compressor(selectedFile, {
                maxWidth: 800,
                maxHeight: 800,
                success(file) {
                    setThumbnail(URL.createObjectURL(file));
                    setSelectedThumbnailImage(file);
                },
            });
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setSelectedFileContent(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFile]);

    /*** =============================================== */
    //     Convert Thumbnail Image
    /*** =============================================== */
    useEffect(() => {
        if (!selectedThumbnailImage) {
            setThumbnail(undefined);
            return;
        }

        let objectUrl = '';

        new Compressor(selectedThumbnailImage, {
            maxWidth: 800,
            maxHeight: 800,
            success(file) {
                objectUrl = URL.createObjectURL(file);
                setThumbnail(objectUrl);
            },
        });

        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedThumbnailImage]);

    /*** =============================================== */
    //      CATCH NFT NAME
    /*** =============================================== */
    const enteredNameHandler = (event) => {
        setEnteredName(event.target.value);
        nameIsValid(event.target.value);
    };

    /*** =============================================== */
    //      CATCH NFT DESCRIPTION
    /*** =============================================== */
    const enteredDescriptionHandler = (event) => {
        setEnteredDescription(event.target.value);
        descriptionIsValid(!!event.target.value);
    };

    /*** =============================================== */
    //      CATCH NFT UNCLOCKABLE CONTENT
    /*** =============================================== */
    const enteredUnlockableHandler = (event) => {
        setUnlockable(event.target.value);
    };

    /*** =============================================== */
    //      VALIDATE UPLOADED IMAGE SIZE
    /*** =============================================== */
    const onSelectFile = (e, nftType) => {
        if (!e.target.files || e.target.files.length === 0) {
            //setSelectedFile(undefined);
            return;
        }
        if (e.target.files.length > 0) {
            for (let i = 0; i <= e.target.files.length - 1; i++) {
                const fsize = e.target.files.item(i).size;
                const file = Math.round(fsize / 1024);
                if (file >= settings.NFTmaxSize) {
                    setFileValidationMsg(t('valitation', { ns: 'createItem' }));
                    setFileIsValid(false);
                    setSelectedFile(undefined);
                    return;
                } else if (nftType === '3d') {
                    if (!/(?:gltf|glb)$/i.test(e.target.files.item(i).name)) {
                        setFileValidationMsg(
                            t('validation3d', { ns: 'createItem' })
                        );
                        setFileIsValid(false);
                        setSelectedFile(undefined);
                        return;
                    }
                } else if (nftType === 'image') {
                    if (
                        !/(?:jpg|gif|png|webp|jpeg)$/i.test(
                            e.target.files.item(i).name
                        )
                    ) {
                        setFileValidationMsg(
                            t('validationPic', { ns: 'createItem' })
                        );
                        setFileIsValid(false);
                        setSelectedFile(undefined);
                        return;
                    }
                } else {
                    setFileIsValid(true);
                    setFormate(e.target.files.item(i).type);
                }
            }
        }
        setSelectedFile(e.target.files[0]);
    };

    const onSelectThumbnailImage = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedThumbnailImage(undefined);
            return;
        }
        if (e.target.files.length > 0) {
            for (let i = 0; i <= e.target.files.length - 1; i++) {
                const fsize = e.target.files.item(i).size;
                const file = Math.round(fsize / 1024);
                // The size of the file.
                if (file >= settings.thumbnailImageSize) {
                    setThumbnailImageValidationMsg(
                        t('valitation', { ns: 'createItem' })
                    );
                    setThumbnailImageIsValid(false);
                    setSelectedThumbnailImage(undefined);
                    return;
                } else if (
                    !/(?:jpg|gif|png|webp)$/i.test(e.target.files.item(i).name)
                ) {
                    setThumbnailImageValidationMsg(
                        t('validationPic', { ns: 'createItem' })
                    );
                    setThumbnailImageIsValid(false);
                    setSelectedThumbnailImage(undefined);
                    return;
                } else {
                    setThumbnailImageIsValid(true);
                }
            }
        }
        setSelectedThumbnailImage(e.target.files[0]);
    };
    /*** =============================================== */
    //      SUBMIT MINTING FORM
    /*** =============================================== */
    const submitSaveHandler = async (event) => {
        event.preventDefault();

        // Validate form fields
        setNameIsValid(!!enteredName);
        setDescriptionIsValid(!!enteredDescription);
        setFileIsValid(!!selectedFile);
        setRoyaltyIsValid(!!royalties);
        setCategoryIsValid(!!enteredCategory);
        if (nftType !== 'image') {
            setThumbnailImageIsValid(!!selectedThumbnailImage);
        }

        let formIsValid =
            enteredName &&
            enteredDescription &&
            selectedFile &&
            royalties &&
            enteredCategory &&
            selectedThumbnailImage;

        const creatNFT = async () => {
            try {
                setIsCreatingNft(true);
                const fileToWriteRes = await GraphqlClient.getFileUrlToWrite(
                    web3Ctx.account,
                    {
                        fileName: selectedFile.name,
                        fileType: selectedFile.type,
                    },
                    false
                );
                const thumbnailToWriteRes =
                    await GraphqlClient.getFileUrlToWrite(
                        web3Ctx.account,
                        {
                            fileName: selectedThumbnailImage.name,
                            fileType: selectedThumbnailImage.type,
                        },
                        true
                    );
                const writeFile = async (file, fileToWriteRes) => {
                    await fetchClient.writeFileToStorage(
                        fileToWriteRes.signedUrl,
                        file
                    );
                    return fileToWriteRes.fileLink;
                };
                const [fileLink, thumbnailLink] = await Promise.all([
                    writeFile(selectedFile, fileToWriteRes),
                    writeFile(selectedThumbnailImage, thumbnailToWriteRes),
                ]);
                // create NFT
                const nftItem = {
                    owner: web3Ctx.account,
                    file: fileLink,
                    thumbnail: thumbnailLink,
                    title: enteredName,
                    category: enteredCategory,
                    royalties: royalties,
                    description: enteredDescription,
                    unlockable: unlockable,
                    formate: formate,
                    type: nftType,
                };

                const creatNftRes = await GraphqlClient.createNft(nftItem);
                enqueueSnackbar(t('success', { ns: 'createItem' }), {
                    variant: 'success',
                });
                // userCtx.loadUserCreatedNftFromDB(web3Ctx.account);
                setIsCreatingNft(false);
                history.push(`/assets/${creatNftRes.id}`);
            } catch (e) {
                var a = e;
                setIsCreatingNft(false);
                enqueueSnackbar(t('wrong', { ns: 'createItem' }), {
                    variant: 'error',
                });
            }
        };
        // save file to local at first

        /*        if (
            nftType === 'video' ||
            nftType === 'audio' ||
            nftType === '3d'
        ) {
            const thumbnailPath = "C:\\Users\\ming1\\Desktop\\files" +
                new Date().toISOString().slice(0,19).replace(/[-T:]/g,"") + '_' + fileNanoId + '_thumbnail';
            fs.writeFile( thumbnailPath, selectedThumbnailImage, function(err) {
                if(err) {
                    return console.log(err);
                }
                console.log("File saved!");
            });
        };*/

        // Upload file to IPFS and push to the blockchain

        formIsValid && creatNFT();
    };

    /*** =============================================== */
    //      INJECT VALIDATION CLASSES TO INPUT FIELDS
    /*** =============================================== */
    const nameClass = nameIsValid ? 'form-control' : 'form-control is-invalid';
    const descriptionClass = descriptionIsValid
        ? 'form-control'
        : 'form-control is-invalid';
    const fileClass = fileIsValid ? 'form-control' : 'form-control is-invalid';
    const thumbnailImageClass = thumbnailImageIsValid
        ? 'form-control'
        : 'form-control is-invalid';
    const categoryClass = categoryIsValid
        ? 'form-select'
        : 'form-select is-invalid';
    const royaltyClass = royaltyIsValid
        ? 'form-select'
        : 'form-select is-invalid';

    const cleanFormValidation = () => {
        setFileIsValid(true);
        setThumbnailImageIsValid(true);
        setNameIsValid(true);
        setDescriptionIsValid(true);
        setCategoryIsValid(true);
        setRoyaltyIsValid(true);
    };
    /*** =============================================== */
    //      SUCCESS MESSAGE AFTER MINTING
    /*** =============================================== */
    if (mintSuccess)
        return (
            <SuccessMessage
                heading={t('success', { ns: 'createItem' })}
                subheading={t('redirect', { ns: 'createItem' })}
            />
        );

    const renderTabs = () => {
        return (
            <div className="toggle-nav">
                {types.map(({ type, name }) => (
                    <TabButton
                        key={Math.random()}
                        className={`flex-fill ${
                            nftType === type ? 'active' : null
                        }`}
                        onClick={() => {
                            setNftType(type);
                            nftType !== type && setSelectedFile(null);
                            cleanFormValidation();
                            setFileValidationMsg('');
                            setFileUploadHint(
                                t(type + 'Hint', { ns: 'createItem' })
                            );
                        }}
                    >
                        {t(name, { ns: 'createItem' })}
                    </TabButton>
                ))}
            </div>
        );
    };

    const renderUploadSection = () => {
        let icon = '';
        let title = t('uploadTitle', { ns: 'createItem' });
        let accept = '';
        let item = '';

        switch (nftType) {
            case 'image':
                icon = 'la-image';
                title = title + t('Image', { ns: 'createItem' });
                accept = '.jpg, .png, .gif, .webp';
                item = (
                    <img
                        src={selectedFileContent}
                        className="img-fluid"
                        alt={enteredName}
                    />
                );
                // setFileUploadHint("images");
                break;
            case 'audio':
                icon = 'la-record-vinyl';
                title = title + t('audio', { ns: 'createItem' });
                accept = '.mp3';
                item = (
                    <AudioPlayer
                        src={selectedFileContent}
                        autoPlayAfterSrcChange={false}
                        showJumpControls={false}
                    />
                );
                break;
            case 'video':
                icon = 'la-video';
                title = title + t('video', { ns: 'createItem' });
                accept = '.mp4, .webm, .gov';
                item = (
                    <ReactPlayer
                        url={selectedFileContent}
                        controls={true}
                        width="100%"
                    />
                );
                break;
            case '3d':
                icon = 'la-cube';
                title = title + t('3D Model', { ns: 'createItem' });
                accept = '.gltf, .glb';
                item = (
                    <ModelViewer
                        styles={{
                            height: 400,
                        }}
                        modelUrl={selectedFileContent}
                        cameraPosition={[0, 0, 60]}
                    ></ModelViewer>
                );
                // setFileUploadHint("3d");
                break;
            default:
                return null;
        }

        return (
            <>
                <div className="d-flex align-items-center justify-content-center mb-4">
                    <i className={`las ${icon} la-3x text-primary me-2`}></i>
                    <h2 className="h4 mb-0">{title}</h2>
                </div>

                <div className="row mb-5">
                    <div className="col-lg-12">
                        <input
                            ref={uploadRef}
                            className={`shadow-0 bg-none custom-file-upload ${fileClass}`}
                            type="file"
                            autoComplete="off"
                            name="nft_image"
                            id="nft_image"
                            accept={accept}
                            placeholder="e.g. Crypto Funk"
                            onChange={(e) => onSelectFile(e, nftType)}
                        />
                        <label
                            className={`form-label text-gray-500 text-center border-gray-400 ${
                                selectedFile ? 'p-3' : 'p-5'
                            }`}
                            htmlFor="nft_image"
                        >
                            {!selectedFile && (
                                <div className="my-5">
                                    <i
                                        className={`las text-muted ${icon}`}
                                        style={{ fontSize: '5rem' }}
                                    ></i>
                                    <h6 className="mb-0 fw-normal text-gray-500">
                                        {t('click', { ns: 'createItem' })}
                                    </h6>
                                    <p className="text-muted mb-0">
                                        {t('waiting', { ns: 'createItem' })}
                                    </p>
                                    <p className="text-muted mb-0">
                                        {fileUploadHint}
                                    </p>
                                    <p className="text-danger">
                                        {fileValidationMsg}
                                    </p>
                                </div>
                            )}

                            {selectedFile && (
                                <div style={{ position: 'relative' }}>
                                    {item}
                                    <ItemToolBox
                                        onFullscreenClick={onFullscreenClick}
                                        onReselectClick={onReselectClick}
                                        onlyFullscreen={false}
                                    ></ItemToolBox>
                                </div>
                            )}
                        </label>
                    </div>
                </div>
            </>
        );
    };

    const renderThumbnailImageSection = () => {
        switch (nftType) {
            case 'video':
            case 'audio':
            case '3d':
                const icon = 'la-image';
                const title = t('thumbnail', { ns: 'createItem' });
                const accept = '.jpg, .png, .gif, .webp';
                const item = (
                    <img
                        src={thumbnail}
                        className="img-fluid"
                        alt={enteredName}
                    />
                );
                return (
                    <>
                        <div className="d-flex align-items-center justify-content-center mb-4">
                            <i
                                className={`las ${icon} la-3x text-primary me-2`}
                            ></i>
                            <h2 className="h4 mb-0">{title}</h2>
                        </div>

                        <div className="row mb-5">
                            <div className="col-lg-12">
                                <input
                                    className={`shadow-0 bg-none custom-file-upload ${thumbnailImageClass}`}
                                    type="file"
                                    autoComplete="off"
                                    name="thumbnail_image"
                                    id="thumbnail_image"
                                    accept={accept}
                                    placeholder="e.g. Crypto Funk"
                                    onChange={onSelectThumbnailImage}
                                />
                                <label
                                    className={`form-label text-gray-500 text-center border-gray-400 ${
                                        selectedThumbnailImage ? 'p-3' : 'p-5'
                                    }`}
                                    htmlFor="thumbnail_image"
                                >
                                    {!selectedThumbnailImage && (
                                        <div className="my-5">
                                            <i
                                                className={`las text-muted ${icon}`}
                                                style={{ fontSize: '5rem' }}
                                            ></i>
                                            <h6 className="mb-0 fw-normal text-gray-500">
                                                {t('click', {
                                                    ns: 'createItem',
                                                })}
                                            </h6>
                                            <p className="text-muted mb-0">
                                                {t('waiting', {
                                                    ns: 'createItem',
                                                })}
                                            </p>
                                            <p className="text-muted mb-0">
                                                {t('imageHint', {
                                                    ns: 'createItem',
                                                })}
                                            </p>
                                            <p className="text-danger">
                                                {thumbnailImageValidationMsg}
                                            </p>
                                        </div>
                                    )}

                                    {selectedThumbnailImage && item}
                                </label>
                            </div>
                        </div>
                    </>
                );
            default:
                return null;
        }
    };
    return (
        <>
            {isCreatingNft ? (
                <FullScreenLoader
                    heading={t('creating', { ns: 'createItem' })}
                />
            ) : null}
            <PageBanner heading={t('createNft', { ns: 'createItem' })} />
            <section className="py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div
                            className="col-xl-8"
                            data-aos="fade"
                            data-aos-delay="100"
                        >
                            <div className="mb-5 pb-4">
                                <div className="d-flex align-items-center  mb-4">
                                    <i className="las la-image la-3x text-primary me-2"></i>
                                    <h2 className="h4 mb-0">
                                        {t('uploadType', { ns: 'createItem' })}
                                    </h2>
                                </div>
                                {renderTabs()}
                            </div>

                            <form className="" onSubmit={submitSaveHandler}>
                                {renderUploadSection()}
                                {renderThumbnailImageSection()}

                                <div className="d-flex align-items-center mb-4">
                                    <i className="las la-icons la-3x me-2 text-primary"></i>
                                    <h2 className="h4 mb-0">
                                        {t('addInfo', { ns: 'createItem' })}
                                    </h2>
                                </div>

                                <div>
                                    <div className="row gy-3 has-field-icons">
                                        <div className="col-lg-12">
                                            <label
                                                className="form-label text-dark lead fw-bold"
                                                htmlFor="nft_title"
                                            >
                                                {t('title', {
                                                    ns: 'createItem',
                                                })}
                                            </label>
                                            <div className="input-icon">
                                                <div className="input-icon-text bg-none">
                                                    <i className="text-primary las la-user-edit"></i>
                                                </div>
                                                <input
                                                    className={`form-control bg-white shadow-0 ${nameClass}`}
                                                    type="text"
                                                    autoComplete="off"
                                                    name="nft_title"
                                                    id="nft_title"
                                                    placeholder={t('e.g.', {
                                                        ns: 'createItem',
                                                    })}
                                                    value={enteredName}
                                                    onChange={
                                                        enteredNameHandler
                                                    }
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <label
                                                className="form-label text-dark lead fw-bold"
                                                htmlFor="nft_category"
                                            >
                                                {t('category', {
                                                    ns: 'createItem',
                                                })}
                                            </label>
                                            <div className="input-icon flex-nowrap category-select">
                                                <div className="input-icon-text bg-none">
                                                    <i className="las la-icons text-primary z-index-20"></i>
                                                </div>
                                                <Select
                                                    placeholder={t('select', {
                                                        ns: 'createItem',
                                                    })}
                                                    searchable={false}
                                                    options={
                                                        i18next.language ===
                                                        'cn'
                                                            ? categoryOptionsCn
                                                            : categoryOptionsEn
                                                    }
                                                    className={`${categoryClass} border-gray-300 shadow-0 bg-white`}
                                                    value={enteredCategory}
                                                    onChange={(values) => {
                                                        setEnteredCategory(
                                                            values
                                                                .map(
                                                                    (el) =>
                                                                        el.value
                                                                )
                                                                .toString()
                                                        );
                                                        setCategoryIsValid(
                                                            true
                                                        );
                                                    }}
                                                ></Select>
                                            </div>
                                        </div>

                                        <div className="col-lg-6">
                                            <label
                                                className="form-label text-dark lead fw-bold"
                                                htmlFor="nft_royalties"
                                            >
                                                {t('royalties', {
                                                    ns: 'createItem',
                                                })}
                                            </label>
                                            <div className="input-icon flex-nowrap category-select">
                                                <div className="input-icon-text bg-none">
                                                    <i className="las la-percentage text-primary z-index-20"></i>
                                                </div>
                                                <Select
                                                    placeholder={t('select', {
                                                        ns: 'createItem',
                                                    })}
                                                    searchable={false}
                                                    options={settings.royalties}
                                                    className={`${royaltyClass} border-gray-300 shadow-0 bg-white`}
                                                    value={royalties}
                                                    onChange={(values) => {
                                                        setRoyalties(
                                                            values
                                                                .map(
                                                                    (el) =>
                                                                        el.value
                                                                )
                                                                .toString()
                                                        );
                                                        setRoyaltyIsValid(true);
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <label
                                                className="form-label text-dark lead fw-bold"
                                                htmlFor="nft_description"
                                            >
                                                {t('desp', {
                                                    ns: 'createItem',
                                                })}
                                            </label>
                                            <div className="input-icon">
                                                <div className="input-icon-text bg-none">
                                                    <i className="las la-align-left text-primary"></i>
                                                </div>
                                                <textarea
                                                    rows="6"
                                                    className={`form-control shadow-0 bg-white pt-3 ${descriptionClass}`}
                                                    name="nft_description"
                                                    id="nft_description"
                                                    placeholder={t('provide', {
                                                        ns: 'createItem',
                                                    })}
                                                    value={enteredDescription}
                                                    onChange={
                                                        enteredDescriptionHandler
                                                    }
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-check form-switch d-flex align-items-center">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    role="switch"
                                                    id="unlockable"
                                                    onChange={() => {
                                                        setWithUnlockable(
                                                            !withUnlockable
                                                        );
                                                        setUnlockable('');
                                                    }}
                                                />
                                                <label
                                                    className="fw-bold h6 ms-3 pt-1 mb-0"
                                                    htmlFor="unlockable"
                                                >
                                                    {t('unlock', {
                                                        ns: 'createItem',
                                                    })}
                                                </label>
                                            </div>
                                        </div>

                                        {withUnlockable && (
                                            <div className="col-lg-12">
                                                <label
                                                    className="form-label text-dark lead fw-bold"
                                                    htmlFor="unlockableContent"
                                                >
                                                    {t('unlockable', {
                                                        ns: 'createItem',
                                                    })}
                                                </label>
                                                <div className="input-icon">
                                                    <div className="input-icon-text bg-none">
                                                        <i className="text-primary las la-cloud"></i>
                                                    </div>
                                                    <input
                                                        className={`form-control bg-white shadow-0 ${unlockable}`}
                                                        type="text"
                                                        autoComplete="off"
                                                        name="unlockable_content"
                                                        id="unlockableContent"
                                                        placeholder={t(
                                                            'addUrl',
                                                            { ns: 'createItem' }
                                                        )}
                                                        value={
                                                            withUnlockable
                                                                ? unlockable
                                                                : ''
                                                        }
                                                        onChange={
                                                            enteredUnlockableHandler
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}

                                        {/* SUBMIT */}
                                        <div className="col-12">
                                            {userCtx.userIsRegistered &&
                                            userCtx.userInformation ? (
                                                <>
                                                    {userCtx.appOwner ===
                                                        web3Ctx.account ||
                                                    userCtx.userInformation
                                                        .inWhiteList ? (
                                                        <button
                                                            className="btn btn-primary mb-3"
                                                            type="submit"
                                                        >
                                                            <i className="lab la-ethereum me-2"></i>
                                                            {t('createNFT', {
                                                                ns: 'createItem',
                                                            })}
                                                        </button>
                                                    ) : (
                                                        <>
                                                            <button
                                                                className="btn btn-primary mb-3"
                                                                type="button"
                                                                disabled={true}
                                                            >
                                                                <i className="lab la-ethereum me-2"></i>
                                                                {t(
                                                                    'createNFT',
                                                                    {
                                                                        ns: 'createItem',
                                                                    }
                                                                )}
                                                            </button>
                                                            <p className="text0sm text-muted">
                                                                For Test
                                                                Purposes, Only
                                                                users added to
                                                                white list can
                                                                mint.
                                                            </p>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {window.ethereum &&
                                                    networkId ===
                                                        settings.networkId ? (
                                                        <Link
                                                            className="btn btn-primary"
                                                            to="/register"
                                                        >
                                                            <i className="las la-user me-2"></i>
                                                            {t('register', {
                                                                ns: 'createItem',
                                                            })}
                                                        </Link>
                                                    ) : (
                                                        <div className="py-3 px-4 d-inline-block lh-reset bg-light rounded">
                                                            <p className="fw-bold mb-2">
                                                                {t(
                                                                    'visitorWarn',
                                                                    {
                                                                        ns: 'createItem',
                                                                    }
                                                                )}
                                                            </p>
                                                            <span className="text-muted">
                                                                {t('connect', {
                                                                    ns: 'createItem',
                                                                })}
                                                            </span>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* PREVIEW ITEM */}
                        <div
                            className="col-xl-4"
                            data-aos="fade"
                            data-aos-delay="200"
                            style={{
                                position: 'sticky',
                                top: 120,
                                height: '80%',
                            }}
                        >
                            <ItemPreview
                                heading={t('preview', { ns: 'createItem' })}
                                type={nftType}
                                thumbnail={thumbnail}
                                file={selectedFileContent}
                                title={enteredName}
                                category={enteredCategory}
                                author={web3Ctx.account}
                                royalties={royalties}
                            />
                        </div>
                    </div>
                </div>
            </section>
            <ItemLightbox
                toggler={toggler}
                type={nftType}
                file={selectedFileContent}
                enlarge={false}
            ></ItemLightbox>
        </>
    );
}

export default CreateItem;
