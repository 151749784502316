import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { settings } from '../../helpers/settings';
import CollectionContext from '../../providers/collection-context';
import UserContext from '../../providers/user-context';
import AuctionContext from '../../providers/auction-context';
import AOS from 'aos';

// COMPONENTS
import MetaMaskLoader from '../../components/general/MetaMaskLoader';
import FullScreenLoader from '../../components/general/FullScreenLoader';
import Web3Context from '../../providers/web3-context';
import SuccessMessage from '../../components/general/SuccessMessage';
import IconButton from '@mui/material/IconButton';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BadgeAvatar from '../../components/BadgeAvatar/BadgeAvatar';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
// Icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreatedNftPanel from './CreatedNftPanel';
import CollectedNftPanel from './CollectedNftPanel';
import ActivityPanel from './ActivityPanel';
import BidPanel from './BidPanel';
import EthereumIcon from '../../components/Icons/EthereumIcon';
import LinkGroup from './LinkGroup';
import { useTranslation } from 'react-i18next';
import Setting from './Setting/Setting';
import { useSnackbar } from 'notistack';
import Banner from '../../components/Banner/Banner';
import { GraphqlClient } from '../../helpers/graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../../helpers/theGraphClient';
import CircularProgress from '@mui/material/CircularProgress';
import {
    USERPROFILE_COLLECTED_INIT_NUMS,
    USERPROFILE_CREATED_EACH_PAGE_NUMS,
    USERPROFILE_CREATED_INIT_NUMS,
} from '../../helpers/pages';
import InfiniteScroll from 'react-infinite-scroller';

function UserProfile({ topSellers }) {
    const { t } = useTranslation(['userProfile']);
    const { enqueueSnackbar } = useSnackbar();
    const [activeTab, setActiveTab] = React.useState('creation');
    const collectionCtx = useContext(CollectionContext);
    const userCtx = useContext(UserContext);
    const web3Ctx = useContext(Web3Context);
    const auctionCtx = useContext(AuctionContext);
    const [isTopSeller, setIsTopSeller] = useState(null);
    const [isUserExisting, setIsUserExisting] = useState(null);
    const [mintSuccess, setMintSuccess] = useState(false);
    const { address, pageIdentifier } = useParams();
    const history = useHistory();
    const [isMetaMaskOpened, setIsMetaMaskOpened] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [createdHasMore, setCreatedHasMore] = useState(true);
    const [collectedHasMore, setCollectedHasMore] = useState(true);

    const [createdSkip, setCreatedSkip] = useState(0);
    const [ownedSkip, setOwnedSkip] = useState(0);
    const [offerCreatedSkip, setOfferCreatedSkip] = useState(0);
    const [aucCreatedSkip, setAucCreatedSkip] = useState(0);

    const [createdItems, setCreatedItems] = useState([]);
    const [collectedOwnerItems, setCollectedOwnerItems] = useState([]);
    const [collectedOfferCreatorItems, setCollectedOfferCreatorItems] =
        useState([]);
    const [collectedAucCreatorItems, setCollectedAucCreatorItems] = useState(
        []
    );

    const [userDetails, setUserDetails] = useState({
        avatar: '',
        header: '',
        web: '',
        fullName: '',
        about: '',
        facebook: '',
        discord: '',
        twitter: '',
        instagram: '',
    });

    const navTabs = [
        {
            tabIdentifier: 'creation',
            tabName: t('navTab.creation'),
        },
        {
            tabIdentifier: 'collection',
            tabName: t('navTab.collection'),
        },
        {
            tabIdentifier: 'activity',
            tabName: t('navTab.activity'),
        },
        {
            tabIdentifier: 'bid',
            tabName: t('navTab.bid'),
            hidden: address !== web3Ctx.account,
        },

        {
            tabIdentifier: 'community',
            tabName: t('navTab.community'),
            hidden: true,
        },
        {
            tabIdentifier: 'setting',
            tabName: t('navTab.setting'),
            hidden: address !== web3Ctx.account,
        },
    ];

    useEffect(() => {
        if (pageIdentifier) {
            setActiveTab(pageIdentifier);
        }
    }, [pageIdentifier]);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${userDetails.fullName} | ${settings.UISettings.marketplaceBrandName}`;
    }, [userDetails]);

    /*** =============================================== */
    //      CHECK IF USER EXISTS
    /*** =============================================== */
    useEffect(() => {
        const loadUser = async () => {
            const user = await GraphqlClient.getUserInfo(address.toLowerCase());
            if (user) {
                setIsUserExisting(true);
                setUserDetails(user);
            } else {
                setIsUserExisting(false);
            }
        };
        if (address) {
            loadUser();
        }
    }, [address]);

    /*** =============================================== */
    //      GET TOP SELLERS
    /*** =============================================== */
    useEffect(() => {
        const topSellersList = topSellers.map((seller) => seller.address);
        setIsTopSeller(topSellersList.includes(address));
    }, [topSellers, address]);

    /*** =============================================== */
    //      GET TOKENS
    /*** =============================================== */
    useEffect(() => {
        const loadTokens = async () => {
            const { createdDrafts, createdTokens } = await loadCreatedData(
                address,
                {
                    skip: 0,
                    first: USERPROFILE_CREATED_INIT_NUMS,
                }
            );
            setCreatedItems(createdDrafts.concat(createdTokens));
            setCreatedHasMore(
                createdTokens.length === USERPROFILE_CREATED_INIT_NUMS
            );

            const {
                collectedOwnerTokens,
                collectedOfferCreatorTokens,
                collectedAucCreatorTokens,
            } = await loadCollectedData(
                address,
                {
                    skip: 0,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                },
                {
                    skip: 0,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                },
                {
                    skip: 0,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                }
            );
            setCollectedOwnerItems(collectedOwnerTokens);
            setCollectedOfferCreatorItems(collectedOfferCreatorTokens);
            setCollectedAucCreatorItems(collectedAucCreatorTokens);
            setCollectedHasMore(
                collectedOwnerTokens.length ===
                    USERPROFILE_COLLECTED_INIT_NUMS ||
                    collectedOfferCreatorTokens.length ===
                        USERPROFILE_COLLECTED_INIT_NUMS ||
                    collectedAucCreatorTokens.length ===
                        USERPROFILE_COLLECTED_INIT_NUMS
            );
        };
        if (isUserExisting && userDetails) {
            loadTokens().finally(() => {
                setIsLoading(false);
            });
        }
    }, [address, isUserExisting]);

    const loadCreatedData = async (address, paging) => {
        const createdDrafts = await GraphqlClient.getDraftNftsByAccount(
            address.toLowerCase()
        );
        const createdTokens = await getCreatedTokens(
            address.toLowerCase(),
            paging
        );

        const {
            updatedTokens: updatedCreatedTokens,
            owners: updatedCreatedTokenOwners,
        } = updateOwnersAndPrice(createdTokens);

        const usersForCreation = await GraphqlClient.getUsersByAccounts(
            updatedCreatedTokenOwners
        );

        const constructedDrafts = constructDrafts(createdDrafts, userDetails);
        const constructedCreatedTokens = await Promise.all(
            constructTokens(updatedCreatedTokens, usersForCreation)
        );
        return {
            createdDrafts: constructedDrafts,
            createdTokens: constructedCreatedTokens,
        };
    };

    const loadCollectedData = async (
        address,
        ownedPaging,
        offerCreatedPaging,
        aucCreatedPaging
    ) => {
        const { owner_tokens, offer_creator_tokens, auc_creator_tokens } =
            await getCollectedTokens(
                address.toLowerCase(),
                ownedPaging,
                offerCreatedPaging,
                aucCreatedPaging
            );

        const {
            updatedTokens: updatedCollectedOwnerTokens,
            owners: updatedCollectedOwnerTokenOwners,
        } = updateOwnersAndPrice(owner_tokens);

        const {
            updatedTokens: updatedCollectedOfferCreatorTokens,
            owners: updatedCollectedOfferCreatorTokenOwners,
        } = updateOwnersAndPrice(offer_creator_tokens);

        const {
            updatedTokens: updatedCollectedAucCreatorTokens,
            owners: updatedCollectedAucCreatorTokenOwners,
        } = updateOwnersAndPrice(auc_creator_tokens);

        const updatedCollectedTokenOwners = [
            ...new Set(
                updatedCollectedOwnerTokenOwners
                    .concat(updatedCollectedOfferCreatorTokenOwners)
                    .concat(updatedCollectedAucCreatorTokenOwners)
            ),
        ];

        const usersForCollection = await GraphqlClient.getUsersByAccounts(
            updatedCollectedTokenOwners
        );

        const constructedCollectedOwnerTokens = await Promise.all(
            constructTokens(updatedCollectedOwnerTokens, usersForCollection)
        );
        const constructedCollectedOfferCreatorTokens = await Promise.all(
            constructTokens(
                updatedCollectedOfferCreatorTokens,
                usersForCollection
            )
        );
        const constructedCollectedAucCreatorTokens = await Promise.all(
            constructTokens(
                updatedCollectedAucCreatorTokens,
                usersForCollection
            )
        );
        return {
            collectedOwnerTokens: constructedCollectedOwnerTokens,
            collectedOfferCreatorTokens: constructedCollectedOfferCreatorTokens,
            collectedAucCreatorTokens: constructedCollectedAucCreatorTokens,
        };
    };

    const getCollectedTokens = async (
        user,
        ownedPaging,
        offerCreatedPaging,
        aucCreatedPaging
    ) => {
        const GET_OWNER_TOKENS = gql`
            query tokens($skip: Int, $first: Int, $user: String) {
                erc721Tokens(
                    skip: $skip
                    first: $first
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: { owner: $user }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
                }
            }
        `;

        const GET_OFFER_CREATOR_TOKENS = gql`
            query tokens($skip: Int, $first: Int, $user: String) {
                erc721Tokens(
                    skip: $skip
                    first: $first
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: {
                        owner_not: $user
                        offers_: {
                            creator: $user
                            fulfilled: false
                            cancelled: false
                        }
                    }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
                }
            }
        `;

        const GET_AUC_CREATOR_TOKENS = gql`
            query tokens($skip: Int, $first: Int, $user: String) {
                erc721Tokens(
                    skip: $skip
                    first: $first
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: {
                        owner_not: $user
                        auctions_: { creator: $user, active: true }
                    }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
                }
            }
        `;
        const owner_tokens = await theGraphGraphqlClient.runQuery(
            GET_OWNER_TOKENS,
            'erc721Tokens',
            ownedPaging,
            { user }
        );

        const offer_creator_tokens = await theGraphGraphqlClient.runQuery(
            GET_OFFER_CREATOR_TOKENS,
            'erc721Tokens',
            offerCreatedPaging,
            { user }
        );

        const auc_creator_tokens = await theGraphGraphqlClient.runQuery(
            GET_AUC_CREATOR_TOKENS,
            'erc721Tokens',
            aucCreatedPaging,
            { user }
        );

        return {
            owner_tokens,
            offer_creator_tokens,
            auc_creator_tokens,
        };
    };

    const getCreatedTokens = async (creator, paging) => {
        const GET_CREATOR_TOKENS = gql`
            query tokens($skip: Int, $first: Int, $creator: String) {
                erc721Tokens(
                    skip: $skip
                    first: $first
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: { creator: $creator }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
                }
            }
        `;

        const creator_tokens = await theGraphGraphqlClient.runQuery(
            GET_CREATOR_TOKENS,
            'erc721Tokens',
            paging,
            { creator }
        );

        return creator_tokens;
    };

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            if (token.onAuction) {
                const validAuction = token.auctions.filter((auction) => {
                    return auction.active && !auction.cancel;
                })[0];

                const bids = validAuction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                let updatedToken = JSON.parse(JSON.stringify(token));
                updatedToken.auctionId = validAuction.id;
                updatedToken.endTime = parseInt(validAuction.endTime);
                updatedToken.cancelled = validAuction.cancel;
                updatedToken.active = validAuction.active;
                updatedToken.realOwner = validAuction.creator.id;
                updatedToken.bids = bids;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            } else {
                const validOffer = token.offers.filter((offer) => {
                    return !offer.fulfilled && !offer.cancelled;
                })[0];

                let updatedToken = JSON.parse(JSON.stringify(token));

                updatedToken.realOwner = validOffer
                    ? validOffer.creator.id
                    : token.owner.id;
                updatedToken.price = validOffer ? validOffer.price : 0;
                updatedToken.offerId = validOffer ? validOffer.id : null;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            }
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];
            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                if (token.onAuction) {
                    extendedToken = {
                        tokenId: token.id,
                        auctionId: token.auctionId,
                        title: metadata.properties.name.description,
                        img: metadata.properties.file.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        unlockable: metadata.properties.unlockable.description,
                        endAt: token.endTime,
                        bids: token.bids,
                        owner: token.owner.id,
                        cancelled: token.cancel,
                        active: token.active,
                        user: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                    };
                } else {
                    extendedToken = {
                        id: token.id,
                        title: metadata.properties.name.description,
                        file:
                            metadata.properties?.file?.description ||
                            metadata.properties?.image?.description,
                        thumbnail:
                            metadata.properties?.thumbnail?.description ||
                            metadata.properties?.preview?.description ||
                            metadata.properties?.image?.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        unlockable: metadata.properties.unlockable.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        owner: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        price: token.price,
                        offerId: token.offerId,
                    };
                }

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked', e);
            }
        });
        return result;
    };

    const constructDrafts = (drafts, userInfo) => {
        return drafts.map((draft) => {
            return {
                ...draft,
                ownerName: userInfo.fullName,
                ownerAvatar: userInfo.avatar,
            };
        });
    };

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    /*** =============================================== */

    //      OPEN MetaMask Loader
    /*** =============================================== */
    function openMetaMaskLoaderHandler() {
        setIsMetaMaskOpened(true);
    }

    /*** =============================================== */

    //      CLOSE MetaMask Loader
    /*** =============================================== */
    function closeMetaMaskLoaderHandler() {
        setIsMetaMaskOpened(false);
    }

    /*** =============================================== */

    //      Redirecting after Minting
    /*** =============================================== */
    function succssMintHandler() {
        setMintSuccess(true);
    }

    if (!isUserExisting && !isLoading) {
        return (
            <div className="container py-5">
                <div className="row py-5 text-center">
                    <div className="col-lg-6 mx-auto">
                        <p
                            className="mb-0 fw-bold"
                            style={{ fontSize: '10rem' }}
                        >
                            404
                        </p>
                        <h1 className="h2 text-uppercase">Not Found</h1>
                        <p className="text-muted">
                            This page is not found, return to Home page
                        </p>
                        <Link to="/" className="btn btn-gradient-primary">
                            Homepage
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    if (isLoading) {
        return (
            <Box
                width={'100%'}
                height={'50vh'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <CircularProgress />
            </Box>
        );
    }

    /*** =============================================== */
    //      SUCCESS MESSAGE AFTER MINTING
    /*** =============================================== */
    if (mintSuccess)
        return (
            <SuccessMessage
                heading="Great! You've successfully minted your NFT"
                subheading="We're redirecting to homepage"
            />
        );

    const renderNavTabs = () => {
        return navTabs.map(({ tabIdentifier, tabName, hidden }) => {
            if (hidden) {
                return null;
            }

            return (
                <Tab
                    key={`user-gallery-tab-${tabIdentifier}`}
                    label={tabName}
                    value={tabIdentifier}
                />
            );
        });
    };

    const renderTabPanel = () => {
        switch (activeTab) {
            case 'creation':
                return (
                    <CreatedNftPanel
                        address={address}
                        createdTokens={createdItems}
                        userDetails={userDetails}
                        closeMetaMaskLoaderHandler={closeMetaMaskLoaderHandler}
                        openMetaMaskLoaderHandler={openMetaMaskLoaderHandler}
                        succssMintHandler={succssMintHandler}
                    ></CreatedNftPanel>
                );
            case 'collection':
                return (
                    <CollectedNftPanel
                        address={address}
                        collectedTokens={collectedOwnerItems
                            .concat(collectedOfferCreatorItems)
                            .concat(collectedAucCreatorItems)}
                        userDetails={userDetails}
                    ></CollectedNftPanel>
                );

            case 'activity':
                return <ActivityPanel address={address} />;
            case 'bid':
                if (address === web3Ctx.account.toLowerCase()) {
                    return <BidPanel address={address} />;
                }
                return null;
            case 'setting':
                if (
                    address === web3Ctx.account.toLowerCase() &&
                    userCtx.userIsRegistered
                ) {
                    return <Setting />;
                }
                return null;
            default:
                return null;
        }
    };

    const start_and_end = (str) => {
        if (str.length > 20) {
            return (
                str.substr(0, 10) +
                '...' +
                str.substr(str.length - 5, str.length)
            );
        }
        return str;
    };

    const loadMore = async () => {
        if (activeTab === 'creation') {
            const { createdDrafts, createdTokens } = await loadCreatedData(
                address,
                {
                    skip: createdItems.length,
                    first: USERPROFILE_CREATED_EACH_PAGE_NUMS,
                }
            );
            setCreatedItems([
                ...createdItems,
                ...createdDrafts,
                ...createdTokens,
            ]);
            setCreatedHasMore(
                createdTokens.length === USERPROFILE_CREATED_INIT_NUMS
            );
        } else if (activeTab === 'collection') {
            const {
                collectedOwnerTokens,
                collectedOfferCreatorTokens,
                collectedAucCreatorTokens,
            } = await loadCollectedData(
                address,
                {
                    skip: collectedOwnerItems.length,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                },
                {
                    skip: collectedOfferCreatorItems.length,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                },
                {
                    skip: collectedAucCreatorItems.length,
                    first: USERPROFILE_COLLECTED_INIT_NUMS,
                }
            );
            setCollectedOwnerItems([
                ...collectedOwnerItems,
                ...collectedOwnerTokens,
            ]);
            setCollectedOfferCreatorItems([
                ...collectedOfferCreatorItems,
                ...collectedOfferCreatorTokens,
            ]);
            setCollectedAucCreatorItems([
                ...collectedAucCreatorItems,
                ...collectedAucCreatorTokens,
            ]);

            setCollectedHasMore(
                collectedOwnerTokens.length ===
                    USERPROFILE_COLLECTED_INIT_NUMS ||
                    collectedOfferCreatorTokens.length ===
                        USERPROFILE_COLLECTED_INIT_NUMS ||
                    collectedAucCreatorTokens.length ===
                        USERPROFILE_COLLECTED_INIT_NUMS
            );
        }
    };

    return (
        <>
            {isMetaMaskOpened ? <MetaMaskLoader /> : null}
            {auctionCtx.fetchingLoading ? (
                <FullScreenLoader heading="Updating Auctions" />
            ) : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            {collectionCtx.nftIsLoading ? (
                <FullScreenLoader heading="loading" />
            ) : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}

            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={
                    activeTab === 'creation'
                        ? createdHasMore
                        : activeTab === 'collection'
                        ? collectedHasMore
                        : false
                }
                initialLoad={false}
            >
                <section className="py-5">
                    <div className="container py-5">
                        <Banner bgUrl={userDetails.header} />
                        <Grid container marginTop={'-4rem'}>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                display="flex"
                                justifyContent={{
                                    xs: 'center',
                                    sm: 'flex-start',
                                }}
                                alignItems="center"
                            >
                                <BadgeAvatar
                                    badgeContent={
                                        isTopSeller && (
                                            <span className="bg-primary badge mb-0 ms-2 text-xxs">
                                                <i className="las la-trophy me-1 text-xxs"></i>
                                                {t('topSeller')}
                                            </span>
                                        )
                                    }
                                    avatarSrc={
                                        userDetails.avatar ||
                                        '/images/astronaut.png'
                                    }
                                ></BadgeAvatar>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            sx={{ fontSize: '12px' }}
                            marginTop={'1rem'}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                display="flex"
                                flexDirection={'column'}
                                justifyContent="center"
                                alignItems={{ xs: 'center', sm: 'flex-start' }}
                            >
                                <Typography
                                    variant="h5"
                                    style={{
                                        fontWeight: 'bold',
                                        paddingLeft: 8,
                                    }}
                                >
                                    {userDetails && userDetails.fullName}
                                </Typography>
                                <Box marginTop={'1rem'}>
                                    <EthereumIcon color={'primary'} />{' '}
                                    <Typography
                                        variant={'body1'}
                                        component={'span'}
                                    >
                                        {' '}
                                        {start_and_end(address)}{' '}
                                    </Typography>{' '}
                                    <Tooltip title={t('tooltip.copy')}>
                                        <IconButton
                                            aria-label={t('tooltip.copy')}
                                            color="primary"
                                            onClick={() => {
                                                navigator.clipboard
                                                    .writeText(address)
                                                    .then(() => {
                                                        enqueueSnackbar(
                                                            t(
                                                                'copyWalletAddressSuccess'
                                                            ),
                                                            {
                                                                variant:
                                                                    'success',
                                                            }
                                                        );
                                                    });
                                            }}
                                        >
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                display={'flex'}
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <LinkGroup
                                    userDetails={userDetails}
                                    address={address}
                                />
                            </Grid>
                        </Grid>

                        <Grid container marginTop={'2rem'}>
                            <Grid item xs={12}>
                                <Box
                                    sx={{
                                        borderBottom: 1,
                                        borderColor: 'divider',
                                    }}
                                >
                                    <Tabs
                                        value={activeTab}
                                        onChange={(e, newValue) => {
                                            setActiveTab(newValue);
                                            history.push(
                                                `/users/${address}/${newValue}`
                                            );
                                        }}
                                    >
                                        {renderNavTabs()}
                                    </Tabs>
                                </Box>
                            </Grid>
                        </Grid>

                        {renderTabPanel()}
                    </div>
                </section>
            </InfiniteScroll>
        </>
    );
}

export default UserProfile;
