import React from 'react';
import ActivityTable from '../../components/tables/ActivityTable';
import Box from '@mui/material/Box';

const ActivityPanel = ({ address }) => {
    return (
        <Box marginTop={'1rem'}>
            <ActivityTable filteredAddress={address} />
        </Box>
    );
};

export default ActivityPanel;
