import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NftItemSkeleton = () => {
    return (
        <Stack spacing={1} direction={'column'} width={'100%'}>
            <Skeleton variant="rectangular" width={'100%'} height={264} />
            <Stack direction={'column'}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography variant={'h4'} width={'20%'}>
                        <Skeleton variant="text" />
                    </Typography>
                    <Typography variant={'h6'} width={'10%'}>
                        <Skeleton variant="text" />
                    </Typography>
                </Stack>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Typography variant={'h3'} width={'50%'}>
                        <Skeleton variant="text" />
                    </Typography>
                    <Typography variant={'h6'} width={'20%'}>
                        <Skeleton variant="text" />
                    </Typography>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NftItemSkeleton;
