import React, { useContext } from 'react';
import { settings } from '../../helpers/settings';
import { formatFullDate, formatPrice } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import MarketplaceContext from '../../providers/marketplace-context';
import UserContext from '../../providers/user-context';
// the hook
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function AuctionBids({ bids }) {
    const transferLogBoxStyle = {
        overflowY: 'scroll',
        maxHeight: '400px',
    };
    const marketplaceCtx = useContext(MarketplaceContext);
    const userCtx = useContext(UserContext);

    const { t } = useTranslation(['auctionBids']);

    return (
        <div className="pb-5">
            <div
                className="rounded-xl"
                style={{
                    border:
                        marketplaceCtx.themeMode === 'light'
                            ? '3px solid #e9ecef'
                            : '3px solid #282830',
                }}
            >
                <div
                    className="p-4 rounded-xl bg-white"
                    style={bids.length > 4 ? transferLogBoxStyle : null}
                >
                    {bids.map((bid, index) => {
                        return (
                            <div
                                className={`d-flex align-items-center text-muted p-2 bg-light rounded-lg ${
                                    index === bids.length - 1 ? 'mb-0' : 'mb-2'
                                }`}
                                key={index}
                            >
                                <Link
                                    className="text-muted"
                                    to={`/users/${bid.bidder}`}
                                    key={index}
                                >
                                    <div className="author-avatar mt-1">
                                        <span
                                            className="author-avatar-inner"
                                            style={{
                                                background: `url(${bid.bidderAvatar})`,
                                            }}
                                        ></span>
                                    </div>
                                </Link>
                                <div className="mx-3">
                                    <p className="mb-0 text-sm">
                                        <span className="lh-reset">
                                            {t('bid', { ns: 'auctionBids' })}
                                        </span>
                                        <strong className="text-dark lh-reset ms-2">
                                            {formatPrice(bid.amount).toFixed(3)}{' '}
                                            {settings.currency}
                                        </strong>
                                    </p>
                                    <p className="mb-0 text-sm">
                                        {t('by', { ns: 'auctionBids' })}
                                        <Link
                                            className="text-muted"
                                            to={`/users/${bid.bidder}`}
                                            key={index}
                                        >
                                            <strong className="lh-reset text-dark mx-2">
                                                {bid.bidderName}
                                            </strong>
                                        </Link>
                                        {t('at', { ns: 'auctionBids' })}{' '}
                                        {formatFullDate(
                                            bid.bidTime * 1000,
                                            i18next.language
                                        )}
                                    </p>
                                </div>

                                <div className="ms-auto">
                                    {bid.withdraw ? (
                                        <span className="badge lh-reset bg-danger">
                                            {t('cancelled', {
                                                ns: 'auctionBids',
                                            })}
                                        </span>
                                    ) : (
                                        <span className="badge lh-reset bg-info">
                                            {t('active', { ns: 'auctionBids' })}
                                        </span>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                    {bids && bids.length === 0 && (
                        <h6 className="text-center mb-0">
                            {t('noBids', { ns: 'auctionBids' })}
                        </h6>
                    )}
                </div>
            </div>
        </div>
    );
}

export default AuctionBids;
