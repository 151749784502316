import React, { useContext, useEffect, useState } from 'react';
import MarketplaceContext from '../../providers/marketplace-context';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { cookieStorage } from '../../helpers/utils';
import CookieIcon from '@mui/icons-material/Cookie';

export const CookiePopup = () => {
    const [viewport, setViewport] = useState('desktop');
    const marketplaceCtx = useContext(MarketplaceContext);
    const { t } = useTranslation(['cookiePopup']);

    /*** =============================================== */
    //      Cookie popup manage
    /*** =============================================== */
    const storageType = cookieStorage;
    const consentPropertyName = 'user_consent';
    const [shouldShowPopup, setShouldShowPopup] = useState(true);
    const saveToStorage = () => storageType.setItem(consentPropertyName, true);
    const notSaveToStorage = () =>
        storageType.setItem(consentPropertyName, false);
    const cookieDiv = document.querySelector('.cookie-banner');
    const acceptFn = () => {
        saveToStorage(storageType);
        cookieDiv.classList.add('hide');
    };
    const declineFn = () => {
        notSaveToStorage(storageType);
        cookieDiv.classList.add('hide');
    };
    useEffect(() => {
        setShouldShowPopup(!storageType.getItem(consentPropertyName));
        if (window.outerWidth > 991) {
            setViewport('desktop');
        } else {
            setViewport('mobile');
        }
        window.addEventListener('resize', function () {
            if (window.outerWidth > 991) {
                setViewport('desktop');
            } else {
                setViewport('mobile');
            }
        });
    }, []);
    return (
        !shouldShowPopup || (
            <div className="cookie-banner">
                <div className="container">
                    <div
                        className="p-4 rounded-xl bg-white shadow-lg"
                        style={{
                            border:
                                marketplaceCtx.themeMode === 'light'
                                    ? '3px solid #e9ecef'
                                    : '3px solid #282830',
                        }}
                    >
                        {viewport === 'desktop' ? (
                            <div className="d-flex align-items-center">
                                <CookieIcon
                                    sx={{
                                        color: '#d63384',
                                        borderRadius: '50%',
                                        fontSize: 40,
                                    }}
                                ></CookieIcon>
                                <div className="ms-3">
                                    <h5 className="mb-0">
                                        {t('cookieTitle', {
                                            ns: 'cookiePopup',
                                        })}
                                    </h5>
                                    <p className="text-muted mb-0">
                                        {t('cookieConsent1', {
                                            ns: 'cookiePopup',
                                        })}
                                        <br />
                                        {t('cookieConsent2', {
                                            ns: 'cookiePopup',
                                        })}{' '}
                                        <Link to={'/privacy'}>
                                            {t('terms', { ns: 'cookiePopup' })}{' '}
                                        </Link>{' '}
                                        {t('cookieConsent3', {
                                            ns: 'cookiePopup',
                                        })}
                                    </p>
                                </div>

                                <div className="ms-auto">
                                    <div>
                                        <button
                                            id="accept"
                                            className="cookie-button btn btn-gradient-primary text-nowrap"
                                            onClick={acceptFn}
                                        >
                                            {t('accept', {
                                                ns: 'cookiePopup',
                                            })}
                                        </button>

                                        <button
                                            id="decline"
                                            className="cookie-button btn btn-gradient-secondary text-nowrap"
                                            onClick={declineFn}
                                        >
                                            {t('decline', {
                                                ns: 'cookiePopup',
                                            })}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <img
                                    src="/images/metamask.png"
                                    alt="Metamask"
                                    className="flex-shrink-0"
                                    width="40"
                                />
                                <div className="ms-3">
                                    <h6 className="mb-0">
                                        {t('cookieTitle', {
                                            ns: 'cookiePopup',
                                        })}
                                    </h6>
                                    <p className="text-muted mb-0 small">
                                        {t('cookieConsent1', {
                                            ns: 'cookiePopup',
                                        })}
                                        <br />
                                        {t('cookieConsent2', {
                                            ns: 'cookiePopup',
                                        })}{' '}
                                        <Link to={'/privacy'}>
                                            {t('terms', { ns: 'cookiePopup' })}{' '}
                                        </Link>{' '}
                                        {t('cookieConsent3', {
                                            ns: 'cookiePopup',
                                        })}
                                    </p>
                                    <button
                                        id="accept"
                                        className="cookie-button btn btn-gradient-primary text-nowrap"
                                        onClick={acceptFn}
                                    >
                                        {t('accept', {
                                            ns: 'cookiePopup',
                                        })}
                                    </button>
                                    <button
                                        id="decline"
                                        onClick={declineFn}
                                        className="cookie-button btn btn-gradient-secondary text-nowrap"
                                    >
                                        {t('decline', {
                                            ns: 'cookiePopup',
                                        })}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default CookiePopup;
