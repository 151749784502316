import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    formatFullDate,
    formatPrice,
    truncateStart,
} from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import DataTable, { createTheme } from 'react-data-table-component';
import CollectionContext from '../../providers/collection-context';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import ReactPlayer from 'react-player';
import { TRANSACTIONS_NUMS } from '../../helpers/pages';
import { GraphqlClient } from '../../helpers/graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../../helpers/theGraphClient';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

createTheme(
    'solarized',
    {
        background: {
            default: '#1c1c22',
        },
    },
    'dark'
);

function TransactionsTable() {
    const { t } = useTranslation(['activity']);
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const [transactions, setTransactions] = useState(null);
    const [marketplaceAddress, setMarketplaceAddress] = useState('');
    const [auctionContractAddress, setAuctionContractAddress] = useState('');

    /*** =============================================== */
    //      GET CONTRACT ADDRESS
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.contract) {
            setMarketplaceAddress(
                marketplaceCtx.contract.options.address.toLowerCase()
            );
        }
    }, [marketplaceCtx.contract]);

    /*** =============================================== */
    //      GET CONTRACT ADDRESS
    /*** =============================================== */
    useEffect(() => {
        if (auctionCtx.contract) {
            setAuctionContractAddress(
                auctionCtx.contract.options.address.toLowerCase()
            );
        }
    }, [auctionCtx.contract]);

    /*** =============================================== */
    //      GET TRANSACTIONS
    /*** =============================================== */

    useEffect(() => {
        const loadActivities = async () => {
            const transactions = await getTransactions({
                skip: 0,
                first: TRANSACTIONS_NUMS,
            });

            const accounts = [
                ...new Set(
                    transactions.reduce((arr, el) => {
                        return arr.concat([el.from.id, el.from.id]);
                    }, [])
                ),
            ];
            const users = await GraphqlClient.getUsersByAccounts(accounts);
            const extendedTransactions = constructTransactions(
                transactions,
                users
            );

            setTransactions(extendedTransactions);
        };

        loadActivities().catch(console.error);
    }, []);

    const getTransactions = async (paging) => {
        const GET_TRANSACTIONS = gql`
            query transactions($skip: Int, $first: Int) {
                userTransactions(skip: $skip, first: $first, orderBy: time) {
                    from {
                        id
                    }
                    to {
                        id
                    }
                    token {
                        id
                    }
                    price
                    time
                }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_TRANSACTIONS,
            'userTransactions',
            paging
        );
    };

    const constructTransactions = (transactions, users) => {
        const result = transactions.map((transaction) => {
            const fromUser = users.filter(
                (el) => el.account.toLowerCase() === transaction.from.id
            )[0];
            const toUser = users.filter(
                (el) => el.account.toLowerCase() === transaction.to.id
            )[0];

            return {
                from: {
                    address: transaction.from.id,
                    name: fromUser?.fullName,
                    avatar: fromUser?.avatar,
                },
                to: {
                    address: transaction.to.id,
                    name: toUser?.fullName,
                    avatar: toUser?.avatar,
                },
                tokenId: parseInt(transaction.token.id),
                price: parseInt(transaction.price),
                time: parseInt(transaction.time) * 1000,
            };
        });
        return result;
    };

    /*** =============================================== */
    //      TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: t('tokenTrans', { ns: 'activity' }),
            selector: (row) => row.tokenId,
            cell: (row) => (
                <div row={row}>
                    {collectionCtx.collection &&
                    collectionCtx.collection.length > 0 ? (
                        <div className="d-flex align-items-center py-3 overflow-hidden">
                            <div className="author-avatar rounded-xl overflow-hidden">
                                {collectionCtx.collection.filter(
                                    (nft) => nft.id === row.tokenId
                                )[0].type === 'image' && (
                                    <span
                                        className="author-avatar-inner rounded-xl"
                                        style={{
                                            background: `url(${
                                                process.env
                                                    .REACT_APP_IPFS_GATEWAY
                                            }${
                                                collectionCtx.collection.filter(
                                                    (nft) =>
                                                        nft.id === row.tokenId
                                                )[0].file
                                            })`,
                                        }}
                                    ></span>
                                )}
                                {collectionCtx.collection.filter(
                                    (nft) => nft.id === row.tokenId
                                )[0].type === 'audio' && (
                                    <>
                                        <span
                                            className="author-avatar-inner rounded-xl"
                                            style={{
                                                background: `linear-gradient(45deg, #4ca1af, #c4e0e5)`,
                                            }}
                                        ></span>
                                        <i className="las la-music text-white position-absolute top-50 start-50 translate-middle z-index-20"></i>
                                    </>
                                )}
                                {collectionCtx.collection.filter(
                                    (nft) => nft.id === row.tokenId
                                )[0].type === 'video' && (
                                    <div className="player-wrapper z-index-20">
                                        <ReactPlayer
                                            url={`${
                                                process.env
                                                    .REACT_APP_IPFS_GATEWAY
                                            }${
                                                collectionCtx.collection.filter(
                                                    (nft) =>
                                                        nft.id === row.tokenId
                                                )[0].file
                                            }`}
                                            controls={false}
                                            width="140%"
                                            height="100%"
                                        />
                                    </div>
                                )}
                            </div>
                            <p className="mb-0 fw-bold ms-3">
                                {truncateStart(
                                    collectionCtx.collection.filter(
                                        (nft) => nft.id === row.tokenId
                                    )[0].title,
                                    20
                                )}
                            </p>
                        </div>
                    ) : (
                        t('loading', { ns: 'activity' })
                    )}
                </div>
            ),
        },
        {
            name: t('transFrom', { ns: 'activity' }),
            selector: (row) => row.from,
            cell: (row) => (
                <div row={row}>
                    {row.from.address === marketplaceAddress ||
                    row.from.address === auctionContractAddress ? (
                        <Link to="/" className="text-reset">
                            <div className="d-flex align-items-center">
                                <div className="author-avatar author-avatar-sm">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(/images/mkt-avatar.png)`,
                                        }}
                                    ></span>
                                </div>
                                <p className="ms-2 mb-0 fw-bold">Marketplace</p>
                            </div>
                        </Link>
                    ) : (
                        <Link
                            to={`/users/${row.from.address}`}
                            className="text-reset"
                        >
                            <div className="d-flex align-items-center">
                                <div className="author-avatar author-avatar-sm">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(${
                                                row.from.avatar === ''
                                                    ? '/images/astronaut.png'
                                                    : row.from.avatar
                                            })`,
                                        }}
                                    ></span>
                                </div>
                                <p className="ms-2 mb-0 fw-bold">
                                    {row.from.name}
                                </p>
                            </div>
                        </Link>
                    )}
                </div>
            ),
        },
        {
            name: t('transTo', { ns: 'activity' }),
            selector: (row) => row.from,
            cell: (row) => (
                <div row={row}>
                    {row.to.address === marketplaceAddress ||
                    row.to.address === auctionContractAddress ? (
                        <Link to="/" className="text-reset">
                            <div className="d-flex align-items-center">
                                <div className="author-avatar author-avatar-sm">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(/images/mkt-avatar.png)`,
                                        }}
                                    ></span>
                                </div>
                                <p className="ms-2 mb-0 fw-bold">Marketplace</p>
                            </div>
                        </Link>
                    ) : (
                        <Link
                            to={`/users/${row.to.address}`}
                            className="text-reset"
                        >
                            <div className="d-flex align-items-center">
                                <div className="author-avatar author-avatar-sm">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(${
                                                row.to.avatar === ''
                                                    ? '/images/astronaut.png'
                                                    : row.to.avatar
                                            })`,
                                        }}
                                    ></span>
                                </div>
                                <p className="ms-2 mb-0 fw-bold">
                                    {row.to.name}
                                </p>
                            </div>
                        </Link>
                    )}
                </div>
            ),
        },
        {
            name: t('time', { ns: 'activity' }),
            selector: (row) => row.time,
            cell: (row) => (
                <div row={row}>
                    <p className="mb-0">
                        {formatFullDate(row.time, i18next.language)}
                    </p>
                </div>
            ),
        },
        {
            name: t('price', { ns: 'activity' }),
            selector: (row) => row.price,
            cell: (row) => (
                <div row={row}>
                    {row.price > 0 ? (
                        <p className="mb-0">
                            {formatPrice(row.price)} {settings.currency}
                        </p>
                    ) : (
                        '-'
                    )}
                </div>
            ),
        },
    ];

    return (
        transactions && (
            <DataTable
                columns={columns}
                data={transactions.sort((a, b) => {
                    return new Date(b.time) - new Date(a.time);
                })}
                pagination={transactions.length >= 10 && true}
                responsive
                theme="solarized"
            />
        )
    );
}

export default TransactionsTable;
