import { useLoader } from '@react-three/fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { Box3, DoubleSide, Mesh, Vector3 } from 'three';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import React, { useMemo } from 'react';

const Model = ({ modelUrl }) => {
    const gltf = useLoader(GLTFLoader, modelUrl, (loader) => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderConfig({ type: 'js' });
        dracoLoader.setDecoderPath(
            'https://www.gstatic.com/draco/v1/decoders/'
        );
        loader.setDRACOLoader(dracoLoader);
    });

    const object = gltf.scene;
    const box = new Box3().setFromObject(object);
    const offset = new Vector3();
    const size = box.getSize(offset).length();
    const center = box.getCenter(offset);

    center.multiplyScalar(-1);
    object.doubleSided = true;

    object.traverse((o) => {
        if (o instanceof Mesh) {
            o.geometry.translate(center.x, center.y, center.z);
        }

        if (o.isMesh) {
            o.material.side = DoubleSide;
        }
    });

    box.setFromObject(object);

    const copiedObject = useMemo(() => object.clone(), [object]);

    return (
        <>
            <primitive object={copiedObject} size={size} />
        </>
    );
};

export default Model;
