import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function NotFound() {
    /*** =============================================== */
    //      CHANGE NAVBAR STYLE
    /*** =============================================== */
    useEffect(() => {
        document.querySelector('.navbar').classList.add('navbar-active');
        return () => {
            document.querySelector('.navbar').classList.remove('navbar-active');
        };
    }, []);

    const { t } = useTranslation(['notFound']);

    return (
        <div className="container py-5">
            <div className="row py-5 text-center">
                <div className="col-lg-6 mx-auto">
                    <p className="mb-0 fw-bold" style={{ fontSize: '10rem' }}>
                        {t('404', { ns: 'notFound' })}
                    </p>
                    <h1 className="h2 text-uppercase">
                        {t('notFound', { ns: 'notFound' })}
                    </h1>
                    <p className="text-muted">
                        {t('return', { ns: 'notFound' })}
                    </p>
                    <Link to="/" className="btn btn-gradient-primary">
                        {t('homepage', { ns: 'notFound' })}
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;
