import * as React from 'react';
import { useState } from 'react';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { styled } from '@mui/material/styles';
import Compressor from 'compressorjs';
import UploadFileConfirmationDialog from '../Dialogs/UploadFileConfirmationDialog/UploadFileConfirmationDialog';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const BadgeAvatar = ({
    badgeContent,
    avatarSrc,
    isEditable = false,
    onFileSelected,
    size,
}) => {
    const [isHover, setIsHover] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['userProfile']);

    const Overlay = styled('label')`
        background-color: rgba(23, 23, 23, 0.4);
        border-radius: 50%;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        opacity: ${isHover ? 1 : 0};
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.15s ease-in-out 0s;
    `;

    const handleSelect = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            toggleDialog();
            setIsHover(false);
            return;
        }

        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (Math.round(file.size / 1024) >= 10240) {
                enqueueSnackbar(t('personalInfo.avatarDialog.error'), {
                    variant: 'error',
                });
            } else {
                if (file.type === 'image/gif') {
                    setSelectedFile(e.target.files[0]);
                    onFileSelected(e.target.files[0]);
                } else {
                    new Compressor(e.target.files[0], {
                        maxWidth: 800,
                        maxHeight: 800,
                        success(_file) {
                            setSelectedFile(_file);
                            onFileSelected(_file);
                        },
                    });
                }

                toggleDialog();
                setIsHover(false);
            }
        }
    };

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };

    return (
        <>
            <Badge
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={badgeContent}
                style={{ position: 'relative', zIndex: 99 }}
            >
                <Avatar
                    alt="Avatar"
                    src={
                        selectedFile
                            ? URL.createObjectURL(selectedFile)
                            : avatarSrc
                    }
                    sx={{ width: size || 100, height: size || 100 }}
                    style={{ border: '3px solid white' }}
                ></Avatar>
                {isEditable && (
                    <Overlay
                        onMouseEnter={() => {
                            setIsHover(true);
                        }}
                        onMouseLeave={() => {
                            setIsHover(false);
                        }}
                        onClick={toggleDialog}
                    >
                        <AddPhotoAlternateIcon
                            style={{ color: 'white' }}
                            fontSize={'large'}
                        />
                    </Overlay>
                )}
            </Badge>
            <UploadFileConfirmationDialog
                title={t('personalInfo.avatarDialog.title')}
                description={t('personalInfo.avatarDialog.description')}
                uploadButtonText={t('personalInfo.avatarDialog.uploadButton')}
                cancelButtonText={t('personalInfo.avatarDialog.cancelButton')}
                isOpen={isDialogOpen}
                toggleDialog={toggleDialog}
                handleChange={handleSelect}
            />
        </>
    );
};

export default BadgeAvatar;
