import React, { useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Grid';

import PersonIcon from '@mui/icons-material/Person';
import AccountPanel from './AccountPanel';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Setting = () => {
    const { t } = useTranslation(['userProfile']);
    let query = useQuery();

    const [selectedIndex, setSelectedIndex] = React.useState(1);

    useEffect(() => {
        if (query.get('tab')) {
            setSelectedIndex(0);
        }
    }, [query]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const [open, setOpen] = React.useState(false);

    const listItems = [
        {
            text: t('setting.profile'),
            icon: <PersonIcon />,
        },
    ];

    const renderTab = () => {
        switch (query.get('tab')) {
            case 'profile':
                return (
                    <Grid item xs={12} sm={9} marginTop={'2rem'}>
                        <AccountPanel></AccountPanel>
                    </Grid>
                );
            default:
                return (
                    <Grid item xs={12} sm={9} marginTop={'2rem'}>
                        <AccountPanel></AccountPanel>
                    </Grid>
                );
        }
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={2} marginTop={'4rem'} paddingRight={'1rem'}>
                <List subheader={null}>
                    {listItems.map(({ text, icon }) => (
                        <ListItemButton
                            selected={selectedIndex === 0}
                            key={text}
                            sx={{
                                display: 'block',
                                '& ,MuiSelect': {
                                    background:
                                        'linear-gradient(155deg, #df369e14 10%, #fff 40%, #fff)',
                                    borderRadius: '2rem',
                                },
                            }}
                            onClick={(e) => handleListItemClick(e, 0)}
                        >
                            <ListItem
                                sx={{
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 48,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={text}
                                    primaryTypographyProps={{
                                        variant: 'body1',
                                        fontWeight: 'bold',
                                    }}
                                />
                            </ListItem>
                        </ListItemButton>
                    ))}
                </List>
            </Grid>
            {renderTab()}
        </Grid>
    );
};

export default Setting;
