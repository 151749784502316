import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const UploadFileConfirmationDialog = ({
    title,
    description,
    uploadButtonText,
    cancelButtonText,
    isOpen,
    toggleDialog,
    handleChange,
}) => {
    return (
        <Dialog open={isOpen} onClose={toggleDialog}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DialogContentText>{description}</DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            component={'label'}
                        >
                            {uploadButtonText}
                            <input
                                hidden
                                accept="image/*"
                                type="file"
                                onChange={handleChange}
                            />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={toggleDialog}
                            fullWidth
                        >
                            {cancelButtonText}
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default UploadFileConfirmationDialog;
