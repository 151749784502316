import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#df369e',
            light: '#ff6fcf',
            dark: '#a9006f',
        },
    },
    typography: {
        fontFamily: ['Inter'],
    },
});
