import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Compressor from 'compressorjs';
import UploadFileConfirmationDialog from '../Dialogs/UploadFileConfirmationDialog/UploadFileConfirmationDialog';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const Banner = ({ bgUrl, isEditable = false, onFileSelected }) => {
    const [isHover, setIsHover] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['userProfile']);

    const CustomBanner = styled('div')`
        position: relative;
        background: url(${selectedFile
                ? URL.createObjectURL(selectedFile)
                : bgUrl || '/images/header.jpg'})
            center center;
        background-size: cover;
        border-radius: 2rem;
        height: 20vh;
        @media (max-width: 991px) {
            border-radius: 0.5rem;
            height: 30vh;
        }
    `;

    const Overlay = styled('div')`
        background-color: rgba(23, 23, 23, 0.4);
        border-radius: 2rem;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        opacity: ${isHover ? 1 : 0};
        align-items: center;
        justify-content: center;
        cursor: pointer;
    `;

    const handleSelect = (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            toggleDialog();
            return;
        }

        if (e.target.files.length > 0) {
            const file = e.target.files[0];

            if (Math.round(file.size / 1024) >= 10240) {
                enqueueSnackbar(t('personalInfo.bannerDialog.error'), {
                    variant: 'error',
                });
            } else {
                if (file.type === 'image/gif') {
                    setSelectedFile(e.target.files[0]);
                    onFileSelected(e.target.files[0]);
                } else {
                    new Compressor(e.target.files[0], {
                        maxWidth: 1200,
                        maxHeight: 1200,
                        success(_file) {
                            setSelectedFile(_file);
                            onFileSelected(_file);
                        },
                    });
                }
                toggleDialog();
            }
        }
    };

    const toggleDialog = () => {
        setIsDialogOpen(!isDialogOpen);
    };
    return (
        <>
            <CustomBanner
                onMouseEnter={() => {
                    setIsHover(true);
                }}
                onMouseLeave={() => {
                    setIsHover(false);
                }}
            >
                {isEditable && (
                    <Overlay onClick={toggleDialog}>
                        <AddPhotoAlternateIcon
                            style={{ color: 'white' }}
                            fontSize={'large'}
                        />
                    </Overlay>
                )}
            </CustomBanner>
            <UploadFileConfirmationDialog
                title={t('personalInfo.bannerDialog.title')}
                description={t('personalInfo.bannerDialog.description')}
                uploadButtonText={t('personalInfo.bannerDialog.uploadButton')}
                cancelButtonText={t('personalInfo.bannerDialog.cancelButton')}
                isOpen={isDialogOpen}
                toggleDialog={toggleDialog}
                handleChange={handleSelect}
            />
        </>
    );
};

export default Banner;
