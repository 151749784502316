import React, { useReducer } from 'react';

import CollectionContext from './collection-context';

const defaultCollectionState = {
    contract: null,
    totalSupply: null,
    assetHistory: null,
    nftHistory: null,
    nftCreator: null,
    collection: [],
    nftTransactionLoading: false,
    nftIsLoading: true,
};

const collectionReducer = (state, action) => {
    if (action.type === 'CONTRACT') {
        return {
            ...state,
            contract: action.contract,
        };
    }

    if (action.type === 'LOADSUPPLY') {
        return {
            ...state,
            totalSupply: action.totalSupply,
        };
    }

    if (action.type === 'LOADCOLLECTION') {
        return {
            ...state,
            collection: action.collection,
        };
    }

    if (action.type === 'GETASSETHISTORY') {
        return {
            ...state,
            assetHistory: action.assetHistory,
        };
    }

    if (action.type === 'UPDATECOLLECTION') {
        const index = state.collection.findIndex(
            (NFT) => NFT.id === parseInt(action.NFT.id)
        );
        let collection = [];

        if (index === -1) {
            collection = [action.NFT, ...state.collection];
        } else {
            collection = [...state.collection];
        }

        return {
            ...state,
            collection: collection,
        };
    }

    if (action.type === 'UPDATEOWNER') {
        const index = state.collection.findIndex(
            (NFT) => NFT.id === parseInt(action.id)
        );
        let collection = [...state.collection];
        collection[index].owner = action.newOwner;

        return {
            ...state,
            collection: collection,
        };
    }

    if (action.type === 'LOADING') {
        return {
            ...state,
            nftIsLoading: action.loading,
        };
    }

    if (action.type === 'GETNFTHISTORY') {
        return {
            ...state,
            nftCreator: {
                account: action.nftHistory[0][0][0],
                name: action.nftHistory[0][0][1],
                avatar: action.nftHistory[0][0][2],
                time: parseInt(action.nftHistory[0][2]) * 1000,
            },
            nftHistory: action.nftHistory.slice(1).map((el) => {
                return {
                    from: {
                        account: el[0][0],
                        name: el[0][1],
                        avatar: el[0][2],
                    },
                    to: {
                        account: el[1][0],
                        name: el[1][1],
                        avatar: el[1][2],
                    },
                    time: parseInt(el[2]) * 1000,
                    price: el[3],
                };
            }),
        };
    }

    if (action.type === 'GETAUCTIONBIDS') {
        return {
            ...state,
            auctionBids: action.auctionBids,
        };
    }

    if (action.type === 'TRANSACTIONLOADING') {
        return {
            ...state,
            nftTransactionLoading: action.loading,
        };
    }

    if (action.type === 'UPDATEAUCTION') {
        return {
            ...state,
            auctionIsUpdating: action.loading,
        };
    }

    return defaultCollectionState;
};

const CollectionProvider = (props) => {
    const [CollectionState, dispatchCollectionAction] = useReducer(
        collectionReducer,
        defaultCollectionState
    );

    const retrieveNftMetadata = (id, metadata, owner) => {
        return {
            id,
            title: metadata.properties.name.description,
            file:
                metadata.properties?.file?.description ||
                metadata.properties?.image?.description,
            thumbnail:
                metadata.properties?.thumbnail?.description ||
                metadata.properties?.preview?.description ||
                metadata.properties?.image?.description,
            description: metadata.properties.description.description,
            category: metadata.properties.category.description,
            dateCreated: metadata.properties.dateCreated.description,
            royalties: metadata.properties.royalties.description,
            unlockable: metadata.properties.unlockable.description,
            type: metadata.properties.type.description,
            formate: metadata.properties.formate.description,
            owner,
        };
    };

    const loadContractHandler = (web3, NFTCollection, deployedNetwork) => {
        const contract = deployedNetwork
            ? new web3.eth.Contract(NFTCollection.abi, deployedNetwork.address)
            : '';
        dispatchCollectionAction({ type: 'CONTRACT', contract: contract });
        return contract;
    };

    const loadTotalSupplyHandler = async (contract) => {
        const totalSupply = await contract.methods.totalSupply().call();
        dispatchCollectionAction({
            type: 'LOADSUPPLY',
            totalSupply: totalSupply,
        });
        return totalSupply;
    };

    const loadCollectionHandler = async (contract, totalSupply) => {
        let collection = [];

        for (let i = 0; i < totalSupply; i++) {
            const hash = await contract.methods._tokensURIs(i).call();
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                const owner = await contract.methods.ownerOf(i + 1).call();

                collection = [
                    retrieveNftMetadata(i + 1, metadata, owner),
                    ...collection,
                ];
            } catch {
                console.log('an NFT was blocked');
            }
        }
        dispatchCollectionAction({
            type: 'LOADCOLLECTION',
            collection: collection,
        });
    };

    const updateCollectionHandler = async (contract, id, owner) => {
        let NFT;
        const hash = await contract.methods.tokenURI(id).call();
        try {
            const response =
                hash &&
                hash !== '' &&
                (await fetch(
                    `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                ));
            if (!response.ok) {
                console.log('IPFS call has an error');
            }

            const metadata = await response.json();

            NFT = retrieveNftMetadata(parseInt(id), metadata, owner);
        } catch {
            console.log('an NFT was blocked');
        }
        dispatchCollectionAction({ type: 'UPDATECOLLECTION', NFT: NFT });
    };

    const updateOwnerHandler = (id, newOwner) => {
        dispatchCollectionAction({
            type: 'UPDATEOWNER',
            id: id,
            newOwner: newOwner,
        });
    };

    const getAssetHistoryHandler = async (contract, id) => {
        const assetHistory = await contract.methods.getTrack(id).call();
        dispatchCollectionAction({
            type: 'GETASSETHISTORY',
            assetHistory: assetHistory,
        });
        return assetHistory;
    };

    const getNftHistoryHandler = async (contract, id, usersList) => {
        try {
            if (isNaN(id)) return;
            const nftHistory = await contract.methods
                .getNFTTransactions(id)
                .call();
            // Map Name and Avatar
            const mappedNftHistory = nftHistory.map((history) => {
                let from = history[0];
                let to = history[1];
                const foundFrom = usersList.filter((el) => el.account === from);
                let fromName = '',
                    fromAvatar = '',
                    toName = '',
                    toAvatar = '';
                if (foundFrom.length > 0) {
                    fromName = foundFrom[0].fullName || '';
                    fromAvatar = foundFrom[0].avatar || '';
                }
                const foundTo = usersList.filter((el) => el.account === to);
                if (foundTo.length > 0) {
                    toName = foundTo[0].fullName || '';
                    toAvatar = foundTo[0].avatar || '';
                }
                return [
                    [from, fromName, fromAvatar],
                    [to, toName, toAvatar],
                    history[2],
                    history[3],
                ];
            });
            dispatchCollectionAction({
                type: 'GETNFTHISTORY',
                nftHistory: mappedNftHistory,
            });
            return mappedNftHistory;
        } catch (error) {
            console.log(error);
        }
    };

    const setNftIsLoadingHandler = (loading) => {
        dispatchCollectionAction({ type: 'LOADING', loading: loading });
    };

    const setNftTransactionLoadingHandler = (loading) => {
        dispatchCollectionAction({
            type: 'TRANSACTIONLOADING',
            loading: loading,
        });
    };

    const collectionContext = {
        contract: CollectionState.contract,
        totalSupply: CollectionState.totalSupply,
        collection: CollectionState.collection,
        nftIsLoading: CollectionState.nftIsLoading,
        nftTransactionLoading: CollectionState.nftTransactionLoading,
        assetHistory: CollectionState.assetHistory,
        nftHistory: CollectionState.nftHistory,
        nftCreator: CollectionState.nftCreator,
        loadContract: loadContractHandler,
        loadTotalSupply: loadTotalSupplyHandler,
        loadCollection: loadCollectionHandler,
        updateCollection: updateCollectionHandler,
        updateOwner: updateOwnerHandler,
        setNftIsLoading: setNftIsLoadingHandler,
        setNftTransactionLoading: setNftTransactionLoadingHandler,
        getAssetHistory: getAssetHistoryHandler,
        getNftHistory: getNftHistoryHandler,
    };

    return (
        <CollectionContext.Provider value={collectionContext}>
            {props.children}
        </CollectionContext.Provider>
    );
};

export default CollectionProvider;
