import React, { useEffect } from 'react';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

function ContactInfo(props) {
    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);
    const { t } = useTranslation(['contact']);

    return (
        <div className={props.gridWidth}>
            <h5
                className="h2 mb-5 text-center"
                data-aos="fade-up"
                data-aos-delay="100"
            >
                {t('weHelp', { ns: 'contact' })}
            </h5>
            <div className="row gy-3">
                <div
                    className="col-lg-4 d-flex"
                    data-aos="fade-up"
                    data-aos-delay="200"
                >
                    <div className="contact-icon bd-3 border-primary text-primary flex-shrink-0">
                        <i className="las la-globe"></i>
                    </div>
                    <div className="ms-3">
                        <h6>{t('companyAdd', { ns: 'contact' })}</h6>
                        <p className="text-sm text-muted mb-0">
                            Schloßstr. 59a, 70176 Stuttgart
                        </p>
                    </div>
                </div>
                <div
                    className="col-lg-4 d-flex"
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    <div className="contact-icon bd-3 border-primary text-primary flex-shrink-0">
                        <i className="las la-phone"></i>
                    </div>
                    <div className="ms-3">
                        <h6>{t('hotlines', { ns: 'contact' })}</h6>
                        <ul className="list-unstyled">
                            <li>
                                <a
                                    className="text-decoration-none text-sm text-muted mb-1"
                                    rel="noreferrer"
                                    href="tel:+4971133628742"
                                >
                                    +49 (0)711 33628742
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    className="col-lg-4 d-flex"
                    data-aos="fade-up"
                    data-aos-delay="400"
                >
                    <div className="contact-icon bd-3 border-primary text-primary flex-shrink-0">
                        <i className="las la-envelope"></i>
                    </div>
                    <div className="ms-3">
                        <h6>{t('email', { ns: 'contact' })}</h6>
                        <ul className="list-unstyled mb-0">
                            <li>
                                <a
                                    className="text-decoration-none text-sm text-muted mb-1"
                                    rel="noreferrer"
                                    href="mailto:info@3digiart.de"
                                >
                                    info@3digiart.de
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactInfo;
