import React, { useContext, useMemo } from 'react';
import { settings } from '../../helpers/settings';
import { formatFullDate, formatPrice } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function PricesLog({ history }) {
    const transferLogBoxStyle = {
        overflowY: 'scroll',
        maxHeight: '400px',
    };
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);

    const pricesLog = useMemo(() => {
        return history
            .filter((item) => parseInt(item.price) > 0)
            .filter(
                (item) =>
                    item.from.account !==
                    marketplaceCtx.contract.options.address.toLowerCase()
            )
            .filter(
                (item) =>
                    item.from.account !==
                    auctionCtx.contract.options.address.toLowerCase()
            );
    }, [history, marketplaceCtx.contract, auctionCtx.contract]);

    const { t } = useTranslation(['pricesLog']);

    return (
        <div className="pb-5">
            <div
                className="rounded-xl"
                style={{
                    border:
                        marketplaceCtx.themeMode === 'light'
                            ? '3px solid #e9ecef'
                            : '3px solid #282830',
                }}
            >
                <div
                    className="p-4 rounded-xl bg-white"
                    style={pricesLog > 4 ? transferLogBoxStyle : null}
                >
                    {pricesLog.map((item, index) => {
                        return (
                            <div
                                className={`d-flex align-items-center text-muted p-2 bg-light rounded-lg ${
                                    index === pricesLog.length - 1
                                        ? 'mb-0'
                                        : 'mb-2'
                                }`}
                                key={index}
                            >
                                <Link
                                    className="text-muted"
                                    to={`/users/${item.from.account}`}
                                    key={index}
                                >
                                    <div className="author-avatar mt-1">
                                        <span
                                            className="author-avatar-inner"
                                            style={{
                                                background: `url(${item.from.avatar})`,
                                            }}
                                        ></span>
                                    </div>
                                </Link>
                                <div className="mx-3">
                                    <p className="mb-0 text-sm">
                                        <strong className="text-dark lh-reset">
                                            {formatPrice(item.price).toFixed(3)}{' '}
                                            {settings.currency}
                                        </strong>
                                    </p>
                                    <p className="mb-0 text-sm">
                                        {t('by', { ns: 'pricesLog' })}
                                        <Link
                                            className="text-muted"
                                            to={`/users/${item.from.account}`}
                                            key={index}
                                        >
                                            <strong className="lh-reset text-dark mx-2">
                                                {!item.from.name
                                                    ? 'Adi Gallia'
                                                    : item.from.name}
                                            </strong>
                                        </Link>
                                        {t('at', { ns: 'pricesLog' })}
                                        {formatFullDate(
                                            item.time,
                                            i18next.language
                                        )}
                                    </p>
                                </div>
                            </div>
                        );
                    })}

                    {pricesLog && pricesLog.length === 0 && (
                        <h6 className="text-center mb-0">
                            {t('noRecords', { ns: 'pricesLog' })}
                        </h6>
                    )}
                </div>
            </div>
        </div>
    );
}

export default PricesLog;
