import headerEn from './en-us/general/header.json';
import homeBannerEn from './en-us/general/homeBanner.json';
import auctionBidsEn from './en-us/general/auctionBids.json';
import auctionCtaEn from './en-us/general/auctionCta.json';
import auctionHistoryEn from './en-us/general/auctionHistory.json';
import auctionItemEn from './en-us/general/auctionItem.json';
import footerEn from './en-us/general/footer.json';
import fullScreenLoaderEn from './en-us/general/fullScreenLoader.json';
import metaMaskLoaderEn from './en-us/general/metaMaskLoader.json';
import nftCategoryEn from './en-us/general/nftCategory.json';
import nftHistoryEn from './en-us/general/nftHistory.json';
import nftItemEn from './en-us/general/nftItem.json';
import noContractAlertEn from './en-us/general/noContractAlert.json';
import noMetaMaskAlertEn from './en-us/general/noMetaMaskAlert.json';
import notFoundEn from './en-us/general/notFound.json';
import pageBannerEn from './en-us/general/pageBanner.json';
import preloaderEn from './en-us/general/preloader.json';
import pricesLogEn from './en-us/general/pricesLog.json';
import updatingDataLoaderEn from './en-us/general/updatingDataLoader.json';
import viewOnlyAlertEn from './en-us/general/viewOnlyAlert.json';
import userProfileEn from './en-us/general/userProfile.json';
import mdfilesEn from './en-us/general/mdfiles.json';
import homeEn from './en-us/general/home.json';
import topSellersEn from './en-us/general/topSellers.json';
import exploreEn from './en-us/general/explores.json';
import auctionEn from './en-us/general/auction.json';
import activityEn from './en-us/general/activity.json';
import createItemEn from './en-us/general/createItem.json';
import itemPreviewEn from './en-us/general/itemPreview.json';
import nftPanelEn from './en-us/general/nftPanel.json';
import cookiePopupEn from './en-us/general/cookiePopup.json';
import contactEn from './en-us/general/contact.json';
import categoryEn from './en-us/general/category.json';
import communityEn from './en-us/general/community.json';
import searchEn from './en-us/general/search.json';
import itemSingleEn from './en-us/general/itemSingle.json';
import itemInfoPanelEn from './en-us/general/itemInfoPanel.json';
import auctionSingleEn from './en-us/general/auctionSingle.json';
import registerFormEn from './en-us/general/registerForm.json';

import headerCn from './zh-cn/general/header.json';
import homeBannerCn from './zh-cn/general/homeBanner.json';
import auctionBidsCn from './zh-cn/general/auctionBids.json';
import auctionCtaCn from './zh-cn/general/auctionCta.json';
import auctionHistoryCn from './zh-cn/general/auctionHistory.json';
import auctionItemCn from './zh-cn/general/auctionItem.json';
import footerCn from './zh-cn/general/footer.json';
import fullScreenLoaderCn from './zh-cn/general/fullScreenLoader.json';
import metaMaskLoaderCn from './zh-cn/general/metaMaskLoader.json';
import nftCategoryCn from './zh-cn/general/nftCategory.json';
import nftHistoryCn from './zh-cn/general/nftHistory.json';
import nftItemCn from './zh-cn/general/nftItem.json';
import noContractAlertCn from './zh-cn/general/noContractAlert.json';
import noMetaMaskAlertCn from './zh-cn/general/noMetaMaskAlert.json';
import notFoundCn from './zh-cn/general/notFound.json';
import pageBannerCn from './zh-cn/general/pageBanner.json';
import preloaderCn from './zh-cn/general/preloader.json';
import pricesLogCn from './zh-cn/general/pricesLog.json';
import updatingDataLoaderCn from './zh-cn/general/updatingDataLoader.json';
import viewOnlyAlertCn from './zh-cn/general/viewOnlyAlert.json';
import userProfileCn from './zh-cn/general/userProfile.json';
import mdfilesCn from './zh-cn/general/mdfiles.json';
import homeCn from './zh-cn/general/home.json';
import topSellersCn from './zh-cn/general/topSellers.json';
import exploreCn from './zh-cn/general/explores.json';
import auctionCn from './zh-cn/general/auction.json';
import activityCn from './zh-cn/general/activity.json';
import createItemCn from './zh-cn/general/createItem.json';
import itemPreviewCn from './zh-cn/general/itemPreview.json';
import nftPanelCn from './zh-cn/general/nftPanel.json';
import cookiePopupCn from './zh-cn/general/cookiePopup.json';
import communityCn from './zh-cn/general/community.json';
import searchCn from './zh-cn/general/search.json';
import contactCn from './zh-cn/general/contact.json';
import categoryCn from './zh-cn/general/category.json';
import itemSingleCn from './zh-cn/general/itemSingle.json';
import itemInfoPanelCn from './zh-cn/general/itemInfoPanel.json';
import auctionSingleCn from './zh-cn/general/auctionSingle.json';
import registerFormCn from './zh-cn/general/registerForm.json';

export const resources = {
    en: {
        header: headerEn,
        homeBanner: homeBannerEn,
        auctionBids: auctionBidsEn,
        auctionCta: auctionCtaEn,
        auctionHistory: auctionHistoryEn,
        auctionItem: auctionItemEn,
        footer: footerEn,
        fullScreenLoader: fullScreenLoaderEn,
        metaMaskLoader: metaMaskLoaderEn,
        nftCategory: nftCategoryEn,
        nftHistory: nftHistoryEn,
        nftItem: nftItemEn,
        noContractAlert: noContractAlertEn,
        noMetaMaskAlert: noMetaMaskAlertEn,
        notFound: notFoundEn,
        pageBanner: pageBannerEn,
        preloader: preloaderEn,
        pricesLog: pricesLogEn,
        updatingDataLoader: updatingDataLoaderEn,
        viewOnlyAlert: viewOnlyAlertEn,
        userProfile: userProfileEn,
        mdfiles: mdfilesEn,
        home: homeEn,
        topSellers: topSellersEn,
        explore: exploreEn,
        auction: auctionEn,
        activity: activityEn,
        createItem: createItemEn,
        itemPreview: itemPreviewEn,
        nftPanel: nftPanelEn,
        cookiePopup: cookiePopupEn,
        contact: contactEn,
        category: categoryEn,
        community: communityEn,
        search: searchEn,
        itemSingle: itemSingleEn,
        itemInfoPanel: itemInfoPanelEn,
        auctionSingle: auctionSingleEn,
        registerForm: registerFormEn,
    },
    cn: {
        header: headerCn,
        homeBanner: homeBannerCn,
        auctionBids: auctionBidsCn,
        auctionCta: auctionCtaCn,
        auctionHistory: auctionHistoryCn,
        auctionItem: auctionItemCn,
        footer: footerCn,
        fullScreenLoader: fullScreenLoaderCn,
        metaMaskLoader: metaMaskLoaderCn,
        nftCategory: nftCategoryCn,
        nftHistory: nftHistoryCn,
        nftItem: nftItemCn,
        noContractAlert: noContractAlertCn,
        noMetaMaskAlert: noMetaMaskAlertCn,
        notFound: notFoundCn,
        pageBanner: pageBannerCn,
        preloader: preloaderCn,
        pricesLog: pricesLogCn,
        updatingDataLoader: updatingDataLoaderCn,
        viewOnlyAlert: viewOnlyAlertCn,
        userProfile: userProfileCn,
        mdfiles: mdfilesCn,
        home: homeCn,
        topSellers: topSellersCn,
        explore: exploreCn,
        auction: auctionCn,
        activity: activityCn,
        createItem: createItemCn,
        itemPreview: itemPreviewCn,
        nftPanel: nftPanelCn,
        cookiePopup: cookiePopupCn,
        contact: contactCn,
        category: categoryCn,
        community: communityCn,
        search: searchCn,
        itemSingle: itemSingleCn,
        itemInfoPanel: itemInfoPanelCn,
        auctionSingle: auctionSingleCn,
        registerForm: registerFormCn,
    },
    de: {},
};
