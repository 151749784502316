import React, { useEffect, useRef } from 'react';
import { formatCategory, truncateStart } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { useTranslation } from 'react-i18next';

const melodyStyle = {
    fontSize: '5rem',
    color: '#fff',
    position: 'absolute',
    top: '4rem',
    left: '50%',
    transform: 'translateX(-50%)',
};

function ItemPreview({
    heading,
    thumbnail,
    file,
    title,
    category,
    author,
    royalties,
    type,
}) {
    const previewWrapperEl = useRef(null);
    const { t } = useTranslation(['itemPreview']);
    useEffect(() => {
        if (previewWrapperEl.current && type === 'audio' && thumbnail) {
            previewWrapperEl.current.style.backgroundImage = `url(${thumbnail})`;
            previewWrapperEl.current.style.backgroundSize = 'cover';
            previewWrapperEl.current.style.backgroundPosition = 'center center';
        }
    }, [previewWrapperEl, thumbnail]);

    const renderItem = () => {
        switch (type) {
            case 'image':
            case '3d':
                return (
                    <img
                        className="img-fluid rounded w-100"
                        src={!thumbnail ? '/images/asset-1.png' : thumbnail}
                        alt={title}
                    />
                );
            case 'audio':
                return (
                    <>
                        <i className="las la-music" style={melodyStyle}></i>
                        <AudioPlayer
                            src={
                                type === 'audio' &&
                                (!file ? '/example.mp3' : file)
                            }
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                        />
                    </>
                );
            case 'video':
                return (
                    <ReactPlayer
                        url={
                            type === 'video' &&
                            (!file ? '/angry-walk.mp4' : file)
                        }
                        controls={true}
                        width="100%"
                        height="auto"
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div className="d-flex align-items-center mb-4">
                <i className="las la-eye la-3x me-2 text-primary"></i>
                <h2 className="h4 mb-0">{heading}</h2>
            </div>

            <div className="card rounded-xl shadow">
                <div className="card-body p-3 position-relative">
                    <div className="position-relative mb-4 shadow">
                        <div
                            ref={previewWrapperEl}
                            className={`card-img-holder rounded overflow-hidden align-items-end ${
                                type === 'audio' && 'audio'
                            }`}
                        >
                            {renderItem()}
                        </div>
                    </div>
                    <div className="fw-bold lead mb-3 d-flex align-items-center justify-content-between">
                        <p className="mb-0">
                            {title === ''
                                ? t('egtitle', { ns: 'itemPreview' })
                                : truncateStart(title, 25)}
                        </p>
                        <div className="badge bg-primary d-flex align-items-center text-white mb-0 ms-3">
                            {t(formatCategory(category), { ns: 'itemPreview' })}
                        </div>
                    </div>

                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="author position-static z-index-20 d-flex align-items-center">
                            <div className="author-avatar">
                                <span
                                    className="author-avatar-inner"
                                    style={{
                                        background: 'url(/avatar-3.png)',
                                    }}
                                ></span>
                            </div>
                            <div className="ms-2">
                                <p className="text-muted fw-normal mb-0 lh-1">
                                    <span className="text-xs">
                                        {t('owned', { ns: 'itemPreview' })}
                                    </span>
                                    <strong className="d-block fw-bold h6 text-dark mb-0">
                                        {truncateStart(author || '0xfff', 10)}
                                    </strong>
                                </p>
                            </div>
                        </div>

                        <p className="text-muted fw-normal mb-0 lh-1">
                            <span className="text-xs">
                                {t('price', { ns: 'itemPreview' })}
                            </span>
                            <strong className="d-block fw-bold h6 text-dark mb-0">
                                0.02 {settings.currency}
                            </strong>
                        </p>
                    </div>

                    <div className="text-muted fw-normaltext-sm d-flex align-items-center mt-4 justify-content-between">
                        <p className="mb-0 text-xs d-flex align-items-center">
                            <i className="las la-percentage me-1"></i>
                            <span className="me-1 text-primary">
                                {royalties}%
                            </span>
                            {t('royalties', { ns: 'itemPreview' })}
                        </p>
                        <p className="text-xs mb-0 d-flex align-items-center">
                            <i className="la-sm text-primary las la-clock mx-1 text-primary"></i>
                            {t('time', { ns: 'itemPreview' })}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

ItemPreview.defaultProps = {
    title: 'A Cat Girl',
    category: 'music',
    type: 'image',
    thumbnail: '',
};

export default ItemPreview;
