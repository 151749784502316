import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from '@formspree/react';
import { settings } from '../../helpers/settings';
import MarketplaceContext from '../../providers/marketplace-context';
import Container from '@mui/material/Container';
// the hook
import { useTranslation } from 'react-i18next';
import Web3Context from '../../providers/web3-context';
import Box from '@mui/material/Box';

function Footer() {
    const web3Ctx = useContext(Web3Context);
    const [state, handleSubmit] = useForm('xlezgplp');
    const marketplaceCtx = useContext(MarketplaceContext);
    const { t, i18n } = useTranslation(['footer']);

    const columns = [
        {
            head: t('marketplace.head'),
            items: [
                {
                    text: t('marketplace.explore'),
                    link: '/explore',
                },
                {
                    text: t('marketplace.featured'),
                    link: '/?featured',
                },
                {
                    text: t('marketplace.auction'),
                    link: '/auctions',
                },
                {
                    text: t('marketplace.activity'),
                    link: '/activity',
                },
                {
                    text: t('marketplace.mint'),
                    link: '/mint',
                },
                {
                    text: t('marketplace.community'),
                    link: '/community',
                },
            ],
        },
        {
            head: t('account.head'),
            hidden: !web3Ctx.account,
            items: [
                {
                    text: t('account.profile'),
                    link: '/users/${web3Ctx.account}/setting?tab=profile',
                },
                {
                    text: t('account.creation'),
                    link: `/users/${web3Ctx.account}/creation`,
                },
                {
                    text: t('account.collection'),
                    link: `/users/${web3Ctx.account}/collection`,
                },
                {
                    text: t('account.activity'),
                    link: `/users/${web3Ctx.account}/activity`,
                },
            ],
        },
        {
            head: t('resource.head'),
            items: [
                {
                    text: t('resource.getStarted'),
                    link: 'https://support.goocean.io',
                },
                {
                    text: t('resource.helpCenter'),
                    link:
                        i18n.language === 'cn'
                            ? 'https://goocean.zendesk.com/hc/zh-cn'
                            : 'https://goocean.zendesk.com/hc/en-001',
                },
                {
                    text: t('resource.partner'),
                    link: `/contact`,
                },
            ],
        },
    ];

    const renderColumns = () => {
        return columns.map(({ head, hidden, items }) => {
            if (hidden) {
                return null;
            }
            return (
                <div
                    style={{ visibility: hidden ? 'hidden' : 'visible' }}
                    className="col-xl-2 col-lg-3 col-md-6 mb-lg-0 pt-2"
                    key={'footer-item' + Math.random()}
                >
                    <h5 className="mb-4">{head}</h5>
                    <ul className="list-unstyled text-muted mb-0">
                        {items.map(({ text, link, hidden }) => {
                            if (hidden) {
                                return null;
                            }

                            const reg = new RegExp('^(http|https)://', 'i');

                            if (reg.test(link)) {
                                return (
                                    <li
                                        className="mb-2"
                                        key={'footer-li' + Math.random()}
                                    >
                                        <a
                                            className="text-reset"
                                            href={link}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {text}
                                        </a>
                                    </li>
                                );
                            } else {
                                return (
                                    <li
                                        className="mb-2"
                                        key={'footer-li' + Math.random()}
                                    >
                                        <Link className="text-reset" to={link}>
                                            {text}
                                        </Link>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                </div>
            );
        });
    };

    return (
        <footer className="footer bg-map bg-light mt-auto">
            <div className="container py-5 z-index-20">
                <div className="row gy-4 pt-4">
                    <div className="col-lg-3 col-md-6 mb-lg-0">
                        <img
                            src={
                                marketplaceCtx.themeMode === 'dark'
                                    ? settings.UISettings.logoPortraitLight
                                    : settings.UISettings.logoPortraitDark
                            }
                            alt="Elysium"
                            className="mb-3"
                            width="140"
                        />
                    </div>
                    {renderColumns()}
                    <div className="col-lg-3 col-md-6 mb-lg-0 pt-2">
                        <h5 className="mb-4">
                            {t('community.head', { ns: 'footer' })}
                        </h5>
                        <ul className="list-unstyled text-muted mb-0">
                            <li className="mb-2">
                                <a
                                    style={{ marginRight: 20 }}
                                    className="text-reset"
                                    href="https://twitter.com/Goocean3D"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i
                                        className="lab la-twitter"
                                        style={{ fontSize: '2rem' }}
                                    />
                                </a>
                                <a
                                    className="text-reset"
                                    href="https://discord.com/channels/1006891982767001610/1006891982767001613"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i
                                        className="lab la-discord"
                                        style={{ fontSize: '2rem' }}
                                    />
                                </a>
                            </li>
                        </ul>

                        <h5 className="mb-4 mt-4">
                            {t('newsletter.title', { ns: 'footer' })}
                        </h5>
                        <p className="text-sm text-muted">
                            {t('newsletter.description', { ns: 'footer' })}
                        </p>
                        <form onSubmit={handleSubmit}>
                            <div className="input-group border border-2 border-gray-400 rounded-pill">
                                <input
                                    className="form-control border-0 bg-none shadow-0 ps-4"
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                    placeholder={t('newsletter.placeholder')}
                                />
                                <button
                                    className="btn btn-primary btn-sm shadow-0 m-2 icon-md"
                                    type="submit"
                                    style={{
                                        borderRadius: '50rem',
                                        width: '4rem',
                                    }}
                                >
                                    {t('iamin')}
                                </button>
                            </div>
                        </form>
                        {state.succeeded ? (
                            <p className="bg-primary text-white mt-1 px-3 py-1 rounded-sm">
                                {t('thanks', { ns: 'footer' })}
                            </p>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="border border-gray-200 rounded-pill"></div>
            </div>

            <div className="container py-4 z-index-20">
                <Container maxWidth="xl">
                    <Box
                        component={'p'}
                        className="text-muted text-sm mb-0"
                        display={'flex'}
                        justifyContent={'space-between'}
                    >
                        © {new Date().getFullYear()}{' '}
                        {t('allRightsReserved', { ns: 'footer' })}{' '}
                        <div>
                            <Link
                                className="text-reset"
                                style={{ marginLeft: 10 }}
                                to={'/privacy'}
                            >
                                {t('privacy', { ns: 'footer' })}{' '}
                            </Link>{' '}
                            <Link
                                className="text-reset"
                                style={{ marginLeft: 10 }}
                                to={'/terms-service'}
                            >
                                {' '}
                                {t('tos', { ns: 'footer' })}
                            </Link>
                        </div>
                    </Box>
                </Container>
            </div>
        </footer>
    );
}

export default Footer;
//<div className="container py-4 z-index-20">
