const writeFileToStorage = async (signedUrl, selectedFile) => {
    await fetch(signedUrl, {
        method: 'PUT',
        body: selectedFile,
        headers: {
            'Content-Type': selectedFile.type,
        },
    });
};

const readFileFromStorage = async (signedUrl) => {
    const response = await fetch(signedUrl, {
        method: 'GET',
    });
    return response.blob();
};

export const fetchClient = {
    writeFileToStorage: writeFileToStorage,
    readFileFromStorage: readFileFromStorage,
};
