import React from 'react';
import styled from 'styled-components';

const TabButton = ({ className, onClick, children }) => {
    const CustomButton = styled.button`
        border: none;
        background: none;
        border-radius: 0.5rem;
        outline: none !important;
        padding: 0.5rem 1rem;
        font-size: 1.1rem;
        font-weight: 700;
        transition: all 0.3s;
        color: ${({ theme }) => theme.fontColor} !important;

        &.active {
            background: ${({ theme }) => theme.tabButtonBackgroundColor};
            box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        }
    `;

    return (
        <CustomButton className={className} onClick={onClick}>
            {children}
        </CustomButton>
    );
};

export default TabButton;
