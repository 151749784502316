import React, { useContext, useEffect, useState } from 'react';
import CollectionContext from '../../providers/collection-context';
import AuctionContext from '../../providers/auction-context';
import { settings } from '../../helpers/settings.js';

// COMPONENTS
import UsersTable from '../tables/UsersTable';
import NftsTable from '../tables/NftsTable';
import AuctionsTable from '../tables/AuctionsTable';
import MetaMaskLoader from '../general/MetaMaskLoader';
import Web3Context from '../../providers/web3-context';
import { useSnackbar } from 'notistack';
import { theGraphGraphqlClient } from '../../helpers/theGraphClient';
import { gql } from '@apollo/client';

function Dashboard({ usersList, whiteList, ownerFunds }) {
    const collectionCtx = useContext(CollectionContext);
    const auctionCtx = useContext(AuctionContext);
    const web3Ctx = useContext(Web3Context);
    const [usersNumber, setUsersNumber] = useState(0);
    const [collectionNumber, setCollectionNumber] = useState(0);
    const [auctionsNumber, setAuctionsNumber] = useState(0);
    const [whiteListedNumber, setWhiteListedNumber] = useState(0);
    const [isMetaMaskOpened, setIsMetaMaskOpened] = useState(false);
    const [collection, setCollection] = useState(null);
    const [auctions, setAuctions] = useState(null);
    const [finalOwnerFunds, setFinalOwnerFunds] = useState(0);
    const { enqueueSnackbar } = useSnackbar();

    /*** =============================================== */
    //      GET USERS LIST LENGTH
    /*** =============================================== */
    useEffect(() => {
        if (usersList) {
            setUsersNumber(usersList.length);
        }
    }, [usersList]);

    /*** =============================================== */
    //      GET Auctions LENGTH
    /*** =============================================== */
    useEffect(() => {
        if (auctions) {
            setAuctionsNumber(auctions.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctions]);

    /*** =============================================== */
    //      GET WHITELISTED USERS LENGTH
    /*** =============================================== */
    useEffect(() => {
        if (whiteList) {
            setWhiteListedNumber(
                whiteList.filter(
                    (user) =>
                        user.account !==
                        '0x0000000000000000000000000000000000000000'
                ).length
            );
        }
    }, [whiteList]);

    // Get Collection Number
    useEffect(() => {
        if (collection) {
            setCollectionNumber(collection.length);
        }
    }, [collection]);

    useEffect(() => {
        if (ownerFunds) {
            const res = ownerFunds.reduce((prev, curr) => {
                return prev + curr.fund;
            }, 0);
            setFinalOwnerFunds(res);
        }
    }, [ownerFunds]);

    useEffect(() => {
        const loadData = async () => {
            const tokens = await getTokens();

            const updatedTokens = updateOwnersAndPrice(tokens);

            const constructedTokens = await Promise.all(
                constructTokens(updatedTokens)
            );

            const auctions = await getAuctions();
            const constructedAuctions = await Promise.all(
                constructAuctions(auctions)
            );

            setCollection(constructedTokens);
            setAuctions(constructedAuctions);
        };
        if (usersList && whiteList) {
            loadData();
        }
    }, [usersList, whiteList]);

    const updateOwnersAndPrice = (tokens) => {
        const updatedTokens = tokens.map((token) => {
            const validOffer = token.offers.filter((offer) => {
                return !offer.fulfilled && !offer.cancelled;
            })[0];

            let updatedToken = JSON.parse(JSON.stringify(token));

            updatedToken.realOwner = validOffer
                ? validOffer.creator.id
                : token.owner.id;
            updatedToken.price = validOffer ? validOffer.price : 0;
            updatedToken.offerId = validOffer ? validOffer.id : null;
            return updatedToken;
        });
        return updatedTokens;
    };

    const getTokens = async () => {
        const GET_TOKENS = gql`
            query tokens {
                erc721Tokens(
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: { onAuction: false }
                ) {
                    id
                    owner {
                        id
                    }
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    uri
                    createdTimestamp
                    category
                    onSalePrice
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_TOKENS,
            'erc721Tokens'
        );
    };

    const constructTokens = (tokens) => {
        const result = tokens.map(async (token) => {
            const user = usersList.filter(
                (el) => el.account === token.realOwner
            )[0];
            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                extendedToken = {
                    id: token.id,
                    title: metadata.properties.name.description,
                    file:
                        metadata.properties?.file?.description ||
                        metadata.properties?.image?.description,
                    thumbnail:
                        metadata.properties?.thumbnail?.description ||
                        metadata.properties?.preview?.description ||
                        metadata.properties?.image?.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    unlockable: metadata.properties.unlockable.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    owner: token.realOwner,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                    price: token.price,
                };

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked');
            }
        });
        return result;
    };

    const getAuctions = async () => {
        const GET_AUCTIONS = gql`
            {
                auctions(where: { active: true }) {
                    id
                    token {
                        id
                        uri
                        owner {
                            id
                        }
                    }
                    creator {
                        id
                    }
                    active
                    cancel
                    endTime
                    bids {
                        bidder {
                            id
                        }
                        withdraw
                        amount
                        time
                    }
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_AUCTIONS,
            'auctions'
        );
    };

    const constructAuctions = (auctions) => {
        const result = auctions.map(async (auction) => {
            const user = usersList.filter(
                (el) => el.account === auction.creator.id
            )[0];

            const hash = auction.token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }
                let bids = auction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                const metadata = await response.json();

                let auctionData = {
                    tokenId: auction.token.id,
                    auctionId: auction.id,
                    title: metadata.properties.name.description,
                    file: metadata.properties.file.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    unlockable: metadata.properties.unlockable.description,
                    endAt: parseInt(auction.endTime),
                    bids,
                    owner: auction.token.owner.id,
                    cancelled: auction.cancel,
                    active: auction.active,
                    user: auction.creator.id,
                    ownerName: user?.fullName,
                    ownerAvatar: user?.avatar,
                };

                return auctionData;
            } catch {
                console.log('an Auction was blocked');
            }
        });
        return result;
    };

    function BlockNftHandler(ids) {
        collectionCtx.contract.methods
            .unwanted(ids)
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setIsMetaMaskOpened(true);
            })
            .once('sending', () => {
                setIsMetaMaskOpened(true);
            })
            .on('error', (e) => {
                enqueueSnackbar('Oops! Something went wrong', {
                    variant: 'error',
                });
                setIsMetaMaskOpened(false);
            })
            .on('receipt', () => {
                enqueueSnackbar("Cool! you've blocked the selected NFT", {
                    variant: 'success',
                });
                setIsMetaMaskOpened(false);
            });
    }

    function BlocAuctionHandler(ids) {
        auctionCtx.contract.methods
            .unwanted(ids)
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setIsMetaMaskOpened(true);
            })
            .once('sending', () => {
                setIsMetaMaskOpened(true);
            })
            .on('error', (e) => {
                enqueueSnackbar('Oops! Something went wrong', {
                    variant: 'error',
                });
                setIsMetaMaskOpened(false);
            })
            .on('receipt', () => {
                enqueueSnackbar("Cool! you've blocked the selected Auctions", {
                    variant: 'success',
                });
                setIsMetaMaskOpened(false);
            });
    }

    return (
        <>
            {isMetaMaskOpened && <MetaMaskLoader />}
            <div className="row g-4" data-aos="fade-up" data-aos-delay="200">
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>Total Registered Users</h5>
                            <p className="h2 fw-normal mb-0">{usersNumber}</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>Total NFTs</h5>
                            <p className="h2 fw-normal mb-0">
                                {collectionNumber}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>Open Auctions</h5>
                            <p className="h2 fw-normal mb-0">
                                {auctionsNumber}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>Marketplace Commission</h5>
                            <p className="h2 fw-normal mb-0">
                                {(settings.saleCommission / 1000) * 100}%
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>WhiteListed Users</h5>
                            <p className="h2 fw-normal mb-0">
                                {whiteListedNumber}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5>Marketplace Owner Profits</h5>
                            <p className="h2 fw-normal mb-0">
                                {parseFloat(finalOwnerFunds / 10 ** 18)}{' '}
                                <span className="h5 fw-normal text-muted">
                                    {settings.currency}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">Users List</h5>
                            <UsersTable
                                users={usersList}
                                ownerFunds={ownerFunds}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">NFTs List</h5>
                            <NftsTable
                                blockNfts={BlockNftHandler}
                                collection={collection}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card shadow-0 rounded-xl p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">Auctions List</h5>
                            <AuctionsTable
                                blockAuction={BlocAuctionHandler}
                                auctions={auctions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;
