// export const {t} = useTranslation(['activity']);
// export function translate(label){
//     const {t} = useTranslation(['activity']);
//     return t('actAndTrans', { ns: 'activity' });
// }
// let label1 = t('actAndTrans', { ns: 'activity' });
export const categoryOptionsEn = [
    { label: 'Art', value: 'art', icon: 'las la-palette' },
    // { label: 'Music', value: 'music', icon: 'las la-music' },
    // { label: 'Game', value: 'game', icon: 'las la-gamepad' },
    { label: 'Memes', value: 'memes', icon: 'las la-frog' },
    /*
    {
        label: 'Trading Cards',
        value: 'trendingCards',
        icon: 'las la-mail-bulk',
    },
     */
    { label: 'Collectibles', value: 'collectibles', icon: 'las la-boxes' },
];
export const categoryOptionsCn = [
    { label: '艺术', value: 'art', icon: 'las la-palette' },
    // { label: 'Music', value: 'music', icon: 'las la-music' },
    // { label: 'Game', value: 'game', icon: 'las la-gamepad' },
    { label: '记忆', value: 'memes', icon: 'las la-frog' },
    /*
    {
        label: 'Trading Cards',
        value: 'trendingCards',
        icon: 'las la-mail-bulk',
    },
     */
    { label: '收藏品', value: 'collectibles', icon: 'las la-boxes' },
];

export const categorySelectBoxEn = [
    { label: 'Trending', value: 'trending' },
    { label: 'Art', value: 'art' },
    // { label: 'Music', value: 'music' },
    // { label: 'Game', value: 'game' },
    { label: 'Memes', value: 'memes' },
    // { label: 'Trading Cards', value: 'trendingCards' },
    { label: 'Collectibles', value: 'collectibles' },
];
export const categorySelectBoxCn = [
    { label: '流行', value: 'trending' },
    { label: '艺术', value: 'art' },
    // { label: 'Music', value: 'music' },
    // { label: 'Game', value: 'game' },
    { label: '记忆', value: 'memes' },
    // { label: 'Trading Cards', value: 'trendingCards' },
    { label: '收藏品', value: 'collectibles' },
];

export const particlesOptions = {
    fpsLimit: 15,
    fullScreen: {
        enable: false,
    },
    particles: {
        color: {
            value: '#3275ac',
        },
        links: {
            color: '#ffffff',
            distance: 150,
            enable: false,
            opacity: 0,
            width: 1,
        },
        collisions: {
            enable: false,
        },
        move: {
            direction: 'top',
            enable: true,
            outMode: 'out',
            random: true,
            speed: 0.5,
            straight: false,
        },
        number: {
            value: 250,
        },
        opacity: {
            value: 0.5,
        },
        shape: {
            type: 'circle',
        },
        size: {
            random: true,
            value: 4,
        },
    },
    detectRetina: true,
};

let apiUrl, apiGqlUrl;
if (process.env.REACT_APP_OFFLINE === 'true') {
    apiGqlUrl = process.env.REACT_APP_API_LOCAL_GQL_URL;
} else {
    apiGqlUrl = process.env.REACT_APP_API_GQL_URL;
}
export const databaseGraphqlURL = apiGqlUrl;
export const theGraphGraphqlURL = process.env.REACT_APP_API_THE_GRAPH_GQL_URL;
