import React from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import RefreshIcon from '@mui/icons-material/Refresh';
import Tooltip from '@mui/material/Tooltip';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

function ItemToolBox({ onFullscreenClick, onReselectClick, onlyFullscreen }) {
    const { t } = useTranslation(['createItem']);
    const ToolboxWrapper = styled('div')`
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: rgb(25, 25, 25, 0.3);
        border-radius: 50px;
        &:hover {
            background-color: rgb(25, 25, 25, 0.6);
            transition: all 0.1s;
        }
    `;

    return (
        <ToolboxWrapper>
            {!onlyFullscreen && (
                <Tooltip
                    title={t('rechoose', {
                        ns: 'createItem',
                    })}
                >
                    <Button className="tool-box" onClick={onReselectClick}>
                        <RefreshIcon sx={{ fontSize: 25 }} />
                    </Button>
                </Tooltip>
            )}
            <Tooltip
                title={t('fullscreen', {
                    ns: 'createItem',
                })}
            >
                <Button className="tool-box" onClick={onFullscreenClick}>
                    <FullscreenIcon sx={{ fontSize: 25 }} />
                </Button>
            </Tooltip>
        </ToolboxWrapper>
    );
}

export default ItemToolBox;
