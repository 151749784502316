import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-dropdown-select';
import MarketplaceContext from '../providers/marketplace-context';
import { formatPrice, truncateStart } from '../helpers/utils';
import { settings } from '../helpers/settings';
import AOS from 'aos';
import Loader from '../components/general/Loader';

// COMPONENTS
import NftItem from '../components/general/NftItem';
import AuctionItem from '../components/general/AuctionItem';
import { gql } from '@apollo/client';
import { GraphqlClient } from '../helpers/graphqlClient';
import { SEARCH_EACH_PAGE_NUMS, SEARCH_INIT_NUMS } from '../helpers/pages';
import InfiniteScroll from 'react-infinite-scroller';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import { useTranslation } from 'react-i18next';

// SEARCH OPTIONS
const searchOptions = [
    { label: 'NFTs', value: 'collection' },
    { label: 'Users', value: 'users' },
];

function Search() {
    const { t } = useTranslation(['search']);
    const marketplaceCtx = useContext(MarketplaceContext);
    const [isSearched, setIsSearched] = useState('collection');
    const [query, setQuery] = useState('');

    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);
    const [users, setUsers] = useState([]);

    const [loading, setLoading] = useState(false);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Search Assets | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    /*** =============================================== */
    //      SEARCH ACCORDING TO DATA CHOSEN
    /*** =============================================== */
    useEffect(() => {
        let didCancel = false;

        const loadData = async () => {
            setLoading(true);
            if (!didCancel && isSearched === 'collection') {
                const collections = await getCollectionDataSearched(query, {
                    skip: 0,
                    take: SEARCH_INIT_NUMS,
                });
                if (!didCancel) {
                    setItems(collections);
                    setHasMore(collections.length === SEARCH_INIT_NUMS);
                    setLoading(false);
                }
            } else if (!didCancel && isSearched === 'users') {
                const users = await getUsersDataSearched(query);
                if (!didCancel) setUsers(users);
                setLoading(false);
            }
        };
        if (query.trim()) {
            loadData();
        } else {
            setItems([]);
            setUsers([]);
            setLoading(false);
        }
        return () => {
            didCancel = true;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, isSearched]);

    const getCollectionDataSearched = async (query, paging) => {
        const nfts = await GraphqlClient.getNftsByTitle(paging, query);
        const uris = nfts.map((nft) => nft.file);

        const tokens = await getTokens(uris);

        const { updatedTokens, owners } = updateOwnersAndPrice(tokens);
        const users = await GraphqlClient.getUsersByAccounts(owners);

        const withMetaDataTokens = await Promise.all(
            constructTokens(updatedTokens, users)
        );

        return withMetaDataTokens;
    };

    const getUsersDataSearched = async (query) => {
        const usersByName = await GraphqlClient.getUsersByName(query);
        return usersByName;
    };

    const getTokens = async (uris) => {
        const GET_TOKENS = gql`
            query tokens($uris: [String]) {
                erc721Tokens(
                    orderBy: createdTimestamp
                    orderDirection: desc
                    where: { uri_in: $uris }
                ) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_TOKENS,
            'erc721Tokens',
            { uris }
        );
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];
            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                if (token.onAuction) {
                    extendedToken = {
                        tokenId: token.id,
                        auctionId: token.auctionId,
                        title: metadata.properties.name.description,
                        img: metadata.properties.file.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        unlockable: metadata.properties.unlockable.description,
                        endAt: token.endTime,
                        bids: token.bids,
                        owner: token.owner.id,
                        cancelled: token.cancel,
                        active: token.active,
                        user: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                    };
                } else {
                    extendedToken = {
                        id: token.id,
                        title: metadata.properties.name.description,
                        file:
                            metadata.properties?.file?.description ||
                            metadata.properties?.image?.description,
                        thumbnail:
                            metadata.properties?.thumbnail?.description ||
                            metadata.properties?.preview?.description ||
                            metadata.properties?.image?.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        unlockable: metadata.properties.unlockable.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        owner: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        price: token.price,
                        offerId: token.offerId,
                    };
                }

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked');
            }
        });
        return result;
    };

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            if (token.onAuction) {
                const validAuction = token.auctions.filter((auction) => {
                    return auction.active && !auction.cancel;
                })[0];

                const bids = validAuction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                let updatedToken = JSON.parse(JSON.stringify(token));
                updatedToken.auctionId = validAuction.id;
                updatedToken.endTime = parseInt(validAuction.endTime);
                updatedToken.cancelled = validAuction.cancel;
                updatedToken.active = validAuction.active;
                updatedToken.realOwner = validAuction.creator.id;
                updatedToken.bids = bids;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            } else {
                const validOffer = token.offers.filter((offer) => {
                    return !offer.fulfilled && !offer.cancelled;
                })[0];

                let updatedToken = JSON.parse(JSON.stringify(token));

                updatedToken.realOwner = validOffer
                    ? validOffer.creator.id
                    : token.owner.id;
                updatedToken.price = validOffer ? validOffer.price : 0;
                updatedToken.offerId = validOffer ? validOffer.id : null;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            }
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const loadMore = async () => {
        if (isSearched === 'collection' && query.trim()) {
            const collections = await getCollectionDataSearched(query, {
                skip: items.length,
                take: SEARCH_EACH_PAGE_NUMS,
            });
            setItems([...items, ...collections]);
            setHasMore(collections.length === SEARCH_EACH_PAGE_NUMS);
        }
    };

    return (
        <>
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={isSearched === 'collection' && hasMore}
                initialLoad={false}
            >
                <section className="pt-5 position-relative page-banner">
                    <div className="container py-4 mt-5 z-index-20">
                        <div className="row align-items-center">
                            <div className="col-xl-7">
                                <h1
                                    className="mb-4"
                                    data-aos="fade-right"
                                    data-aos-delay="100"
                                >
                                    {t('title')}
                                </h1>
                                <form
                                    onSubmit={(e) => e.preventDefault()}
                                    className="mb-4"
                                    data-aos="fade-up"
                                    data-aos-delay="300"
                                >
                                    <div
                                        className="bg-white rounded-lg py-1 ps-1 pe-4 position-relative"
                                        style={{
                                            border:
                                                marketplaceCtx.themeMode ===
                                                'light'
                                                    ? '2px solid #e9ecef'
                                                    : '2px solid #282830',
                                        }}
                                    >
                                        <div className="input-icon pe-5">
                                            <div
                                                className="input-icon-text"
                                                style={{ top: '0.8rem' }}
                                            >
                                                <i className="text-primary las la-search"></i>
                                            </div>
                                            <input
                                                className="form-control bg-none form-control-lg shadow-0 py-2 border-0"
                                                type="search"
                                                autoComplete="off"
                                                name="search"
                                                placeholder={
                                                    isSearched === 'collection'
                                                        ? t('searchNFTs')
                                                        : t('searchUsers')
                                                }
                                                value={query}
                                                onChange={(event) =>
                                                    setQuery(event.target.value)
                                                }
                                            />
                                        </div>
                                        <Select
                                            searchable={false}
                                            options={searchOptions}
                                            placeholder="NFTs"
                                            className="form-select search-form-select ps-4 border-gray-300 shadow-0 bg-white fw-bold"
                                            value={isSearched}
                                            onChange={(values) => {
                                                setIsSearched(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                );
                                                setQuery('');
                                            }}
                                        />
                                    </div>
                                </form>
                                {loading && (
                                    <div>
                                        <Loader></Loader>
                                    </div>
                                )}

                                {!loading &&
                                query !== '' &&
                                (items.length > 0 || users.length > 0) ? (
                                    <p className="lead text-muted mb-0">
                                        {t('matchResult')}
                                    </p>
                                ) : null}

                                {!loading &&
                                query.trim() !== '' &&
                                items.length === 0 &&
                                users.length === 0 ? (
                                    <div className="d-flex">
                                        <i
                                            className="las la-exclamation mb-2"
                                            style={{ fontSize: '3rem' }}
                                        ></i>
                                        <div className="ms-2">
                                            <h3 className="h3">
                                                {t('noResultTitle')}
                                            </h3>
                                            <p className="text-muted mb-0">
                                                {t('noResultDescription')}
                                            </p>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                            <div
                                className="col-lg-5 d-none d-lg-block"
                                data-aos="fade-left"
                                data-aos-delay="150"
                            >
                                <img
                                    src="/images/Canvas.png"
                                    className="img-fluid"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section
                    className={`pb-5 ${
                        marketplaceCtx.themeMode === 'dark' && 'bg-light'
                    }`}
                >
                    <div className="container pb-5">
                        <div className="row gy-4">
                            {!loading &&
                                isSearched === 'collection' &&
                                items &&
                                items.map((NFT, key) => {
                                    if (NFT.auctionId) {
                                        return (
                                            <div
                                                className="col-xl-4 col-md-6"
                                                key={key}
                                            >
                                                <AuctionItem
                                                    {...NFT}
                                                    nftKey={key}
                                                />
                                            </div>
                                        );
                                    } else {
                                        const index = NFT.offerId
                                            ? NFT.offerId
                                            : -1;
                                        const owner = NFT.owner;
                                        const price =
                                            index !== -1
                                                ? formatPrice(
                                                      NFT.price
                                                  ).toFixed(2)
                                                : null;

                                        return (
                                            <div
                                                className={`col-xl-4 col-md-6 ${NFT.category}`}
                                                key={NFT.id}
                                            >
                                                <NftItem
                                                    {...NFT}
                                                    noAnimation={true}
                                                    index={index}
                                                    owner={owner}
                                                    price={price}
                                                    nftKey={key}
                                                    offerPriceBN={NFT.price}
                                                />
                                            </div>
                                        );
                                    }
                                })}

                            {!loading &&
                                isSearched === 'users' &&
                                users &&
                                users.map((user, index) => {
                                    return (
                                        <Link
                                            to={`/users/${user.account}`}
                                            className="col-xl-3 col-lg-4 col-md-6"
                                            key={index}
                                        >
                                            <div className="card bd-3 shadow card-hover-minimal border-0 position-relative rounded-pill text-dark">
                                                <div className="card-body">
                                                    <div className="d-flex align-items-center">
                                                        <div className="author-avatar author-avatar-md">
                                                            <span
                                                                className="author-avatar-inner"
                                                                style={{
                                                                    background: `url(${
                                                                        user.avatar
                                                                            ? user.avatar
                                                                            : '/images/astronaut.png'
                                                                    })`,
                                                                }}
                                                            ></span>
                                                        </div>

                                                        <div className="ms-3">
                                                            <h3 className="h6 mb-1 text-capitalize text-reset">
                                                                {user.fullName &&
                                                                    truncateStart(
                                                                        user.fullName,
                                                                        10
                                                                    )}
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    );
                                })}
                        </div>
                    </div>
                </section>
            </InfiniteScroll>
        </>
    );
}

export default Search;
