import React from 'react';

const UserContext = React.createContext({
    contract: null,
    appOwner: null,
    appOwnerDetails: null,
    che: null,
    usersList: null,
    userBalance: null,
    whiteList: null,
    activity: null,
    transactions: null,
    userAssets: null,
    createdNftsInDB: null,
    userInformationError: false,
    usersListError: false,
    userIsLoading: true,
    userIsRegistered: false,
    getUserInformation: () => {},
    getUserInformationFromDB: () => {},
    getAppOwner: () => {},
    getAppOwnerDetails: () => {},
    loadUserBalance: () => {},
    getUsersList: () => {},
    getUsersListFromDB: () => {},
    loadWhiteList: () => {},
    loadWhiteListFromDB: () => {},
    loadUserAssets: () => {},
    loadContract: () => {},
    loadActivity: () => {},
    loadTransactions: () => {},
    checkRegisteration: () => {},
    setUserIsLoading: () => {},
});

export default UserContext;
