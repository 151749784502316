import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import web3 from '../connect-web3/web3';
import Web3 from 'web3';
import ItemThumbnail from '../components/item/ItemThumbnail';
import ItemInfoPanel from '../components/item/ItemInfoPanel';
import ItemAuthor from '../components/item/ItemAuthor';
import { formatPrice } from '../helpers/utils';
import { settings } from '../helpers/settings';
import Web3Context from '../providers/web3-context';
import CollectionContext from '../providers/collection-context';
import MarketplaceContext from '../providers/marketplace-context';
import UserContext from '../providers/user-context';
import AuctionContext from '../providers/auction-context';
import FullScreenLoader from '../components/general/FullScreenLoader';
import NftHistory from '../components/general/NftHistory';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import NftItem from '../components/general/NftItem';
import PricesLog from '../components/general/PricesLog';
import Modal from '../components/general/Modal';
import SuccessMessage from '../components/general/SuccessMessage';
import { GraphqlClient } from '../helpers/graphqlClient';
import { deleteNftFromDB, mintNft } from '../helpers/nftUtils';
import TabButton from '../components/TabButton/TabButton';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import { useTranslation } from 'react-i18next';
import NftFullScreenSkeleton from '../components/Skeletons/NftFullScreenSkeleton/NftFullScreenSkeleton';

function ItemSingle() {
    const { t } = useTranslation(['itemSingle']);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const auctionCtx = useContext(AuctionContext);
    const [saleType, setSaleType] = useState('');
    const [endDate, setEndDate] = useState(new Date().getTime());
    const [similarItems, setSimilarItems] = useState(null);
    const [marketplaceAddress, setMarketplaceAddress] = useState('');
    const [offerPrice, setOfferPrice] = useState('');
    const [ownerName, setOwnerName] = useState('Loading...');
    const [historyType, setHistoryType] = useState('transactions');
    const [ownerAvatar, setOwnerAvatar] = useState(null);
    const [currentAsset, setCurrentAsset] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCurrentAsset, setIsCurrentAsset] = useState(null);
    const [networkId, setNetworkId] = useState(0);
    const [isFromDB, setIsFromDB] = useState(false);
    const [isMetaMaskOpened, setIsMetaMaskOpened] = useState(false);
    const [mintSuccess, setMintSuccess] = useState(false);
    const [deleteSuccess, setDeleteSuccess] = useState(false);
    const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
        useState(false);
    const { id } = useParams();
    const [toggler, setToggler] = useState(false);

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function init() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(
                    new Web3(window.ethereum)
                );
                setNetworkId(networkId);
            }
        }

        init();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        async function loadTokenData() {
            // check token draft or minted based on id
            const token = await getToken();
            if (token) {
                setIsCurrentAsset(true);
                const { updatedTokens, owners } = updateOwnersAndPrice([token]);
                const users = await GraphqlClient.getUsersByAccounts(owners);
                const withPriceMetaDataTokens = await Promise.all(
                    constructTokens(updatedTokens, users)
                );

                const similarTokens = await getSimilarTokens(
                    withPriceMetaDataTokens[0].category
                );
                const {
                    updatedTokens: updatedSimilarTokens,
                    owners: similarOwners,
                } = updateOwnersAndPrice(similarTokens, true);
                const similarTokenUsers =
                    await GraphqlClient.getUsersByAccounts(similarOwners);

                const withPriceMetaDataSimilarTokens = await Promise.all(
                    constructTokens(
                        updatedSimilarTokens,
                        similarTokenUsers,
                        true
                    )
                );
                setCurrentAsset(withPriceMetaDataTokens);
                setSimilarItems(withPriceMetaDataSimilarTokens, true);

                setOwnerName(withPriceMetaDataTokens[0].ownerName);
                setOwnerAvatar(withPriceMetaDataTokens[0].ownerAvatar);
            } else {
                setIsCurrentAsset(false);
            }
        }

        loadTokenData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getToken = async () => {
        if (isNaN(id)) {
            setIsFromDB(true);
            return await GraphqlClient.getNftInfoById(id);
        } else {
            const GET_TOKEN = gql`
            {
              erc721Token(
                id: ${id}
              ) {
                id
                owner {
                  id
                }
                uri
                offers {
                  id
                  creator {
                    id
                  }
                  fulfilled
                  cancelled
                  price
                }
                category
                creator {
                  id
                }
                createdTimestamp
                onAuction
                onSale
                onSalePrice
                nftTransactions{
                    id
                    nftTransactionActions {
                      from{
                        id
                      }
                      to {
                        id
                      }
                      timestamp
                      price
                    }
                }
              }
            }
            `;

            setIsFromDB(false);
            return await theGraphGraphqlClient.runQueryWithoutPaging(
                GET_TOKEN,
                'erc721Token'
            );
        }
    };

    const getSimilarTokens = async (category) => {
        let conditions = '';
        if (!isNaN(id)) {
            conditions = `, id_not: ${id}`;
        }
        const GET_TOKEN = gql`
        query tokens($skip: Int, $first: Int, $category: String){
          erc721Tokens(skip: $skip, first: $first, where: {category: $category, onAuction: false, onSale: true${conditions}}) {
            id
            owner {
              id
            }
            uri
            offers {
              id
              creator {
                id
              }
              fulfilled
              cancelled
              price
            }
            creator {
                id
            }
            onAuction
            category
            onSale
            onSalePrice
          }
        }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_TOKEN,
            'erc721Tokens',
            { skip: 0, first: 3 },
            { category }
        );
    };

    const updateOwnersAndPrice = (tokens, onlySimilar = false) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            if (!isNaN(token.id)) {
                const validOffer = token.offers.filter((offer) => {
                    return !offer.fulfilled && !offer.cancelled;
                })[0];

                let updatedToken = JSON.parse(JSON.stringify(token));

                updatedToken.realOwner = validOffer
                    ? validOffer.creator.id
                    : token.owner.id;
                updatedToken.price = validOffer ? validOffer.price : 0;
                updatedToken.offerId = validOffer ? validOffer.id : null;
                owners.add(updatedToken.realOwner);
                if (!onlySimilar) {
                    owners.add(token.creator.id);
                    token.nftTransactions.nftTransactionActions.forEach((t) => {
                        owners.add(t.from.id);
                        owners.add(t.to.id);
                    });
                }
                return updatedToken;
            } else {
                owners.add(token.owner);
                return {
                    ...token,
                    realOwner: token.owner,
                };
            }
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const constructTokens = (tokens, users, onlySimilar = false) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];

            if (isNaN(token.id)) {
                return {
                    ...token,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                };
            } else {
                const hash = token.uri;
                try {
                    const response =
                        hash &&
                        hash !== '' &&
                        (await fetch(
                            `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                        ));
                    if (!response.ok) {
                        console.log('IPFS call has an error');
                    }

                    const metadata = await response.json();
                    let extendedToken = {
                        id: token.id,
                        title: metadata.properties.name.description,
                        file:
                            metadata.properties?.file?.description ||
                            metadata.properties?.image?.description,
                        thumbnail:
                            metadata.properties?.thumbnail?.description ||
                            metadata.properties?.preview?.description ||
                            metadata.properties?.image?.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        unlockable: metadata.properties.unlockable.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        owner: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        price: token.price,
                        offerId: token.offerId,
                    };

                    if (!onlySimilar) {
                        const creator = users.filter(
                            (el) => el.account === token.creator.id
                        )[0];
                        extendedToken.creator = {
                            account: token.creator.id,
                            name: creator.fullName,
                            avatar: creator.avatar,
                            time: parseInt(token.createdTimestamp) * 1000,
                        };
                        extendedToken.assetHistory =
                            token.nftTransactions.nftTransactionActions
                                .sort(
                                    (prev, curr) =>
                                        prev.timestamp - curr.timestamp
                                )
                                .map((transaction) => {
                                    let fromUser = users.filter(
                                        (el) =>
                                            el.account === transaction.from.id
                                    )[0];
                                    let toUser = users.filter(
                                        (el) => el.account === transaction.to.id
                                    )[0];
                                    return {
                                        from: {
                                            account: transaction.from.id,
                                            name: fromUser?.fullName,
                                            avatar: fromUser?.avatar,
                                        },
                                        to: {
                                            account: transaction.to.id,
                                            name: toUser?.fullName,
                                            avatar: toUser?.avatar,
                                        },
                                        time:
                                            parseInt(transaction.timestamp) *
                                            1000,
                                        price: transaction.price,
                                    };
                                });
                        extendedToken.nftHistory =
                            extendedToken.assetHistory.slice(1);
                    }
                    return extendedToken;
                } catch (e) {
                    console.log('an NFT was blocked', e);
                }
            }
        });
        return result;
    };

    /*** =============================================== */
    //      DECLARE PRICE REFERENCE
    /*** =============================================== */
    const priceRef = useRef(null);

    /*** =============================================== */
    //      GET NFT DETAILS
    /*** =============================================== */
    /*    const similarItems = useMemo(() => {
        if (nftData && nftData.length > 0 && isCurrentAsset === true) {
            return collectionCtx.collection
                .filter(
                    (nft) =>
                        !auctionCtx.auctions
                            .filter((auc) => auc.isActive === true)
                            .some((auc) => nft.id === auc.tokenId)
                )
                .filter((item) => item.category === nftData[0].category)
                .filter((item) => !isNaN(id) && item.id !== parseInt(id));
        }
    }, [
        nftData,
        id,
        collectionCtx.collection,
        isCurrentAsset,
        auctionCtx.auctions,
    ]);*/
    /*** =============================================== */
    //      GET MARKETPLACE CONTRACT
    /*** =============================================== */
    useEffect(() => {
        if (marketplaceCtx.contract) {
            async function getMarketplaceAddress() {
                const mktAddress = await marketplaceCtx.contract._address;
                setMarketplaceAddress(mktAddress);
            }

            getMarketplaceAddress();
        }
    }, [marketplaceCtx.contract]);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${
            currentAsset.length > 0 ? currentAsset[0].title : 'NFT Item'
        } | Goocean NFT`;
    }, [currentAsset, id]);

    /*** =============================================== */
    //      ADD PRICE FUNCTION
    /*** =============================================== */
    const makeOfferHandler = (event, id, nftKey) => {
        event.preventDefault();

        const enteredPrice = web3.utils.toWei(priceRef.current.value, 'ether');

        collectionCtx.contract.methods
            .approve(marketplaceCtx.contract.options.address, id)
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setIsModalOpen(false);
            })
            .once('sending', () => {
                collectionCtx.setNftTransactionLoading(true);
                setIsModalOpen(false);
            })
            .once('error', (e) => {
                collectionCtx.setNftTransactionLoading(false);
                setIsModalOpen(false);
            })
            .on('receipt', (receipt) => {
                marketplaceCtx.contract.methods
                    .addPrice(id, enteredPrice)
                    .send({ from: web3Ctx.account })
                    .once('sending', () => {
                        collectionCtx.setNftTransactionLoading(true);
                    })
                    .on('receipt', () => {
                        setTimeout(() => {
                            collectionCtx.setNftTransactionLoading(false);
                            setIsModalOpen(false);
                            history.go(0);
                        }, 15000);
                    })
                    .on('error', (error) => {
                        collectionCtx.setNftTransactionLoading(false);
                        setIsModalOpen(false);
                        enqueueSnackbar(t('error', { ns: 'itemSingle' }), {
                            variant: 'error',
                        });
                    });
                collectionCtx.setNftTransactionLoading(false);
                setIsModalOpen(false);
            });
    };

    /*** =============================================== */
    //      BUY NFT FUNCTION
    /*** =============================================== */
    const buyHandler = (event, offerPriceBN) => {
        const offerId = parseInt(event.target.value);
        if (offerId !== -1) {
            marketplaceCtx.contract.methods
                .buyNFT(offerId)
                .send({
                    from: web3Ctx.account,
                    value: offerPriceBN,
                })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('transactionHash', (hash) => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('receipt', () => {
                    setTimeout(() => {
                        collectionCtx.setNftTransactionLoading(false);
                        history.go(0);
                    }, 15000);
                })
                .on('error', (error) => {
                    enqueueSnackbar(t('error', { ns: 'itemSingle' }), {
                        variant: 'error',
                    });
                    collectionCtx.setNftTransactionLoading(false);
                });
        }
    };

    /*** =============================================== */
    //      CANCEL OFFER FUNCTION
    /*** =============================================== */
    const cancelHandler = (event) => {
        const offerId = event.target.value;
        if (offerId !== -1) {
            marketplaceCtx.contract.methods
                .cancelSale(offerId)
                .send({ from: web3Ctx.account })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('transactionHash', (hash) => {
                    collectionCtx.setNftTransactionLoading(true);
                })
                .on('receipt', () => {
                    setTimeout(() => {
                        collectionCtx.setNftTransactionLoading(false);
                        history.go(0);
                    }, 15000);
                })
                .on('error', (error) => {
                    collectionCtx.setNftTransactionLoading(false);
                    enqueueSnackbar(t('error', { ns: 'itemSingle' }), {
                        variant: 'error',
                    });
                });
        }
    };

    /*** =============================================== */
    //      MAKE AUCTION FUNCTION
    /*** =============================================== */
    const makeAuctionHandler = (event, endDate, id) => {
        event.preventDefault();

        if (new Date(endDate).getTime() > new Date().getTime()) {
            collectionCtx.contract.methods
                .approve(auctionCtx.contract.options.address, parseInt(id))
                .send({ from: web3Ctx.account })
                .on('transactionHash', (hash) => {
                    setIsModalOpen(false);
                })
                .once('sending', () => {
                    collectionCtx.setNftTransactionLoading(true);
                    setIsModalOpen(false);
                })
                .once('error', (e) => {
                    collectionCtx.setNftTransactionLoading(false);
                    setIsModalOpen(false);
                })
                .on('receipt', (receipt) => {
                    auctionCtx.contract.methods
                        .createAuction(
                            parseInt(id),
                            new Date(endDate).getTime()
                        )
                        .send({ from: web3Ctx.account })
                        .once('sending', () => {
                            auctionCtx.setAuctionTransactionLoading(true);
                        })
                        .on('receipt', () => {
                            setTimeout(() => {
                                auctionCtx.setAuctionTransactionLoading(false);
                                setIsModalOpen(false);
                                setSaleType('');
                                //userCtx.loadTransactions(userCtx.contract);
                                //userCtx.loadActivity(userCtx.contract);
                                history.push(`/nftauction/${id}`);
                            }, 15000);
                        })
                        .on('error', (error) => {
                            auctionCtx.setAuctionTransactionLoading(false);
                            setIsModalOpen(false);
                            setSaleType('');
                            enqueueSnackbar(t('error', { ns: 'itemSingle' }), {
                                variant: 'error',
                            });
                        });
                    auctionCtx.setAuctionTransactionLoading(false);
                    collectionCtx.setNftTransactionLoading(false);
                    setIsModalOpen(false);
                });
        } else {
            enqueueSnackbar(t('dateError', { ns: 'itemSingle' }), {
                variant: 'error',
            });
        }
    };

    /*** =============================================== */
    //      SUBMIT MINTING FORM
    /*** =============================================== */
    const submitMintHandler = (event) => {
        event.preventDefault();
        const asset = currentAsset[0];
        let formIsValid =
            asset.title && asset.description && asset.file && asset.thumbnail;

        formIsValid &&
            mintNft({
                web3Ctx,
                collectionCtx,
                userCtx,
                openMetaMaskLoader: () => setIsMetaMaskOpened(true),
                closeMetaMaskLoader: () => setIsMetaMaskOpened(false),
                enqueueSnackbar,
                deleteNftFromDB,
                successMint: () => setMintSuccess(true),
                history,
                ...asset,
            });
    };

    /*** =============================================== */
    //      DELETE MINTED NFT FROM DB
    /*** =============================================== */
    const deleteNftFromDBHandler = (event) => {
        event.preventDefault();
        setIsDeleteConfirmModalOpen(false);
        deleteNftFromDB({
            web3Ctx,
            id,
            userCtx,
            deleteSuccess: () => setDeleteSuccess(true),
        });
        setTimeout(() => {
            history.push('/explore');
        }, 2500);
    };

    /*** =============================================== */

    //      CLOSE DELETE NFT MODAL FUNCTION
    /*** =============================================== */
    function closeDeleteConfirmModalHandler() {
        setIsDeleteConfirmModalOpen(false);
    }

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
        setSaleType('');
    }

    if (isCurrentAsset === false) {
        return (
            <div className="container py-5">
                <div className="row py-5 text-center">
                    <div className="col-lg-6 mx-auto">
                        <p
                            className="mb-0 fw-bold"
                            style={{ fontSize: '10rem' }}
                        >
                            404
                        </p>
                        <h1 className="h2 text-uppercase">
                            {t('notFound', { ns: 'itemSingle' })}
                        </h1>
                        <p className="text-muted">
                            {t('return', { ns: 'itemSingle' })}
                        </p>
                        <Link to="/" className="btn btn-gradient-primary">
                            {t('home', { ns: 'itemSingle' })}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    if (mintSuccess)
        return (
            <SuccessMessage
                heading={t('mintSuccess', { ns: 'itemSingle' })}
                subheading={t('redirecting', { ns: 'itemSingle' })}
            />
        );

    if (deleteSuccess)
        return (
            <SuccessMessage
                heading={t('deleteSuccess', { ns: 'itemSingle' })}
                subheading={t('redirecting', { ns: 'itemSingle' })}
            />
        );

    return (
        <>
            {isMetaMaskOpened ? <MetaMaskLoader /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {marketplaceCtx.mktIsLoading ? (
                <FullScreenLoader heading="loading" />
            ) : null}
            <section className="pt-5 bg-light">
                {currentAsset.length === 0 ? (
                    <div className="container pt-5">
                        <div className="pt-5 mt-4">
                            <NftFullScreenSkeleton />
                        </div>
                    </div>
                ) : (
                    currentAsset.map((asset, key) => {
                        const index = asset.offerId ? asset.offerId : -1;
                        const owner = asset.owner;
                        const price =
                            index !== -1
                                ? formatPrice(asset.price).toFixed(2)
                                : null;
                        const priceBN = index !== -1 ? asset.price : 0;
                        return (
                            <div key={key}>
                                <div className="container pt-5">
                                    <div className="pt-5 mt-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Link
                                                className="text-reset"
                                                to={`/users/${
                                                    isFromDB
                                                        ? asset.owner
                                                        : asset.creator.account
                                                }`}
                                            >
                                                <div className="author-avatar">
                                                    <span
                                                        className="author-avatar-inner"
                                                        style={{
                                                            background: `url(${
                                                                asset.ownerAvatar
                                                                    ? asset.ownerAvatar
                                                                    : '/images/astronaut.png'
                                                            })`,
                                                        }}
                                                    ></span>
                                                </div>
                                            </Link>
                                            <div className="ms-3 text-muted d-flex align-items-center">
                                                {t('by', { ns: 'itemSingle' })}
                                                <strong className="fw-bold lh-1 ms-2 lead text-dark">
                                                    <Link
                                                        className="text-reset"
                                                        to={`/users/${
                                                            isFromDB
                                                                ? asset.owner
                                                                : asset.creator
                                                                      .account
                                                        }`}
                                                    >
                                                        {asset.ownerName
                                                            ? asset.ownerName
                                                            : 'Adi Galia'}
                                                    </Link>
                                                </strong>
                                            </div>
                                        </div>
                                        <h1 className="mb-4 text-center">
                                            {asset.title}
                                        </h1>
                                    </div>
                                    <div className="row mb-4 gy-4 mt-4">
                                        <div className="col-lg-6">
                                            <ItemThumbnail
                                                type={asset.type}
                                                file={
                                                    isFromDB
                                                        ? asset.file
                                                        : `${process.env.REACT_APP_IPFS_GATEWAY}${asset.file}`
                                                }
                                                thumbnail={
                                                    isFromDB
                                                        ? asset.thumbnail
                                                        : `${process.env.REACT_APP_IPFS_GATEWAY}${asset.thumbnail}`
                                                }
                                            />

                                            {asset.nftHistory && asset.creator && (
                                                <>
                                                    <div className="toggle-nav mt-5 mb-4">
                                                        <TabButton
                                                            className={`flex-fill ${
                                                                historyType ===
                                                                'transactions'
                                                                    ? 'active'
                                                                    : null
                                                            }`}
                                                            onClick={() =>
                                                                setHistoryType(
                                                                    'transactions'
                                                                )
                                                            }
                                                        >
                                                            <span className="lh-reset">
                                                                {t(
                                                                    'transactions',
                                                                    {
                                                                        ns: 'itemSingle',
                                                                    }
                                                                )}
                                                            </span>
                                                        </TabButton>
                                                        <TabButton
                                                            className={`flex-fill ${
                                                                historyType ===
                                                                'prices'
                                                                    ? 'active'
                                                                    : null
                                                            }`}
                                                            onClick={() =>
                                                                setHistoryType(
                                                                    'prices'
                                                                )
                                                            }
                                                        >
                                                            <span className="lh-reset">
                                                                {t('priceLog', {
                                                                    ns: 'itemSingle',
                                                                })}
                                                            </span>
                                                        </TabButton>
                                                    </div>
                                                    {historyType ===
                                                        'transactions' && (
                                                        <NftHistory
                                                            history={
                                                                asset.nftHistory
                                                            }
                                                            creator={
                                                                isFromDB
                                                                    ? {
                                                                          account:
                                                                              asset.owner,
                                                                          name: asset.ownerName,
                                                                          avatar: asset.ownerAvatar,
                                                                          time: new Date(
                                                                              asset.dateCreated
                                                                          ).getTime(),
                                                                      }
                                                                    : asset.creator
                                                            }
                                                            owner={asset.owner}
                                                            mktAddress={
                                                                marketplaceAddress
                                                            }
                                                        />
                                                    )}
                                                    {historyType ===
                                                        'prices' && (
                                                        <PricesLog
                                                            history={
                                                                asset.nftHistory
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>

                                        <div className="col-lg-6">
                                            {(asset.creator || isFromDB) && (
                                                <ItemInfoPanel
                                                    name={asset.title}
                                                    category={asset.category}
                                                    img={
                                                        isFromDB
                                                            ? asset.thumbnail
                                                            : `${process.env.REACT_APP_IPFS_GATEWAY}${asset.thumbnail}`
                                                    }
                                                    artist={
                                                        isFromDB
                                                            ? asset.owner
                                                            : asset.creator
                                                                  .account
                                                    }
                                                    description={
                                                        asset.description
                                                    }
                                                    dateCreated={
                                                        asset.dateCreated
                                                    }
                                                    royalties={asset.royalties}
                                                    unlockable={
                                                        asset.unlockable
                                                    }
                                                    formate={asset.formate}
                                                    type={asset.type}
                                                />
                                            )}

                                            {asset.unlockable &&
                                                asset.owner ===
                                                    web3Ctx.account.toLowerCase() && (
                                                    <div className="row mb-4">
                                                        <div className="col-xl-8">
                                                            <a
                                                                href={
                                                                    asset.unlockable
                                                                }
                                                                className="btn btn-info px-4 w-100"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="las la-cloud me-2"></i>{' '}
                                                                {t('download', {
                                                                    ns: 'itemSingle',
                                                                })}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}

                                            {asset &&
                                                (asset.creator ? (
                                                    <ItemAuthor
                                                        history={
                                                            asset.assetHistory
                                                        }
                                                        creator={asset.creator}
                                                        owner={asset.owner}
                                                        ownerName={ownerName}
                                                        ownerAvatar={
                                                            ownerAvatar
                                                        }
                                                        marketplaceAddress={
                                                            marketplaceAddress
                                                        }
                                                        fromDB={isFromDB}
                                                    />
                                                ) : (
                                                    isFromDB && (
                                                        <ItemAuthor
                                                            history={
                                                                asset.assetHistory
                                                            }
                                                            creator={
                                                                isFromDB
                                                                    ? {
                                                                          name: asset.ownerName,
                                                                          avatar: asset.ownerAvatar,
                                                                          account:
                                                                              asset.owner,
                                                                      }
                                                                    : {
                                                                          name: asset
                                                                              .creator
                                                                              .name,
                                                                          avatar: asset
                                                                              .creator
                                                                              .avatar,
                                                                          account:
                                                                              asset
                                                                                  .creator
                                                                                  .account,
                                                                      }
                                                            }
                                                            owner={asset.owner}
                                                            ownerName={
                                                                ownerName
                                                            }
                                                            ownerAvatar={
                                                                ownerAvatar
                                                            }
                                                            marketplaceAddress={
                                                                marketplaceAddress
                                                            }
                                                            fromDB={isFromDB}
                                                        />
                                                    )
                                                ))}

                                            <div className="gy-4 my-4">
                                                {price ? (
                                                    <>
                                                        <h6 className="mb-3">
                                                            {t('currPrice', {
                                                                ns: 'itemSingle',
                                                            })}
                                                        </h6>
                                                        <div className="text-sm text-muted fw-normal mb-0 d-flex align-items-center">
                                                            <span className="icon bg-primary text-white me-2 mb-1">
                                                                <i className="lab la-ethereum fa-fw"></i>
                                                            </span>
                                                            <p className="mb-0 h4 d-flex align-items-end fw-bold ms-2 text-dark">
                                                                {price}{' '}
                                                                {
                                                                    settings.currency
                                                                }
                                                            </p>
                                                        </div>
                                                    </>
                                                ) : isFromDB ? (
                                                    <div className="d-inline-block">
                                                        <p className="text-muted mb-0 d-flex align-items-center bg-gray-200 rounded py-2 px-3">
                                                            <i className="lab la-ethereum text-dark me-2"></i>
                                                            {t('notMinted', {
                                                                ns: 'itemSingle',
                                                            })}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <div className="d-inline-block">
                                                        <p className="text-muted mb-0 d-flex align-items-center bg-gray-200 rounded py-2 px-3">
                                                            <i className="lab la-ethereum text-dark me-2"></i>
                                                            {t('notSale', {
                                                                ns: 'itemSingle',
                                                            })}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>

                                            {index !== -1 ? (
                                                owner !==
                                                web3Ctx.account.toLowerCase() ? (
                                                    userCtx.userIsRegistered ? (
                                                        <button
                                                            type="button"
                                                            className="btn btn-gradient-primary px-5"
                                                            value={index}
                                                            onClick={(e) => {
                                                                buyHandler(
                                                                    e,
                                                                    priceBN
                                                                );
                                                            }}
                                                        >
                                                            <i className="lab la-ethereum me-2"></i>
                                                            {t('purchase', {
                                                                ns: 'itemSingle',
                                                            })}
                                                        </button>
                                                    ) : (
                                                        <>
                                                            {window.ethereum &&
                                                                networkId ===
                                                                    settings.networkId && (
                                                                    <Link
                                                                        to="/register"
                                                                        className="btn btn-primary"
                                                                    >
                                                                        <i className="las la-user me-2"></i>
                                                                        {t(
                                                                            'register',
                                                                            {
                                                                                ns: 'itemSingle',
                                                                            }
                                                                        )}
                                                                    </Link>
                                                                )}
                                                        </>
                                                    )
                                                ) : (
                                                    <button
                                                        type="button"
                                                        value={index}
                                                        className="btn btn-danger px-5"
                                                        onClick={cancelHandler}
                                                    >
                                                        {t('unlist', {
                                                            ns: 'itemSingle',
                                                        })}
                                                    </button>
                                                )
                                            ) : owner ===
                                              web3Ctx.account.toLowerCase() ? (
                                                isFromDB ? (
                                                    <>
                                                        <div className="col-xl-4">
                                                            <form
                                                                onSubmit={(e) =>
                                                                    submitMintHandler(
                                                                        e
                                                                    )
                                                                }
                                                            >
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary w-100 rounded-sm mb-3"
                                                                >
                                                                    {t('mint', {
                                                                        ns: 'itemSingle',
                                                                    })}
                                                                </button>
                                                            </form>
                                                        </div>
                                                        <div className="col-xl-4">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info w-100 rounded-sm mt-3"
                                                                onClick={() => {
                                                                    setIsDeleteConfirmModalOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                {t('delete', {
                                                                    ns: 'itemSingle',
                                                                })}
                                                            </button>
                                                        </div>
                                                        <Modal
                                                            status={
                                                                isDeleteConfirmModalOpen
                                                            }
                                                            variant=""
                                                            modalClose={
                                                                closeDeleteConfirmModalHandler
                                                            }
                                                            layout={{
                                                                width: '500px',
                                                                maxWidth:
                                                                    '100%',
                                                            }}
                                                        >
                                                            <div className="card-body text-center py-lg-5">
                                                                <h4 className="mb-1">
                                                                    {t('sure', {
                                                                        ns: 'itemSingle',
                                                                    })}
                                                                </h4>
                                                                <div className="d-flex flex-column">
                                                                    <form
                                                                        onSubmit={(
                                                                            e
                                                                        ) =>
                                                                            deleteNftFromDBHandler(
                                                                                e
                                                                            )
                                                                        }
                                                                    >
                                                                        <button
                                                                            className="btn btn-info m-1 w-50 mt-2"
                                                                            type="submit"
                                                                        >
                                                                            {t(
                                                                                'confirm',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                        </button>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </Modal>
                                                    </>
                                                ) : (
                                                    <div className="col-xl-8">
                                                        <button
                                                            className="btn btn-primary px-5"
                                                            type="button"
                                                            onClick={() => {
                                                                setIsModalOpen(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            {t('createSale', {
                                                                ns: 'itemSingle',
                                                            })}
                                                        </button>
                                                        <Modal
                                                            status={isModalOpen}
                                                            variant=""
                                                            modalClose={
                                                                closeModalHandler
                                                            }
                                                            layout={{
                                                                width: '500px',
                                                                maxWidth:
                                                                    '100%',
                                                            }}
                                                        >
                                                            <div className="card-body text-center p-lg-5">
                                                                <h4 className="mb-1">
                                                                    {t(
                                                                        'listSale',
                                                                        {
                                                                            ns: 'itemSingle',
                                                                        }
                                                                    )}
                                                                </h4>
                                                                {saleType ===
                                                                    'fixedPrice' && (
                                                                    <p className="text-muted mb-4">
                                                                        {t(
                                                                            'addPrice',
                                                                            {
                                                                                ns: 'itemSingle',
                                                                            }
                                                                        )}{' '}
                                                                        {
                                                                            settings.currency
                                                                        }
                                                                    </p>
                                                                )}
                                                                {saleType ===
                                                                    'auction' && (
                                                                    <p className="text-muted mb-4">
                                                                        {t(
                                                                            'addAucEnd',
                                                                            {
                                                                                ns: 'itemSingle',
                                                                            }
                                                                        )}
                                                                    </p>
                                                                )}

                                                                {saleType ===
                                                                    '' && (
                                                                    <div className="d-flex flex-column">
                                                                        <button
                                                                            className="btn btn-primary m-1 w-100"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setSaleType(
                                                                                    'fixedPrice'
                                                                                )
                                                                            }
                                                                        >
                                                                            {t(
                                                                                'fixedPrice',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                        </button>
                                                                        <button
                                                                            className="btn btn-info m-1 w-100"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setSaleType(
                                                                                    'auction'
                                                                                )
                                                                            }
                                                                        >
                                                                            {t(
                                                                                'openBid',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                {saleType ===
                                                                    'fixedPrice' && (
                                                                    <form
                                                                        onSubmit={(
                                                                            e
                                                                        ) =>
                                                                            makeOfferHandler(
                                                                                e,
                                                                                asset.id,
                                                                                key
                                                                            )
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="number"
                                                                            step="0.001"
                                                                            min="0.0000000000000000000000001"
                                                                            placeholder={
                                                                                t(
                                                                                    'openBid',
                                                                                    {
                                                                                        ns: 'itemSingle',
                                                                                    }
                                                                                ) +
                                                                                `${settings.currency}...`
                                                                            }
                                                                            className="form-control mb-2"
                                                                            ref={
                                                                                priceRef
                                                                            }
                                                                            required={
                                                                                true
                                                                            }
                                                                            autoFocus={
                                                                                true
                                                                            }
                                                                            value={
                                                                                offerPrice
                                                                            }
                                                                            onChange={(
                                                                                e
                                                                            ) =>
                                                                                setOfferPrice(
                                                                                    e
                                                                                        .target
                                                                                        .value
                                                                                )
                                                                            }
                                                                        />
                                                                        <button
                                                                            type="submit"
                                                                            className="btn btn-primary w-100 rounded-sm mb-3"
                                                                        >
                                                                            {t(
                                                                                'createSale',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                        </button>
                                                                        <p className="mb-0 text-center text-muted">
                                                                            {t(
                                                                                'youllGet',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                            <span className="text-primary fw-normal mx-1">
                                                                                {offerPrice
                                                                                    ? offerPrice -
                                                                                      (parseFloat(
                                                                                          offerPrice
                                                                                      ) *
                                                                                          settings.saleCommission) /
                                                                                          1000
                                                                                    : 0}
                                                                            </span>
                                                                            {
                                                                                settings.currency
                                                                            }{' '}
                                                                            {t(
                                                                                'after',
                                                                                {
                                                                                    ns: 'itemSingle',
                                                                                }
                                                                            )}
                                                                        </p>
                                                                    </form>
                                                                )}

                                                                {saleType ===
                                                                    'auction' && (
                                                                    <form
                                                                        onSubmit={(
                                                                            e
                                                                        ) =>
                                                                            makeAuctionHandler(
                                                                                e,
                                                                                endDate,
                                                                                id
                                                                            )
                                                                        }
                                                                    >
                                                                        <div className="row gy-3 text-start">
                                                                            <div className="col-12">
                                                                                <label className="form-label text-start fw-bold">
                                                                                    {t(
                                                                                        'aucEnd',
                                                                                        {
                                                                                            ns: 'itemSingle',
                                                                                        }
                                                                                    )}
                                                                                </label>
                                                                                <input
                                                                                    className="form-control"
                                                                                    type="date"
                                                                                    value={
                                                                                        endDate
                                                                                    }
                                                                                    autoFocus={
                                                                                        true
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        setEndDate(
                                                                                            e
                                                                                                .target
                                                                                                .value
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="col-12">
                                                                                <button
                                                                                    type="submit"
                                                                                    className="btn btn-primary w-100 rounded-sm mb-2"
                                                                                >
                                                                                    {t(
                                                                                        'createSale',
                                                                                        {
                                                                                            ns: 'itemSingle',
                                                                                        }
                                                                                    )}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                )}
                                                            </div>
                                                        </Modal>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </section>

            {similarItems && similarItems.length > 0 && (
                <section className="pb-5 bg-light">
                    <div className="container pb-5">
                        <header className="mb-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2
                                        data-aos="fade-right"
                                        data-aos-delay="100"
                                        data-aos-once="true"
                                    >
                                        {t('similar', {
                                            ns: 'itemSingle',
                                        })}
                                    </h2>
                                    <p
                                        className="text-muted lead mb-0"
                                        data-aos="fade-right"
                                        data-aos-delay="200"
                                        data-aos-once="true"
                                    >
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Repudiandae esse quis
                                        sed,necessitatibus nostrum mollitia.
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="row gy-5">
                            {similarItems.map((NFT, key) => {
                                const index = NFT.offerId ? NFT.offerId : -1;
                                const owner = NFT.owner;
                                const price =
                                    index !== -1
                                        ? formatPrice(NFT.price).toFixed(2)
                                        : null;

                                return (
                                    <div className="col-lg-4" key={key}>
                                        <NftItem
                                            {...NFT}
                                            index={index}
                                            owner={owner}
                                            price={price}
                                            nftKey={key}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default ItemSingle;
