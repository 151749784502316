import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { settings } from '../../helpers/settings';
import DataTable, { createTheme } from 'react-data-table-component';

createTheme(
    'solarized',
    {
        background: {
            default: '#1c1c22',
        },
    },
    'dark'
);

function UsersTable({ users, ownerFunds }) {
    const [usersList, setUsersList] = useState(null);

    /*** =============================================== */
    //      GET USERS LIST
    /*** =============================================== */
    useEffect(() => {
        if (users && ownerFunds) {
            setUsersList(
                users.map((user) => {
                    return {
                        account: user.account,
                        name: user.fullName,
                        avatar: user.avatar,
                        selling: ownerFunds.filter(
                            (el) => el.id === user.account
                        )[0]?.fund,
                    };
                })
            );
        }
    }, [users, ownerFunds]);

    /*** =============================================== */
    //      TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: 'User Info',
            selector: (row) => row.fullName,
            cell: (row) => (
                <div row={row}>
                    <Link className="text-reset" to={`/users/${row.account}`}>
                        <div className="d-flex align-items-center py-3">
                            <div className="author-avatar">
                                <span
                                    className="author-avatar-inner"
                                    style={{ background: `url(${row.avatar})` }}
                                ></span>
                            </div>
                            <div className="ms-3">
                                <p className="fw-bold text-base mb-0">
                                    {row.name}
                                </p>
                                <p className="text-muted text-xxs mb-0">
                                    {row.email}
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: 'Profits',
            selector: (row) => row.selling,
            cell: (row) => (
                <div row={row}>
                    <div className="d-flex align-items-center">
                        <p className="mb-0 fw-bold">
                            {row.selling > 0
                                ? (row.selling / 10 ** 18).toFixed(2)
                                : 0}{' '}
                            {settings.currency}
                        </p>
                    </div>
                </div>
            ),
        },
    ];

    return (
        usersList && (
            <DataTable
                columns={columns}
                data={usersList}
                pagination={usersList.length >= 10 && true}
                responsive
                theme="solarized"
            />
        )
    );
}

export default UsersTable;
