import Web3 from 'web3';
import { GraphqlClient } from './graphqlClient';
import { authenticate } from './utils';

const connectWalletHandler = async (web3Ctx, userCtx) => {
    try {
        // Request account access
        await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
        console.error(error);
    }
    // Load accounts
    const account = await web3Ctx.loadAccount(new Web3(window.ethereum));
    const getUserRes = await GraphqlClient.getUser(account);
    if (getUserRes === '404') {
        // if user doesn't exist, create it at first
        const createUserRes = await GraphqlClient.createUser(account);
        await authenticate(createUserRes.lastNonce, account);
    }
    await userCtx.getUsersListFromDB();
};

export { connectWalletHandler };
