import web3 from '../connect-web3/web3';
import { GraphqlClient } from './graphqlClient';

export const DECIMALS = 10 ** 18;

export const ether = (wei) => wei / DECIMALS;

export const formatPrice = (price) => {
    const precision = 100; // Use 2 decimal places

    price = ether(price);
    price = Math.round(price * precision) / precision;

    return price;
};

export function navbarChangeStyle() {
    const navbar = document.getElementById('navbar');
    window.addEventListener('scroll', function () {
        let windowScroll = document.scrollingElement.scrollTop;
        if (windowScroll >= 10) {
            navbar.classList.add('navbar-active');
        } else {
            navbar.classList.remove('navbar-active');
        }
    });
}

export function authCloseNavbar() {
    const navLinks = document.querySelectorAll('.navbar-nav .nav-link');
    const navbarToggler = document.querySelector('.navbar-toggler');
    const navbarCollapse = document.querySelector('.navbar-collapse');

    navLinks.forEach((link) => {
        link.addEventListener('click', function () {
            navbarCollapse.classList.remove('show');
            navbarToggler.setAttribute('aria-expanded', false);
        });
    });
}

export function formatDate(itemDate) {
    let date1 = new Date();
    let date2 = new Date(itemDate);
    // To calculate the time difference of two dates
    let Difference_In_Time = date1.getTime() - date2.getTime();
    // To calculate the no. of days between two dates
    let Difference_In_Days = Math.floor(
        Difference_In_Time / (1000 * 3600 * 24)
    );
    let Difference_In_Hours = Math.floor(Difference_In_Time / (1000 * 3600));

    if (Difference_In_Days < 1) {
        return { count: `${Difference_In_Hours} `, unit: 'hour' };
    } else {
        return { count: `${Difference_In_Days} `, unit: 'day' };
    }
}

export function formatFullDate(date, lang) {
    const month = new Date(date).getMonth();
    const formatMonth = month < 10 ? '0' + month : '' + month;
    const day = new Date(date).getDate() + 1;
    const formatDate = day < 10 ? '0' + day : '' + day;
    const year = new Date(date).getFullYear();
    switch (lang) {
        case 'en':
            return `${formatMonth}/${formatDate}/${year} 
                ${new Date(date).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                })}`;
        case 'cn':
            return `${year}-${formatMonth}-${formatDate}
            ${new Date(date).toLocaleTimeString('zh-CN', {
                hour: '2-digit',
                minute: '2-digit',
            })}`;
        default:
            return `${formatMonth}/${formatDate}/${year} 
                ${new Date(date).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                })}`;
    }
}

export function formatCategory(category) {
    if (category === 'art') {
        category = 'Art';
    } else if (category === 'trendingCards') {
        category = 'Trading Cards';
    } else if (category === 'game') {
        category = 'Game';
    } else if (category === 'memes') {
        category = 'Memes';
    } else if (category === 'collectibles') {
        category = 'Collectibles';
    } else if (category === 'music') {
        category = 'Music';
    } else {
        category = 'General';
    }
    return category;
}

export function truncate(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;
    separator = separator || '...';
    let sepLen = separator.length,
        charsToShow = strLen - sepLen,
        frontChars = Math.ceil(charsToShow / 2),
        backChars = Math.floor(charsToShow / 2);

    return (
        fullStr.substr(0, frontChars) +
        separator +
        fullStr.substr(fullStr.length - backChars)
    );
}

export function truncateStart(fullStr, strLen, separator) {
    if (fullStr.length <= strLen) return fullStr;
    separator = separator || '...';
    let charsToShow = strLen,
        frontChars = Math.ceil(charsToShow);

    return fullStr.substr(0, frontChars) + separator;
}

export function configEtherScanUrl(network, account) {
    let blockExplorerUrl;
    if (network === 3) {
        blockExplorerUrl = 'https://ropsten.etherscan.io';
    } else if (network === 4) {
        blockExplorerUrl = 'https://rinkeby.etherscan.io';
    } else if (network === 42) {
        blockExplorerUrl = 'https://kovan.etherscan.io';
    } else if (network === 5) {
        blockExplorerUrl = 'https://goerli.etherscan.io';
    } else if (network === 56) {
        blockExplorerUrl = 'https://bscscan.com';
    } else if (network === 137) {
        blockExplorerUrl = 'https://polygonscan.com';
    } else if (network === 97) {
        blockExplorerUrl = 'https://testnet.bscscan.com';
    } else if (network === 80001) {
        blockExplorerUrl = 'https://mumbai.polygonscan.com';
    } else {
        blockExplorerUrl = 'https://etherscan.io';
    }

    return `${blockExplorerUrl}/address/${account}`;
}

export function arrayMatch(arr1, arr2) {
    var arr = [];
    arr1 = arr1.toString().split(',').map(Number);
    arr2 = arr2.toString().split(',').map(Number);
    // for array1
    for (var i in arr1) {
        if (arr2.indexOf(arr1[i]) !== -1) arr.push(arr1[i]);
    }

    return arr.sort((x, y) => x - y);
}

export function generateRandomImage(max) {
    const val = Math.floor(Math.random() * max) + 1;
    if (val === 0) {
        return 'https://i.postimg.cc/KzFk8y0R/avatar-1.png';
    } else if (val === 1) {
        return 'https://i.postimg.cc/KzFk8y0R/avatar-1.png';
    } else if (val === 2) {
        return 'https://i.postimg.cc/x81XL3DQ/avatar-2.png';
    } else if (val === 3) {
        return 'https://i.postimg.cc/j23Lcnt7/avatar-3.png';
    } else if (val === 4) {
        return 'https://i.postimg.cc/MK4njBsk/avatar-4.png';
    } else if (val === 5) {
        return 'https://i.postimg.cc/Y9p0wzSF/avatar-5.png';
    } else if (val === 6) {
        return 'https://i.postimg.cc/rpGsbPqc/avatar-6.png';
    } else if (val === 7) {
        return 'https://i.postimg.cc/ZRVRYv9M/avatar-7.png';
    }
}

export const cookieStorage = {
    getItem: (item) => {
        const cookies = document.cookie
            .split(';')
            .map((cookie) => cookie.split('='))
            .reduce(
                (acc, [key, value]) => ({ ...acc, [key.trim()]: value }),
                {}
            );
        return cookies[item];
    },
    setItem: (key, value) => {
        document.cookie = `${key}=${value}`;
    },
};
export const getTokenFromStorage = () => {
    const cookieConsent = cookieStorage.getItem('user_consent');
    const token = cookieConsent
        ? cookieStorage.getItem('token')
        : localStorage.getItem('token');
    return token;
};
export const authenticate = async (nonce, account) => {
    const msg =
        'Welcome to Go Ocean!\n\n' +
        'Click to sign in and accept the Go Ocean Terms of Service: https://goocean.io/terms-services\n\n' +
        'This request will not trigger a blockchain transaction or cost any gas fees.\n\n' +
        'Your authentication status will reset after 24 hours.\n\n' +
        'Wallet address:\n' +
        account +
        '\n\nNonce:\n' +
        nonce;

    // Sign with nonce
    const signature = await web3.eth.personal.sign(msg, account, '');

    const { access_token } = await GraphqlClient.authenticate(
        account,
        signature
    );

    const cookieConsent = cookieStorage.getItem('user_consent');
    if (cookieConsent) {
        cookieStorage.setItem('token', access_token);
    } else {
        localStorage.setItem('token', access_token);
    }
};
