import React from 'react';
import ModelViewer from '../ModelViewer/ModelViewer';
import FsLightbox from 'fslightbox-react';
import { styled } from '@mui/material/styles';

function ItemLightbox({ file, type, toggler, enlarge }) {
    const ImgWrapper = styled('img')`
        object-fit: contain;
        }
    `;
    const renderItem = () => {
        switch (type) {
            case 'image':
                return enlarge ? (
                    <div style={{ width: '1000px' }}>
                        <ImgWrapper
                            className="d-flex align-items-center"
                            style={{ width: '100%', height: 'auto' }}
                            src={file}
                        />
                    </div>
                ) : (
                    <ImgWrapper src={file} />
                );
            case '3d':
                return (
                    <div style={{ width: '9000px', height: '5000px' }}>
                        <ModelViewer
                            modelUrl={file}
                            cameraPosition={[0, 0, 60]}
                            style={{ width: '100%', height: 'auto' }}
                        ></ModelViewer>
                    </div>
                );
            default:
                return null;
        }
    };

    return <FsLightbox toggler={toggler} sources={[renderItem()]} />;
}

export default ItemLightbox;
