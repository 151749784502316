import React, { useContext, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '../../components/Icons/FacebookIcon';
import InstagramIcon from '../../components/Icons/InstagramIcon';
import TwitterIcon from '../../components/Icons/TwitterIcon';
import DiscordIcon from '../../components/Icons/DiscordIcon';
import LanguageIcon from '@mui/icons-material/Language';
import Divider from '@mui/material/Divider';
import ShareIcon from '@mui/icons-material/Share';
import Typography from '@mui/material/Typography';
import { styled as muiStyled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Web3Context from '../../providers/web3-context';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

const LinkGroup = ({ userDetails, address }) => {
    const { t } = useTranslation(['userProfile']);
    const history = useHistory();
    const web3Ctx = useContext(Web3Context);
    const [linkGroup, setLinkGroup] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    useEffect(() => {
        const links = {
            facebook: {
                link: userDetails?.facebook,
                tooltip: 'Facebook',
                icon: <FacebookIcon />,
                ariaLabel: 'Facebook',
            },
            instagram: {
                link: userDetails?.instagram,
                tooltip: 'Instagram',
                icon: <InstagramIcon />,
                ariaLabel: 'Instagram',
            },
            twitter: {
                link: userDetails?.twitter,
                tooltip: 'Twitter',
                icon: <TwitterIcon />,
                ariaLabel: 'Twitter',
            },
            discord: {
                link: userDetails?.discord,
                tooltip: 'Discord',
                icon: <DiscordIcon />,
                ariaLabel: 'Discord',
            },
            web: {
                link: userDetails?.web,
                tooltip: 'Web',
                icon: <LanguageIcon />,
                ariaLabel: 'Web',
            },
        };

        if (userDetails) {
            const { facebook, instagram, twitter, discord, web } = userDetails;

            if (facebook) {
                links.facebook.link = facebook;
            }

            if (facebook) {
                links.instagram.link = instagram;
            }

            if (facebook) {
                links.twitter.link = twitter;
            }

            if (facebook) {
                links.discord.link = discord;
            }

            if (facebook) {
                links.web.link = web;
            }

            setLinkGroup(links);
        }
    }, [userDetails]);

    const EditProfileButton = muiStyled(Button)(({ theme }) => ({
        color: 'rgb(21,21,21)',
        backgroundColor: 'rgb(242,242,242)',
        boxShadow: 'none',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: 'rgb(214,214,214)',
        },
    }));

    const renderLinks = () => {
        return Object.keys(linkGroup).map((key) => {
            const { link, tooltip, icon, ariaLabel } = linkGroup[key];
            if (!link) {
                return null;
            }
            return (
                <Tooltip title={tooltip} key={key}>
                    <IconButton
                        aria-label={ariaLabel}
                        size="large"
                        onClick={() => {
                            window.open(link, '_blank');
                        }}
                    >
                        {icon}
                    </IconButton>
                </Tooltip>
            );
        });
    };

    return (
        <>
            {renderLinks()}
            <Divider
                orientation="vertical"
                variant={'middle'}
                flexItem
                style={{
                    alignSelf: 'stretch',
                    height: 'auto',
                    color: 'rgb(215,215,215)',
                }}
            />

            <Tooltip title={t('tooltip.share')}>
                <IconButton
                    aria-label={t('tooltip.share')}
                    size="large"
                    color={'secondary'}
                    onClick={() => {
                        navigator.clipboard
                            .writeText(
                                window.location.href.split('/')[0] +
                                    '/' +
                                    window.location.href.split('/')[1] +
                                    '/' +
                                    window.location.href.split('/')[2] +
                                    '/' +
                                    window.location.href.split('/')[3] +
                                    '/' +
                                    window.location.href.split('/')[4]
                            )
                            .then(() => {
                                enqueueSnackbar(t('copyWebAddressSuccess'), {
                                    variant: 'success',
                                });
                            });
                    }}
                >
                    <ShareIcon />
                </IconButton>
            </Tooltip>
            {address === web3Ctx.account.toLowerCase() && (
                <EditProfileButton
                    variant="contained"
                    onClick={() => {
                        history.push(`/users/${address}/setting?tab=profile`);
                    }}
                >
                    <Typography variant={'subtitle2'}>
                        {t('editProfile')}
                    </Typography>
                </EditProfileButton>
            )}
        </>
    );
};

export default LinkGroup;
