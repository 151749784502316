import React from 'react';
import { formatPrice } from '../../helpers/utils';
import NftItem from '../../components/general/NftItem';
import AuctionItem from '../../components/general/AuctionItem';
import { useTranslation } from 'react-i18next';

const CollectedNftPanel = ({ address, collectedTokens, userDetails }) => {
    const { t } = useTranslation(['nftPanel']);
    if (collectedTokens && collectedTokens.length > 0) {
        return (
            <>
                <div className="row gy-4 mb-5 align-items-stretch">
                    {collectedTokens.map((NFT, key) => {
                        if (NFT.auctionId) {
                            return (
                                <div className="col-xl-4 col-md-6" key={key}>
                                    <AuctionItem {...NFT} nftKey={key} />
                                </div>
                            );
                        } else {
                            const index = NFT.offerId ? NFT.offerId : -1;
                            const owner = NFT.owner;
                            const price =
                                index !== -1
                                    ? formatPrice(NFT.price).toFixed(2)
                                    : null;

                            return (
                                <div
                                    className={`col-xl-4 col-md-6 ${NFT.category}`}
                                    key={NFT.id}
                                >
                                    <NftItem
                                        {...NFT}
                                        noAnimation={true}
                                        index={index}
                                        owner={owner}
                                        price={price}
                                        nftKey={key}
                                        offerPriceBN={NFT.price}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </>
        );
    }

    return (
        <>
            <div className="row gy-4 mb-5 align-items-stretch">
                <h4>
                    {userDetails.fullName} {t('noOwn', { ns: 'nftPanel' })}
                </h4>
            </div>
        </>
    );
};

export default CollectedNftPanel;
