import React, { useEffect, useState } from 'react';
import { settings } from '../helpers/settings';

// COMPONENTS
import PgaeBanner from '../components/general/PageBanner';

const techQuestions = [
    {
        question: 'What blockchains are supported?',
        answer: (
            <p className="text-muted mb-0">
                Our products support these blockchains:
                <ul className="mb-0">
                    <li>Ethereum</li>
                    <li>Polyogn</li>
                    <li>Binance Smart Chain</li>
                    <li>Celo</li>
                    <li>Harmony</li>
                </ul>
            </p>
        ),
    },
    {
        question: 'What wallets are supported?',
        answer: 'At the moment, you can use MetaMask wallet.',
    },
    {
        question: 'More questions?',
        answer: (
            <p className="text-muted mb-0">
                If you can’t find the answers here,{' '}
                <a
                    rel="noopener noreferrer"
                    href="client/src/pages/FAQ"
                    className="text-primary fw-bold"
                    target="_blank"
                >
                    get in touch
                </a>
                . We will be happy to help.
            </p>
        ),
    },
];

function FAQ() {
    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `FAQ | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    const [tab, setTab] = useState('tech-questions');

    return (
        <>
            <PgaeBanner heading="Frequently Asked Questions" />

            <section className="py-5">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-8 order-2 order-lg-1">
                            {tab === 'tech-questions' && (
                                <>
                                    <h2 className="mb-4">
                                        Technical Questions
                                    </h2>
                                    <div
                                        className="accordion"
                                        id="accordionExample"
                                    >
                                        {techQuestions.map((el, index) => {
                                            return (
                                                <div
                                                    className="accordion-item mb-3"
                                                    key={index}
                                                >
                                                    <h2
                                                        className="accordion-header"
                                                        id={`heading${
                                                            index + 1
                                                        }`}
                                                    >
                                                        <button
                                                            className={`accordion-button shadow-0 fw-bold ${
                                                                index === 0
                                                                    ? ''
                                                                    : 'collapsed'
                                                            }`}
                                                            type="button"
                                                            data-bs-toggle="collapse"
                                                            data-bs-target={`#collapse${
                                                                index + 1
                                                            }`}
                                                            aria-expanded={
                                                                index === 0
                                                                    ? true
                                                                    : false
                                                            }
                                                            aria-controls={`collapse${
                                                                index + 1
                                                            }`}
                                                        >
                                                            {el.question}
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id={`collapse${
                                                            index + 1
                                                        }`}
                                                        className={`accordion-collapse collapse ${
                                                            index === 0
                                                                ? 'show'
                                                                : ''
                                                        }`}
                                                        aria-labelledby={`heading${
                                                            index + 1
                                                        }`}
                                                        data-bs-parent="#accordionExample"
                                                    >
                                                        <div className="accordion-body">
                                                            {el.answer}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="col-lg-4 order-1 order-lg-2">
                            <h2 className="mb-4">Quick Nav</h2>

                            <div className="card bg-light shadow-0">
                                <div className="card-body p-4 p-lg-5">
                                    <ul className="p-0 m-0 list-unstyled">
                                        <li className="mb-3">
                                            <button
                                                className={`btn btn-link p-0 fw-bold text-decoration-none shadow-0 ${
                                                    tab === 'tech-questions'
                                                        ? 'text-primary'
                                                        : 'text-dark'
                                                }`}
                                                onClick={() =>
                                                    setTab('tech-questions')
                                                }
                                            >
                                                Technical Questions
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FAQ;
