import React from 'react';
import { useTranslation } from 'react-i18next';
// COMPONENTS
import PageBanner from '../components/general/PageBanner';
import ActivityTable from '../components/tables/ActivityTable';
import TransactionsTable from '../components/tables/TransactionsTable';

function Activity() {
    const { t } = useTranslation(['activity']);
    return (
        <>
            <PageBanner heading={t('actAndTrans', { ns: 'activity' })} />
            <section className="py-5">
                <div
                    className="container py-5"
                    data-aos="fade-up"
                    data-aos-delay="100"
                >
                    {/* ACTIVITES */}
                    <div className="mb-5">
                        <header className="mb-4">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h2 className="mb-2">
                                        {t('activities', { ns: 'activity' })}
                                    </h2>
                                    <p className="text-muted lead">
                                        {t('findOut', { ns: 'activity' })}
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="card shadow-lg">
                            <div className="card-body">
                                <ActivityTable />
                            </div>
                        </div>
                    </div>

                    {/* TRANSACTIONS */}
                    <header className="mb-4">
                        <div className="row">
                            <div className="col-lg-7">
                                <h2 className="mb-2">
                                    {t('transaction', { ns: 'activity' })}
                                </h2>
                                <p className="text-muted lead">
                                    {t('latestTrans', { ns: 'activity' })}
                                </p>
                            </div>
                        </div>
                    </header>

                    <div className="card shadow-lg">
                        <div className="card-body">
                            <TransactionsTable />
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Activity;
