import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NftFullScreenSkeleton = () => {
    return (
        <Stack spacing={1} direction={'column'} width={'100%'}>
            <Stack direction={'column'} alignItems={'center'}>
                <Stack
                    direction={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    width={'15%'}
                >
                    <Skeleton variant="circular" width={40} height={40} />
                    <Typography variant={'h4'} width={'60%'}>
                        <Skeleton variant="text" />
                    </Typography>
                </Stack>
                <Typography variant={'h1'} width={'20%'}>
                    <Skeleton variant="text" />
                </Typography>
            </Stack>
            <br></br>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'83%'}
            >
                <Skeleton variant="rectangular" width={'58%'} height={290} />
                <Stack direction={'column'} width={'38%'}>
                    <Typography variant={'h3'} width={'18%'}>
                        <Skeleton variant="text" />
                    </Typography>
                    <Typography variant={'h4'} width={'100%'}>
                        <Skeleton variant="text" />
                    </Typography>
                    <Skeleton
                        variant="rectangular"
                        width={'100%'}
                        height={200}
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};

export default NftFullScreenSkeleton;
