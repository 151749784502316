import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import CollectionContext from '../providers/collection-context';
import MarketplaceContext from '../providers/marketplace-context';
import AuctionContext from '../providers/auction-context';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { formatPrice } from '../helpers/utils';
import { categoryOptionsEn } from '../helpers/constants';
import { settings } from '../helpers/settings';
import { useTranslation } from 'react-i18next';
// COMPONENTS
import HomeBanner from '../components/home/HomeBanner';
import NftItem from '../components/general/NftItem';
import AuctionItem from '../components/general/AuctionItem';
import TopSellers from '../components/home/TopSellers';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import { GraphqlClient } from '../helpers/graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

SwiperCore.use([Navigation]);

function Home({ topSellers }) {
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);
    const { t } = useTranslation(['home']);

    const [featureItems, setFeatureItems] = useState([]);
    const [exploreItems, setExploreItems] = useState([]);
    const [auctionItems, setAuctionItems] = useState([]);
    const [finalTopSellers, setFinalTopSellers] = useState(null);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = settings.UISettings.marketplaceBrandName;
    }, []);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    useEffect(() => {
        AOS.init({ duration: 1000, once: true });
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const featureTokens = await getTokens(
                { skip: 0, first: settings.UISettings.featuredItems.length },
                ', $id: [Int]',
                ', where: {id_in: $id, onAuction: false}',
                { id: settings.UISettings.featuredItems }
            );

            const exploreTokens = await getTokens(
                { skip: 0, first: 8 },
                '',
                ', where: {onAuction: false}'
            );

            const auctionTokens = await getTokens(
                { skip: 0, first: 8 },
                '',
                ', where: {onAuction: true}'
            );

            // Extract the relative owners
            const {
                updatedTokens: updatedFeatureTokens,
                owners: featureTokenOwners,
            } = updateOwnersAndPrice(featureTokens);

            const {
                updatedTokens: updatedExploreTokens,
                owners: exploreTokenOwners,
            } = updateOwnersAndPrice(exploreTokens);
            const {
                updatedTokens: updatedAuctionTokens,
                owners: auctionTokenOwners,
            } = updateOwnersAndPrice(auctionTokens);
            const topSellerUsers = topSellers?.map((seller) => seller.address);

            // Get the owner information
            const users = await GraphqlClient.getUsersByAccounts([
                ...new Set([
                    ...featureTokenOwners,
                    ...exploreTokenOwners,
                    ...auctionTokenOwners,
                    ...topSellerUsers,
                ]),
            ]);

            // Construct the data
            const withPriceMetaDataFeatureTokens = await Promise.all(
                constructTokens(updatedFeatureTokens, users)
            );

            setFeatureItems(withPriceMetaDataFeatureTokens);

            const withPriceMetaDataExploreTokens = await Promise.all(
                constructTokens(updatedExploreTokens, users)
            );

            setExploreItems(withPriceMetaDataExploreTokens);

            const withPriceMetaDataAuctionTokens = await Promise.all(
                constructTokens(updatedAuctionTokens, users)
            );

            setAuctionItems(withPriceMetaDataAuctionTokens);

            const constructedTopSellers = constructTopSellers(users);
            setFinalTopSellers(constructedTopSellers);
        };
        if (topSellers.length > 0) {
            loadData();
        }
    }, [topSellers]);

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            if (token.onAuction) {
                const validAuction = token.auctions.filter((auction) => {
                    return auction.active && !auction.cancel;
                })[0];

                const bids = validAuction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                let updatedToken = JSON.parse(JSON.stringify(token));
                updatedToken.auctionId = validAuction.id;
                updatedToken.endTime = parseInt(validAuction.endTime);
                updatedToken.cancelled = validAuction.cancel;
                updatedToken.active = validAuction.active;
                updatedToken.realOwner = validAuction.creator.id;
                updatedToken.bids = bids;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            } else {
                const validOffer = token.offers.filter((offer) => {
                    return !offer.fulfilled && !offer.cancelled;
                })[0];

                let updatedToken = JSON.parse(JSON.stringify(token));

                updatedToken.realOwner = validOffer
                    ? validOffer.creator.id
                    : token.owner.id;
                updatedToken.price = validOffer ? validOffer.price : 0;
                updatedToken.offerId = validOffer ? validOffer.id : null;
                owners.add(updatedToken.realOwner);
                return updatedToken;
            }
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const getTokens = async (
        paging,
        parameters = '',
        conditions = '',
        args = {}
    ) => {
        const GET_TOKENS = gql`
            query tokens($skip: Int, $first: Int${parameters}){
              erc721Tokens(skip: $skip, first: $first${conditions}) {
                    id
                    owner {
                        id
                    }
                    uri
                    offers {
                        id
                        creator {
                            id
                        }
                        fulfilled
                        cancelled
                        price
                    }
                    creator {
                        id
                    }
                    auctions {
                        id
                        creator {
                            id
                        }
                        active
                        cancel
                        endTime
                        bids {
                            bidder {
                                id
                            }
                            withdraw
                            amount
                            time
                        }
                    }
                    createdTimestamp
                    onAuction
                    category
                    onSale
                    onSalePrice
              }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_TOKENS,
            'erc721Tokens',
            paging,
            args
        );
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];

            const creator = users.filter(
                (el) => el.account === token.creator.id
            )[0];

            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                if (token.onAuction) {
                    extendedToken = {
                        tokenId: token.id,
                        auctionId: token.auctionId,
                        title: metadata.properties.name.description,
                        img: metadata.properties.file.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        unlockable: metadata.properties.unlockable.description,
                        endAt: token.endTime,
                        bids: token.bids,
                        owner: token.owner.id,
                        cancelled: token.cancel,
                        active: token.active,
                        user: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        creatorAccount: creator.id,
                        creatorName: creator.fullName,
                        creatorAvatar: creator.avatar,
                    };
                } else {
                    extendedToken = {
                        id: token.id,
                        title: metadata.properties.name.description,
                        file:
                            metadata.properties?.file?.description ||
                            metadata.properties?.image?.description,
                        thumbnail:
                            metadata.properties?.thumbnail?.description ||
                            metadata.properties?.preview?.description ||
                            metadata.properties?.image?.description,
                        description:
                            metadata.properties.description.description,
                        category: metadata.properties.category.description,
                        dateCreated:
                            metadata.properties.dateCreated.description,
                        royalties: metadata.properties.royalties.description,
                        unlockable: metadata.properties.unlockable.description,
                        type: metadata.properties.type.description,
                        formate: metadata.properties.formate.description,
                        owner: token.realOwner,
                        ownerName: user.fullName,
                        ownerAvatar: user.avatar,
                        price: token.price,
                        offerId: token.offerId,
                        creatorAccount: creator.id,
                        creatorName: creator.fullName,
                        creatorAvatar: creator.avatar,
                    };
                }

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked');
            }
        });
        return result;
    };

    const constructTopSellers = (users) => {
        return topSellers?.map((seller) => {
            const user = users.filter((el) => el.account === seller.address)[0];
            return {
                ...seller,
                avatar: user.avatar,
                fullName: user.fullName,
            };
        });
    };

    return (
        <>
            <HomeBanner dailyNFT={featureItems[0]} />
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}

            {/* MARKETPLACE FEATURED ITEMS */}
            <section className="pb-sm-5 pb-2">
                <div className="container pb-sm-5 pb-0">
                    <Box
                        component={'h4'}
                        className="mb-4"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        <div className="row">
                            <div className="col-lg-6 mx-auto text-center">
                                <h2 data-aos="fade-up" data-aos-delay="100">
                                    {t('featured', { ns: 'home' })}
                                </h2>
                                <p
                                    className="text-muted lead mb-0"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    {t('share', { ns: 'home' })}
                                </p>
                            </div>
                        </div>
                    </Box>
                    <Box
                        sx={{ display: { xs: 'block', sm: 'none' } }}
                        className="px-2"
                    >
                        <Typography variant={'subtitle2'}>
                            Featured this Month
                        </Typography>
                    </Box>

                    <Swiper
                        spaceBetween={0}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            900: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 3 },
                        }}
                        navigation={collectionCtx.collection.length !== 0}
                        modules={[Navigation, Pagination]}
                        pagination={{
                            clickable: true,
                        }}
                    >
                        {featureItems.length === 0
                            ? Array.from(Array(3)).map((_, key) => (
                                  <SwiperSlide
                                      className="pt-4 pb-5 px-sm-3"
                                      key={'featured-placeholder' + key}
                                  >
                                      <NftItem isLoading={true} />
                                  </SwiperSlide>
                              ))
                            : featureItems.map((NFT, key) => {
                                  const index = NFT.offerId ? NFT.offerId : -1;
                                  const owner = NFT.owner;
                                  const price =
                                      index !== -1
                                          ? formatPrice(NFT.price).toFixed(2)
                                          : null;

                                  return (
                                      <SwiperSlide
                                          key={key}
                                          className="pt-4 pb-5 px-sm-3"
                                      >
                                          <NftItem
                                              {...NFT}
                                              index={index}
                                              owner={owner}
                                              price={price}
                                              nftKey={key}
                                              offerPriceBN={NFT.price}
                                          />
                                      </SwiperSlide>
                                  );
                              })}
                    </Swiper>
                </div>
            </section>

            {/* BROWSE BY CATEGORY */}
            <section className="py-5 bg-light">
                <div className="container py-4">
                    <header className="mb-5">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 data-aos="fade-up" data-aos-delay="100">
                                    {t('catogories', { ns: 'home' })}
                                </h2>
                                <p
                                    className="text-muted lead mb-0"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    {t('browse', { ns: 'home' })}
                                </p>
                            </div>
                        </div>
                    </header>

                    <div className="row gy-4">
                        {categoryOptionsEn.map((el, i) => {
                            return (
                                <div
                                    className="col-xl-2 col-lg-4 col-6"
                                    key={i}
                                    data-aos="fade-left"
                                    data-aos-delay={(i + 1) * 100}
                                >
                                    <div className="card card-hover-minimal border-0 rounded-xl htw-card">
                                        <div className="card-body text-center py-4">
                                            <i
                                                className={`text-primary mb-2 ${el.icon}`}
                                                style={{ fontSize: '2rem' }}
                                            ></i>
                                            <Link
                                                className="text-reset stretched-link"
                                                to={`/categories/${el.value}`}
                                            >
                                                <h6 className="fw-normal">
                                                    {t(el.label, {
                                                        ns: 'home',
                                                    })}
                                                </h6>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </section>

            {/* NFT ITEMS */}
            <section className="py-5 position-relative">
                <div className="container pt-5">
                    <header className="mb-4">
                        <div className="row">
                            <div className="col-lg-6 mx-auto text-center">
                                <h2 data-aos="fade-up" data-aos-delay="100">
                                    {t('latestNft', { ns: 'home' })}
                                </h2>
                                <p
                                    className="text-muted lead mb-0"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    {t('treat', { ns: 'home' })}
                                </p>
                            </div>
                        </div>
                    </header>

                    <Swiper
                        spaceBetween={0}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            900: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 3 },
                        }}
                        navigation={Boolean(exploreItems.length !== 0)}
                    >
                        {exploreItems.length === 0
                            ? Array.from(Array(3)).map((_, key) => (
                                  <SwiperSlide
                                      className="pt-4 pb-5 px-sm-3"
                                      key={'explore-item-placeholder' + key}
                                  >
                                      <NftItem isLoading={true} />
                                  </SwiperSlide>
                              ))
                            : exploreItems.map((NFT, key) => {
                                  const index = NFT.offerId ? NFT.offerId : -1;
                                  const owner = NFT.owner;
                                  const price =
                                      index !== -1
                                          ? formatPrice(NFT.price).toFixed(2)
                                          : null;

                                  return (
                                      <SwiperSlide
                                          key={key}
                                          className="pt-4 pb-5 px-3"
                                      >
                                          <NftItem
                                              {...NFT}
                                              index={index}
                                              owner={owner}
                                              price={price}
                                              nftKey={key}
                                              offerPriceBN={NFT.price}
                                          />
                                      </SwiperSlide>
                                  );
                              })}
                    </Swiper>
                </div>
                {marketplaceCtx.themeMode === 'dark' && (
                    <div className="glow"></div>
                )}
            </section>

            <TopSellers
                isLoading={finalTopSellers === null}
                topSellers={finalTopSellers}
                title={t('topSeller', { ns: 'home' })}
                description={t('bestselling', { ns: 'home' })}
            />

            <section className="py-5 bg-light">
                <div className="container py-5">
                    <div className="row gy-5 align-items-center">
                        <div className="col-lg-6 col-12">
                            <h2 data-aos="fade-right" data-aos-delay="100">
                                {t('how', { ns: 'home' })}
                            </h2>
                            <p
                                className="text-muted lead mb-4"
                                data-aos="fade-up"
                                data-aos-delay="200"
                            >
                                {t('feelFree', { ns: 'home' })}
                            </p>

                            <div
                                className="d-flex mb-4"
                                data-aos="fade-right"
                                data-aos-delay="150"
                            >
                                <div className="icon-animated rounded-xl bg-primary mx-auto flex-shrink-0 d-flex-left">
                                    <i className="las la-wallet text-white"></i>
                                </div>
                                <div className="ms-3 d-flex-left ">
                                    <h5>{t('connect', { ns: 'home' })}</h5>
                                    <p className="text-muted text-sm mb-0">
                                        {t('metaMask', { ns: 'home' })}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="mb-4 d-flex"
                                data-aos="fade-right"
                                data-aos-delay="200"
                            >
                                <div className="icon-animated mx-auto rounded-xl bg-primary flex-shrink-0 d-flex-left">
                                    <i className="las la-rocket text-white"></i>
                                </div>
                                <div className="ms-3 d-flex-left">
                                    <h5>{t('nftOperation', { ns: 'home' })}</h5>
                                    <p className="text-muted text-sm mb-2">
                                        {t('nftRepresentation', { ns: 'home' })}
                                    </p>
                                </div>
                            </div>
                            <div
                                className="mb-0 d-flex"
                                data-aos="fade-right"
                                data-aos-delay="250"
                            >
                                <div className="icon-animated mx-auto rounded-xl bg-primary flex-shrink-0 d-flex-left">
                                    <i className="lab la-ethereum text-white"></i>
                                </div>
                                <div className="ms-3 d-flex-left">
                                    <h5>{t('purchase', { ns: 'home' })}</h5>
                                    <p className="text-muted text-sm mb-0">
                                        {t('whenPurchase', { ns: 'home' })}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Box
                            className="col-lg-5 col-12 ms-auto"
                            data-aos="fade-left"
                            data-aos-delay="100"
                            component={'div'}
                            sx={{ display: { xs: 'none' } }}
                        >
                            <img
                                src="/images/ils.svg"
                                alt="Illustration"
                                className="img-fluid w-100"
                            />
                        </Box>
                    </div>
                </div>
            </section>

            {/* NFT ITEMS */}
            <section className="py-5">
                <div className="container py-5">
                    <header className="mb-4">
                        <div className="row">
                            <div className="col-lg-6 mx-auto text-center">
                                <h2 data-aos="fade-up" data-aos-delay="100">
                                    {t('recentAuctions', { ns: 'home' })}
                                </h2>
                                <p
                                    className="text-muted lead mb-0"
                                    data-aos="fade-up"
                                    data-aos-delay="200"
                                >
                                    {t('getThis', { ns: 'home' })}
                                </p>
                            </div>
                        </div>
                    </header>

                    <Swiper
                        spaceBetween={0}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            900: { slidesPerView: 2 },
                            1200: { slidesPerView: 3 },
                            1400: { slidesPerView: 3 },
                        }}
                        navigation={Boolean(auctionItems.length !== 0)}
                    >
                        {auctionItems.length === 0
                            ? Array.from(Array(3)).map((_, key) => (
                                  <SwiperSlide
                                      className="pt-4 pb-5 px-sm-3"
                                      key={'auction-item-placeholder' + key}
                                  >
                                      <AuctionItem isLoading={true} />
                                  </SwiperSlide>
                              ))
                            : auctionItems.map((AUC, key) => {
                                  return (
                                      <SwiperSlide
                                          key={key}
                                          className="pt-4 pb-5 px-3"
                                      >
                                          <AuctionItem {...AUC} nftKey={key} />
                                      </SwiperSlide>
                                  );
                              })}
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Home;
