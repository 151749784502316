import React from 'react';
import { Link } from 'react-router-dom';
import { formatCategory } from '../../helpers/utils';
import { useTranslation } from 'react-i18next';

function Category({ category }) {
    const { t } = useTranslation(['nftCategory']);

    return (
        <p className="text-sm text-white badge bg-primary fw-normal d-flex align-items-center mb-0">
            {category ? (
                <Link className="text-white" to={`/categories/${category}`}>
                    <span className="text-white lh-reset">
                        {t(formatCategory(category), { ns: 'itemInfoPanel' })}
                    </span>
                </Link>
            ) : (
                <span className="text-white">
                    {t('noCateogry', { ns: 'nftCategory' })}
                </span>
            )}
        </p>
    );
}

export default Category;
