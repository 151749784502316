import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import Countdown from 'react-countdown';
import web3 from '../connect-web3/web3';
import ItemThumbnail from '../components/item/ItemThumbnail';
import ItemInfoPanel from '../components/item/ItemInfoPanel';
import ItemAuthor from '../components/item/ItemAuthor';
import { settings } from '../helpers/settings';
import Web3Context from '../providers/web3-context';
import CollectionContext from '../providers/collection-context';
import MarketplaceContext from '../providers/marketplace-context';
import AuctionContext from '../providers/auction-context';
import FullScreenLoader from '../components/general/FullScreenLoader';
import NftHistory from '../components/general/NftHistory';
import AuctionBids from '../components/general/AuctionBids';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import AuctionItem from '../components/general/AuctionItem';
import Modal from '../components/general/Modal';
import AuctionCta from '../components/general/AuctionCta';
import PricesLog from '../components/general/PricesLog';
import TabButton from '../components/TabButton/TabButton';
import { useSnackbar } from 'notistack';
import { GraphqlClient } from '../helpers/graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import { useTranslation } from 'react-i18next';
import NftFullScreenSkeleton from '../components/Skeletons/NftFullScreenSkeleton/NftFullScreenSkeleton';

function AuctionSingle() {
    const collectionCtx = useContext(CollectionContext);
    const marketplaceCtx = useContext(MarketplaceContext);
    const web3Ctx = useContext(Web3Context);
    const history = useHistory();
    const auctionCtx = useContext(AuctionContext);
    const [auctionData, setAuctionData] = useState(null);
    const [assetHistory, setAssetHistory] = useState(['0x9']);
    const [similarAuctions, setSimilarAuctions] = useState(null);
    const [ownerName, setOwnerName] = useState('Loading...');
    const [ownerAvatar, setOwnerAvatar] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const [currentAsset, setCurrentAsset] = useState([]);
    const [bidPrice, setBidPrice] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [auctionEnded, setAuctionEnded] = useState(false);
    const [isCurrentAsset, setIsCurrentAsset] = useState(null);
    const [isCurrentBidder, setIsCurrentBidder] = useState(null);
    const [currentBid, setCurrentBid] = useState(0);
    const [topBid, setTopBid] = useState(0);
    const [topBidder, setTopBidder] = useState('');
    const [historyType, setHistoryType] = useState('transactions');
    const { id } = useParams();
    const { t } = useTranslation(['auctionSingle']);
    /*** =============================================== */
    //      CHECK IF AUCTION IS STILL OPEN
    /*** =============================================== */
    useEffect(() => {
        if (auctionData) {
            if (auctionData.endAt <= new Date().getTime()) {
                setAuctionEnded(true);
            }
        }
    }, [auctionData]);

    /*** =============================================== */
    //      GET TOP BIDDER
    /*** =============================================== */
    useEffect(() => {
        if (auctionData) {
            if (
                auctionData.bids.filter((bid) => bid.withdraw !== true).length >
                0
            ) {
                const auctionBids = auctionData.bids
                    .filter((bid) => bid.withdraw !== true)
                    .map((bid) => bid.amount);
                const maxBid = Math.max(...auctionBids);
                const topBidder = auctionData.bids
                    .filter((bid) => bid.withdraw !== true)
                    .filter((bid) => bid.amount === maxBid)[0].bidder;
                setTopBidder(topBidder);
            }
        }
    }, [auctionData]);

    const Completionist = () => (
        <div className="row mb-4">
            <div className="col-lg-8">
                <div
                    className="bg-white rounded-xl p-4"
                    style={{
                        border:
                            marketplaceCtx.themeMode === 'light'
                                ? '3px solid #e9ecef'
                                : '3px solid #282830',
                    }}
                >
                    <h6 className="mb-0 fw-bold text-uppercase letter-spacing-0">
                        {t('aucEnd', { ns: 'auctionSingle' })}
                    </h6>
                    {web3Ctx.account.toLowerCase() === topBidder && topBid > 0 && (
                        <>
                            <p className="text-muted mb-2">
                                {t('winAuc', { ns: 'auctionSingle' })}
                            </p>
                            <button
                                className="btn btn-primary btn-sm py-2 px-4"
                                type="button"
                                onClick={claimNFTHandler}
                            >
                                <span className="lh-reset">
                                    {t('claim', { ns: 'auctionSingle' })}{' '}
                                </span>
                            </button>
                        </>
                    )}

                    {topBid === 0 &&
                        web3Ctx.account.toLowerCase() === auctionData.user && (
                            <>
                                <p className="text-muted mb-2">
                                    {t('noInterest', { ns: 'auctionSingle' })}
                                </p>
                                <button
                                    className="btn btn-primary btn-sm py-2 px-4"
                                    type="button"
                                    onClick={cancelHandler}
                                >
                                    <span className="lh-reset">
                                        {t('restore', { ns: 'auctionSingle' })}
                                    </span>
                                </button>
                            </>
                        )}

                    {topBid > 0 &&
                        web3Ctx.account.toLowerCase() === auctionData.user && (
                            <>
                                <p className="text-muted mb-2">
                                    {t('newOwner', { ns: 'auctionSingle' })}
                                </p>
                            </>
                        )}
                </div>
            </div>
        </div>
    );

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            return (
                <div className="row mb-4">
                    <div className="col-lg-8">
                        <div
                            className="bg-white rounded-xl px-4"
                            style={{
                                border:
                                    marketplaceCtx.themeMode === 'light'
                                        ? '3px solid #e9ecef'
                                        : '3px solid #282830',
                            }}
                        >
                            <div className="countdown rounded-lg mt-4 mb-5">
                                <div className="countdown-item flex-fill">
                                    <div className="countdown-item-number bg-white w-100">
                                        {days}
                                    </div>
                                    <span>
                                        {t('Days', { ns: 'auctionSingle' })}
                                    </span>
                                </div>
                                <div className="countdown-item flex-fill">
                                    <div className="countdown-item-number bg-white w-100">
                                        {hours}
                                    </div>
                                    <span>
                                        {t('Hours', { ns: 'auctionSingle' })}
                                    </span>
                                </div>
                                <div className="countdown-item flex-fill">
                                    <div className="countdown-item-number bg-white w-100">
                                        {minutes}
                                    </div>
                                    <span>
                                        {t('Mins', { ns: 'auctionSingle' })}
                                    </span>
                                </div>
                                <div className="countdown-item flex-fill">
                                    <div className="countdown-item-number bg-white w-100">
                                        {seconds}
                                    </div>
                                    <span>
                                        {t('Secs', { ns: 'auctionSingle' })}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };

    useEffect(() => {
        async function loadTokenData() {
            // check token draft or minted based on id
            const token = await getToken();
            if (token && token.length > 0) {
                setIsCurrentAsset(true);
                const { updatedTokens, owners } = updateOwnersAndPrice(token);
                const users = await GraphqlClient.getUsersByAccounts(owners);
                const withPriceMetaDataTokens = await Promise.all(
                    constructTokens(updatedTokens, users)
                );

                const auction = token[0].auctions[0];

                const extendedAuction = await Promise.all(
                    constructAuctions([auction], users, false)
                );

                setCurrentAsset(withPriceMetaDataTokens);
                setAuctionData(extendedAuction[0]);
                setOwnerName(withPriceMetaDataTokens[0].ownerName);
                setOwnerAvatar(withPriceMetaDataTokens[0].ownerAvatar);

                const similarAuctions = await getSimilarAuctions(
                    withPriceMetaDataTokens[0].category
                );
                const similarAuctionOwners = [
                    ...new Set(
                        similarAuctions.map((auction) => auction.creator.id)
                    ),
                ];
                const similarAuctionUsers =
                    await GraphqlClient.getUsersByAccounts(
                        similarAuctionOwners
                    );

                const extendedSimilarAuctions = await Promise.all(
                    constructAuctions(
                        similarAuctions,
                        similarAuctionUsers,
                        true
                    )
                );
                setSimilarAuctions(extendedSimilarAuctions);
            } else {
                setIsCurrentAsset(false);
            }
        }

        loadTokenData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const getToken = async () => {
        const GET_TOKEN = gql`
        {
            erc721Tokens(
                  where:{id: ${id}, onAuction: true, auctions_: {active: true}},
                ) {
                  id
                  owner {
                    id
                  }
                  onAuction
                  uri
                  category
                  creator {
                    id
                  }
                  auctions {
                      id
                      creator {
                          id
                      }
                      token {
                        id
                        uri
                        owner {
                            id
                        }
                      }
                      active
                      cancel
                      endTime
                      bids {
                          bidder {
                              id
                          }
                          withdraw
                          amount
                          time
                      }
                  }
                  createdTimestamp
                  nftTransactions{
                      id
                      nftTransactionActions {
                        from{
                          id
                        }
                        to {
                          id
                        }
                        timestamp
                        price
                      }
                  }
                }
        }
        `;

        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_TOKEN,
            'erc721Tokens'
        );
    };

    const getSimilarAuctions = async (category) => {
        const GET_AUCTIONS = gql`
            query auctions($skip: Int, $first: Int, $category: String) {
                auctions(skip: $skip, first: $first, where:{active: true, token_:{category: $category, id_not: ${id}}}) {
                    id
                    token {
                        id
                        uri
                        owner {
                            id
                        }
                    }
                    creator {
                        id
                    }
                    active
                    cancel
                    endTime
                    bids {
                        bidder {
                            id
                        }
                        withdraw
                        amount
                        time
                    }
                }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_AUCTIONS,
            'auctions',
            { skip: 0, first: 3 },
            { category }
        );
    };

    const updateOwnersAndPrice = (tokens) => {
        let owners = new Set();
        const updatedTokens = tokens.map((token) => {
            const validAuction = token.auctions[0];
            const bids = validAuction.bids;

            bids.forEach((bid) => {
                owners.add(bid.bidder.id);
            });

            let updatedToken = JSON.parse(JSON.stringify(token));

            updatedToken.realOwner = validAuction
                ? validAuction.creator.id
                : token.owner.id;
            owners.add(updatedToken.realOwner);
            owners.add(token.creator.id);
            token.nftTransactions.nftTransactionActions.forEach((t) => {
                owners.add(t.from.id);
                owners.add(t.to.id);
            });

            return updatedToken;
        });
        owners = [...owners];
        return {
            updatedTokens,
            owners,
        };
    };

    const constructTokens = (tokens, users) => {
        const result = tokens.map(async (token) => {
            const user = users.filter(
                (el) => el.account === token.realOwner
            )[0];

            const hash = token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken = {
                    id: token.id,
                    title: metadata.properties.name.description,
                    file:
                        metadata.properties?.file?.description ||
                        metadata.properties?.image?.description,
                    thumbnail:
                        metadata.properties?.thumbnail?.description ||
                        metadata.properties?.preview?.description ||
                        metadata.properties?.image?.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    unlockable: metadata.properties.unlockable.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    owner: token.realOwner,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                };

                const creator = users.filter(
                    (el) => el.account === token.creator.id
                )[0];
                extendedToken.creator = {
                    account: token.creator.id,
                    name: creator.fullName,
                    avatar: creator.avatar,
                    time: parseInt(token.createdTimestamp) * 1000,
                };
                extendedToken.assetHistory =
                    token.nftTransactions.nftTransactionActions
                        .sort((prev, curr) => prev.timestamp - curr.timestamp)
                        .map((transaction) => {
                            let fromUser = users.filter(
                                (el) => el.account === transaction.from.id
                            )[0];
                            let toUser = users.filter(
                                (el) => el.account === transaction.to.id
                            )[0];
                            return {
                                from: {
                                    account: transaction.from.id,
                                    name: fromUser?.fullName,
                                    avatar: fromUser?.avatar,
                                },
                                to: {
                                    account: transaction.to.id,
                                    name: toUser?.fullName,
                                    avatar: toUser?.avatar,
                                },
                                time: parseInt(transaction.timestamp) * 1000,
                                price: transaction.price,
                            };
                        });
                extendedToken.nftHistory = extendedToken.assetHistory.slice(1);

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked', e);
            }
        });
        return result;
    };

    const constructAuctions = (auctions, users, isSimilar) => {
        const result = auctions.map(async (auction) => {
            const user = users.filter(
                (el) => el.account === auction.creator.id
            )[0];

            const hash = auction.token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                let bids;
                if (isSimilar) {
                    bids = auction.bids.map((bid) => {
                        return {
                            bidder: bid.bidder.id,
                            amount: parseInt(bid.amount),
                            bidTime: parseInt(bid.time),
                            withdraw: bid.withdraw,
                        };
                    });
                } else {
                    bids = auction.bids.map((bid) => {
                        const bidder = users.filter(
                            (el) => el.account === bid.bidder.id
                        )[0];
                        return {
                            bidder: bid.bidder.id,
                            bidderAvatar: bidder.avatar,
                            bidderName: bidder.fullName,
                            amount: parseInt(bid.amount),
                            bidTime: parseInt(bid.time),
                            withdraw: bid.withdraw,
                        };
                    });
                }

                const metadata = await response.json();

                let auctionData = {
                    tokenId: auction.token.id,
                    auctionId: auction.id,
                    title: metadata.properties.name.description,
                    img: metadata.properties.file.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    unlockable: metadata.properties.unlockable.description,
                    endAt: parseInt(auction.endTime),
                    bids,
                    owner: auction.token.owner.id,
                    cancelled: auction.cancel,
                    active: auction.active,
                    user: auction.creator.id,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                };

                return auctionData;
            } catch (e) {
                console.log('an Auction was blocked', e);
            }
        });
        return result;
    };

    /*** =============================================== */
    //      GET TOP BID
    /*** =============================================== */
    useEffect(() => {
        if (auctionData) {
            if (auctionData.bids.length > 0) {
                const bids = auctionData.bids
                    .filter((bid) => bid.withdraw === false)
                    .map((bid) => bid.amount);
                if (bids.length > 0) {
                    setTopBid(Math.max(...bids));
                } else {
                    setTopBid(0);
                }
            } else {
                setTopBid(0);
            }
        }
    }, [auctionData, id]);

    /*** =============================================== */
    //      DECLARE PRICE REFERENCE
    /*** =============================================== */
    const priceRef = useRef();

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `${
            currentAsset.length > 0 ? currentAsset[0].title : 'NFT Item'
        } | Goocean NFT`;
    }, [currentAsset, id]);

    /*** =============================================== */
    //      VALIDATE IF THE USER IS CURRENT BIDDER
    /*** =============================================== */
    useEffect(() => {
        if (auctionData) {
            if (auctionData.bids.length > 0) {
                const filterBid = auctionData.bids.filter(
                    (bid) =>
                        bid.withdraw !== true &&
                        bid.bidder === web3Ctx.account.toLowerCase()
                )[0];
                if (filterBid) {
                    setIsCurrentBidder(true);
                    setCurrentBid(filterBid.amount);
                } else {
                    setIsCurrentBidder(false);
                }
            } else {
                setIsCurrentBidder(false);
            }
        }
    }, [web3Ctx.account, auctionData]);

    /*** =============================================== */
    //      CANCEL AUCTION FUNCTION
    /*** =============================================== */
    const cancelHandler = (event) => {
        auctionCtx.contract.methods
            .cancelAuction(auctionData.tokenId, auctionData.auctionId)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setIsModalOpen(false);
                    history.push(`users/${web3Ctx.account}`);
                }, 15000);
            })
            .on('error', (error) => {
                auctionCtx.setAuctionTransactionLoading(false);
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
            });
    };

    /*** =============================================== */
    //      PLACE BID FUNCTION
    /*** =============================================== */
    const placeBidHandler = (event, nftKey) => {
        event.preventDefault();

        const enteredPrice = web3.utils.toWei(bidPrice, 'ether');

        auctionCtx.contract.methods
            .bid(auctionData.tokenId, auctionData.auctionId, enteredPrice)
            .send({ from: web3Ctx.account, value: enteredPrice })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
                setIsModalOpen(false);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setIsModalOpen(false);
                    history.go(0);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      WITHDRAW BID FUNCTION
    /*** =============================================== */
    const withdrawBidHandler = (event) => {
        event.preventDefault();

        auctionCtx.contract.methods
            .withdraw(auctionData.tokenId, auctionData.auctionId)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setIsModalOpen(false);
                    history.go(0);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      CLAIM WINNDED NFT
    /*** =============================================== */
    const claimNFTHandler = (event) => {
        event.preventDefault();

        auctionCtx.contract.methods
            .endAuction(auctionData.tokenId, auctionData.auctionId)
            .send({
                from: web3Ctx.account,
            })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setIsModalOpen(false);
                    history.push(`/users/${web3Ctx.account}`);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
    }

    if (isCurrentAsset === false) {
        return (
            <div className="container py-5">
                <div className="row py-5 text-center">
                    <div className="col-lg-6 mx-auto">
                        <p
                            className="mb-0 fw-bold"
                            style={{ fontSize: '10rem' }}
                        >
                            404
                        </p>
                        <h1 className="h2 text-uppercase">Not Found</h1>
                        <p className="text-muted">
                            {t('notFound', { ns: 'auctionSingle' })}
                        </p>
                        <Link to="/" className="btn btn-gradient-primary">
                            {t('home', { ns: 'auctionSingle' })}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>
            {auctionCtx.fetchingLoading === true ? (
                <FullScreenLoader
                    heading={t('updating', { ns: 'auctionSingle' })}
                />
            ) : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            {collectionCtx.nftTransactionLoading ? <MetaMaskLoader /> : null}
            {marketplaceCtx.mktIsLoading ? (
                <FullScreenLoader
                    heading={t('loading', { ns: 'auctionSingle' })}
                />
            ) : null}
            <Modal
                status={isModalOpen}
                variant=""
                modalClose={closeModalHandler}
                layout={{ width: '500px', maxWidth: '100%' }}
            >
                <div className="card-body text-center p-4 p-lg-5">
                    <form onSubmit={(e) => placeBidHandler(e, parseInt(id))}>
                        <input
                            type="number"
                            step="0.001"
                            min="0.0000000000000000000000001"
                            placeholder={
                                t('price', { ns: 'auctionSingle' }) +
                                `${settings.currency}...`
                            }
                            className="form-control mb-2"
                            required={true}
                            autoFocus={true}
                            ref={priceRef}
                            value={bidPrice}
                            onChange={(e) => setBidPrice(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="btn btn-primary w-100 rounded-sm mb-2"
                        >
                            {t('placeBid', { ns: 'auctionSingle' })}
                        </button>
                        <p className="mb-0 text-center text-muted mb-0">
                            {t('youll', { ns: 'auctionSingle' })}{' '}
                            <span className="text-primary">
                                {t('myAccount', { ns: 'auctionSingle' })}
                            </span>{' '}
                            {t('notWon', { ns: 'auctionSingle' })}
                        </p>
                    </form>
                </div>
            </Modal>
            <section className="pt-5 bg-light">
                {currentAsset.length === 0 ? (
                    <div className="container pt-5">
                        <div className="pt-5 mt-4">
                            <NftFullScreenSkeleton />
                        </div>
                    </div>
                ) : (
                    currentAsset.map((asset, key) => {
                        return (
                            <div key={key}>
                                <div className="container pt-5">
                                    <div className="pt-5 mt-4">
                                        <div className="d-flex align-items-center justify-content-center">
                                            <Link
                                                className="text-reset"
                                                to={`/users/${
                                                    asset.creator &&
                                                    asset.creator.account
                                                }`}
                                            >
                                                <div className="author-avatar">
                                                    <span
                                                        className="author-avatar-inner"
                                                        style={{
                                                            background: `url(${
                                                                asset.creator &&
                                                                asset.creator
                                                                    .avatar
                                                                    ? asset
                                                                          .creator
                                                                          .avatar
                                                                    : '/images/astronaut.png'
                                                            })`,
                                                        }}
                                                    ></span>
                                                </div>
                                            </Link>
                                            <div className="ms-3 text-muted d-flex align-items-center">
                                                {t('by', {
                                                    ns: 'auctionSingle',
                                                })}
                                                <strong className="fw-bold lh-1 ms-2 lead text-dark">
                                                    <Link
                                                        className="text-reset"
                                                        to={`/users/${
                                                            asset.creator &&
                                                            asset.creator
                                                                .account
                                                        }`}
                                                    >
                                                        {asset.creator &&
                                                        asset.creator.name
                                                            ? asset.creator.name
                                                            : 'Adi Gallia'}
                                                    </Link>
                                                </strong>
                                            </div>
                                        </div>
                                        <h1 className="mb-4 text-center">
                                            {asset.title}
                                        </h1>
                                    </div>
                                    <div className="row mb-4 gy-4 mt-4">
                                        <div className="col-lg-6">
                                            <ItemThumbnail
                                                thumbnail={`${process.env.REACT_APP_IPFS_GATEWAY}${asset.file}`}
                                                type={asset.type}
                                                file={`${process.env.REACT_APP_IPFS_GATEWAY}${asset.file}`}
                                            />

                                            {asset.nftHistory && asset.creator && (
                                                <>
                                                    <div className="toggle-nav mt-5 mb-4">
                                                        <TabButton
                                                            className={`flex-fill ${
                                                                historyType ===
                                                                'transactions'
                                                                    ? 'active'
                                                                    : null
                                                            }`}
                                                            onClick={() =>
                                                                setHistoryType(
                                                                    'transactions'
                                                                )
                                                            }
                                                        >
                                                            <span className="lh-reset">
                                                                {t('transac', {
                                                                    ns: 'auctionSingle',
                                                                })}
                                                            </span>
                                                        </TabButton>
                                                        <TabButton
                                                            className={`flex-fill ${
                                                                historyType ===
                                                                'bids'
                                                                    ? 'active'
                                                                    : null
                                                            }`}
                                                            onClick={() =>
                                                                setHistoryType(
                                                                    'bids'
                                                                )
                                                            }
                                                        >
                                                            <span className="lh-reset">
                                                                {t('bids', {
                                                                    ns: 'auctionSingle',
                                                                })}
                                                            </span>
                                                        </TabButton>
                                                        <TabButton
                                                            className={`flex-fill ${
                                                                historyType ===
                                                                'prices'
                                                                    ? 'active'
                                                                    : null
                                                            }`}
                                                            onClick={() =>
                                                                setHistoryType(
                                                                    'prices'
                                                                )
                                                            }
                                                        >
                                                            <span className="lh-reset">
                                                                {t('priceLog', {
                                                                    ns: 'auctionSingle',
                                                                })}
                                                            </span>
                                                        </TabButton>
                                                    </div>
                                                    {historyType ===
                                                        'transactions' && (
                                                        <NftHistory
                                                            history={
                                                                asset.nftHistory
                                                            }
                                                            creator={
                                                                asset.creator
                                                            }
                                                            owner={asset.owner}
                                                            mktAddress={
                                                                marketplaceCtx
                                                                    .contract
                                                                    .options
                                                                    .address
                                                            }
                                                            isAuction={true}
                                                        />
                                                    )}
                                                    {historyType === 'bids' &&
                                                        auctionData && (
                                                            <AuctionBids
                                                                bids={
                                                                    auctionData.bids
                                                                }
                                                            />
                                                        )}
                                                    {historyType === 'prices' &&
                                                        auctionData && (
                                                            <PricesLog
                                                                history={
                                                                    asset.nftHistory
                                                                }
                                                            />
                                                        )}
                                                </>
                                            )}
                                        </div>

                                        <div className="col-lg-6">
                                            {asset.creator && (
                                                <ItemInfoPanel
                                                    name={asset.title}
                                                    category={asset.category}
                                                    img={`${process.env.REACT_APP_IPFS_GATEWAY}${asset.thumbnail}`}
                                                    artist={
                                                        asset.creator.account
                                                    }
                                                    description={
                                                        asset.description
                                                    }
                                                    dateCreated={
                                                        asset.dateCreated
                                                    }
                                                    royalties={asset.royalties}
                                                    unlockable={
                                                        asset.unlockable
                                                    }
                                                    formate={asset.formate}
                                                    type={asset.type}
                                                />
                                            )}

                                            {auctionData && (
                                                <Countdown
                                                    date={auctionData.endAt}
                                                    renderer={renderer}
                                                    onComplete={() =>
                                                        setAuctionEnded(true)
                                                    }
                                                />
                                            )}

                                            {asset.unlockable &&
                                                asset.owner ===
                                                    web3Ctx.account.toLowerCase() && (
                                                    <div className="row mb-4">
                                                        <div className="col-xl-8">
                                                            <a
                                                                href={
                                                                    asset.unlockable
                                                                }
                                                                className="btn btn-info px-4 w-100"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                <i className="las la-cloud me-2"></i>{' '}
                                                                {t('download', {
                                                                    ns: 'auctionSingle',
                                                                })}
                                                            </a>
                                                        </div>
                                                    </div>
                                                )}

                                            {auctionData && asset.creator && (
                                                <ItemAuthor
                                                    history={asset.assetHistory}
                                                    creator={asset.creator}
                                                    owner={auctionData.user}
                                                    ownerName={ownerName}
                                                    ownerAvatar={ownerAvatar}
                                                    marketplaceAddress={
                                                        auctionCtx.contract
                                                            .options.address
                                                    }
                                                />
                                            )}

                                            {auctionData &&
                                                auctionEnded !== true && (
                                                    <AuctionCta
                                                        topBid={topBid}
                                                        currentBid={currentBid}
                                                        isCurrentBidder={
                                                            isCurrentBidder
                                                        }
                                                        setIsModalOpen={
                                                            setIsModalOpen
                                                        }
                                                        cancelHandler={
                                                            cancelHandler
                                                        }
                                                        placeBidHandler={
                                                            placeBidHandler
                                                        }
                                                        withdrawBidHandler={
                                                            withdrawBidHandler
                                                        }
                                                        claimNFTHandler={
                                                            claimNFTHandler
                                                        }
                                                        closeModalHandler={
                                                            closeModalHandler
                                                        }
                                                        owner={auctionData.user}
                                                    />
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                )}
            </section>

            {similarAuctions && similarAuctions.length > 0 && (
                <section className="pb-5 bg-light">
                    <div className="container pb-5">
                        <header className="mb-4">
                            <div className="row">
                                <div className="col-lg-6">
                                    <h2
                                        data-aos="fade-right"
                                        data-aos-delay="100"
                                        data-aos-once="true"
                                    >
                                        {t('similar', { ns: 'auctionSingle' })}
                                    </h2>
                                    <p
                                        className="text-muted lead mb-0"
                                        data-aos="fade-right"
                                        data-aos-delay="200"
                                        data-aos-once="true"
                                    >
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Repudiandae esse quis
                                        sed,necessitatibus nostrum mollitia.
                                    </p>
                                </div>
                            </div>
                        </header>
                        <div className="row gy-5">
                            {similarAuctions.map((AUC, key) => {
                                return (
                                    <div
                                        className={`col-xl-4 col-md-6 ${AUC.category}`}
                                        key={AUC.tokenId}
                                    >
                                        <AuctionItem {...AUC} nftKey={key} />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
}

export default AuctionSingle;
