export const lightTheme = {
    background: 'white',
    tabButtonBackgroundColor: 'white',
    fontColor: '#222',
};

export const darkTheme = {
    background: '#1c1c22',
    tabButtonBackgroundColor: '#1a1a20',
    fontColor: '#eee',
};
