import React, { Suspense } from 'react';
import { Environment, Html, Loader, OrbitControls } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import Model from '../Model/Model';

const ModelViewer = ({ modelUrl, cameraPosition, styles = {} }) => {
    return (
        <Canvas
            dpr={[1, 2]}
            camera={{
                position: cameraPosition,
                fov: 50,
                near: 0.01,
                far: 1000,
            }}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            style={styles}
        >
            <ambientLight intensity={1} />
            <Suspense
                fallback={
                    <Html center>
                        <Loader dataStyles={{ color: 'rgb(25,25,25)' }} />
                    </Html>
                }
            >
                <Model modelUrl={modelUrl} />
                <OrbitControls maxDistance={380} />
                <Environment preset="city" />
            </Suspense>
        </Canvas>
    );
};

export default ModelViewer;
