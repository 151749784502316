import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { formatFullDate, formatPrice } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import DataTable, { createTheme } from 'react-data-table-component';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../../helpers/theGraphClient';
import { ACTIVITIES_NUMS } from '../../helpers/pages';
import { GraphqlClient } from '../../helpers/graphqlClient';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

createTheme(
    'solarized',
    {
        background: {
            default: '#1c1c22',
        },
    },
    'dark'
);

function ActivityTable({ filteredAddress }) {
    const { t } = useTranslation(['activity']);
    const [activity, setActivity] = useState(null);

    /*** =============================================== */
    //      GET ACTIVITY
    /*** =============================================== */
    useEffect(() => {
        const loadActivities = async () => {
            const activities = await getActivities({
                skip: 0,
                first: ACTIVITIES_NUMS,
            });
            const accounts = [
                ...new Set(activities.map((activity) => activity.user.id)),
            ];
            const users = await GraphqlClient.getUsersByAccounts(accounts);
            const extendedActivities = constructActivities(activities, users);

            setActivity(extendedActivities);
        };

        loadActivities().catch(console.error);
    }, []);

    const getActivities = async (paging) => {
        let conditions = '';
        if (filteredAddress) {
            conditions = `, where: {user: "${filteredAddress}"}`;
        }
        const GET_ACTIVITIES = gql`
            query activities($skip: Int, $first: Int) {
                userActivities(skip: $skip, first: $first, orderBy: time${conditions}) {
                    user {
                        id
                    }
                    price
                    royality
                    commission
                    time
                    status
                }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_ACTIVITIES,
            'userActivities',
            paging
        );
    };

    const constructActivities = (activities, users) => {
        const result = activities.map((activity) => {
            const user = users.filter(
                (el) => el.account === activity.user.id
            )[0];

            return {
                address: user.account,
                price: activity.price,
                royalties: parseInt(activity.royality),
                commission: parseInt(activity.commission) / 10,
                type: activity.status,
                time: parseInt(activity.time) * 1000,
                name: user.fullName,
                avatar: user.avatar,
            };
        });
        return result;
    };

    /*** =============================================== */
    //      TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: t('user', { ns: 'activity' }),
            selector: (row) => row.address,
            cell: (row) => (
                <div row={row}>
                    <Link to={`/users/${row.address}`} className="text-reset">
                        <div className="d-flex align-items-center py-2">
                            <div className="author-avatar author-avatar-sm">
                                <span
                                    className="author-avatar-inner"
                                    style={{
                                        background: `url(${
                                            row.avatar === ''
                                                ? '/images/astronaut.png'
                                                : row.avatar
                                        })`,
                                    }}
                                ></span>
                            </div>
                            <p className="ms-2 mb-0 fw-bold">{row.name}</p>
                        </div>
                    </Link>
                </div>
            ),
        },
        {
            name: t('time', { ns: 'activity' }),
            selector: (row) => row.time,
            cell: (row) => (
                <div row={row}>
                    <p className="mb-0">
                        {formatFullDate(row.time, i18next.language)}
                    </p>
                </div>
            ),
        },
        {
            name: t('action', { ns: 'activity' }),
            selector: (row) => row.type,
            cell: (row) =>
                row.type === 'Add User' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-gray-700">
                            {t('userReg', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Mint NFT Token' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-primary">
                            {t('mint', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Make Offer' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-info">
                            {t('addPrice', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Buy NFT' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-success">
                            {t('buy', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Claim Funds' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-success">
                            {t('collect', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Cancel Offer' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-danger">
                            {t('cancel', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Update User' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-gray-700">
                            {t('update', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Added to Whitelist' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-info">
                            {t('addWhite', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Removed from Whitelist' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-danger">
                            {t('removeWhite', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Withdraw' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-danger">
                            {t('withdrawBid', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Create auction' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-info">
                            {t('createAuction', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Add Bid' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-info">
                            {t('placeBid', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'Withdraw from auction' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-danger">
                            {t('withdrawAuc', { ns: 'activity' })}
                        </span>
                    </div>
                ) : row.type === 'End auction' ? (
                    <div row={row}>
                        <span className="fw-bold badge lh-reset bg-success">
                            {t('endAuc', { ns: 'activity' })}
                        </span>
                    </div>
                ) : (
                    '-'
                ),
        },
        {
            name: t('price', { ns: 'activity' }),
            selector: (row) => row.price,
            cell: (row) => (
                <div row={row}>
                    {row.price !== '0' ? (
                        <p className="mb-0">
                            {formatPrice(row.price).toFixed(3)}{' '}
                            {settings.currency}
                        </p>
                    ) : (
                        <p className="mb-0">-</p>
                    )}
                </div>
            ),
        },
        {
            name: t('commission', { ns: 'activity' }),
            selector: (row) => row.commission,
            cell: (row) => (
                <div row={row}>
                    {row.commission !== 0 ? (
                        row.type === 'Buy NFT' ? (
                            <p className="mb-0">
                                {row.commission / 10 ** 18} {settings.currency}
                            </p>
                        ) : (
                            <p className="mb-0">{row.commission}%</p>
                        )
                    ) : (
                        <p className="mb-0">-</p>
                    )}
                </div>
            ),
        },
        {
            name: t('royalties', { ns: 'activity' }),
            selector: (row) => row.royalties,
            cell: (row) => (
                <div row={row}>
                    {row.royalties > 100 ? (
                        <p className="mb-0">
                            {formatPrice(row.royalties).toFixed(3)}{' '}
                            {settings.currency}
                        </p>
                    ) : row.royalties !== 0 && row.royalties < 100 ? (
                        <p className="mb-0">{row.royalties}%</p>
                    ) : (
                        <p className="mb-0">-</p>
                    )}
                </div>
            ),
        },
    ];

    return (
        activity && (
            <DataTable
                columns={columns}
                data={activity.sort((a, b) => {
                    return new Date(b.time) - new Date(a.time);
                })}
                pagination={activity.length >= 1 && true}
                responsive
                theme="solarized"
            />
        )
    );
}

export default ActivityTable;
