import React, { useContext, useEffect, useState } from 'react';
import Web3Context from '../../../providers/web3-context';
import { Controller, useForm } from 'react-hook-form';
import AOS from 'aos';
import { GraphqlClient } from '../../../helpers/graphqlClient';
import web3 from '../../../connect-web3/web3';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import BadgeAvatar from '../../../components/BadgeAvatar/BadgeAvatar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import FacebookIcon from '../../../components/Icons/FacebookIcon';
import InstagramIcon from '../../../components/Icons/InstagramIcon';
import TwitterIcon from '../../../components/Icons/TwitterIcon';
import DiscordIcon from '../../../components/Icons/DiscordIcon';
import Button from '@mui/material/Button';
import FullScreenLoader from '../../../components/general/FullScreenLoader';
import { useSnackbar } from 'notistack';
import Banner from '../../../components/Banner/Banner';
import { fetchClient } from '../../../helpers/fetchClient';

const AccountPanel = () => {
    const { t } = useTranslation(['userProfile']);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const web3Ctx = useContext(Web3Context);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, control, reset } = useForm({
        mode: 'onBlur',
        reValidateMode: 'onChange',
    });

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [about, setAbout] = useState('');
    const [twitter, setTwitter] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [discord, setDiscord] = useState('');
    const [web, setWeb] = useState('');
    const [selectedAvatarFile, setSelectedAvatarFile] = useState(null);
    const [selectedBannerFile, setSelectedBannerFile] = useState(null);

    const [isCurrentUser, setIsCurrentUser] = useState(false);

    const [userDetails, setUserDetails] = useState({
        avatar: '',
        header: '',
        fullName: '',
        email: '',
        about: '',
        web: '',
        facebook: '',
        twitter: '',
        instagram: '',
        discord: '',
    });

    useEffect(() => {
        const loadUser = async () => {
            const user = await GraphqlClient.getUserInfo(
                web3Ctx.account.toLowerCase()
            );
            if (user) {
                setIsCurrentUser(true);
                setUserDetails(user);
            } else {
                setIsCurrentUser(false);
            }
        };
        if (web3Ctx.account) {
            loadUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [web3Ctx.account]);

    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 700, disable: 'mobile' });
    }, []);

    /*** =============================================== */
    //      GET USER INFORMATION
    /*** =============================================== */
    useEffect(() => {
        setFullName(userDetails?.fullName || '');
        setEmail(userDetails?.email || '');
        setWeb(userDetails?.web || '');
        setAbout(userDetails?.about || '');
        setFacebook(userDetails?.facebook || '');
        setInstagram(userDetails?.instagram || '');
        setTwitter(userDetails?.twitter || '');
        setDiscord(userDetails?.discord || '');

        reset({
            ...userDetails,
        });
    }, [userDetails]);

    const handleBannerFileSelected = (file) => {
        setSelectedBannerFile(file);
    };

    const handleAvatarFileSelected = (file) => {
        setSelectedAvatarFile(file);
    };

    const onSubmit = async (data) => {
        if (data.facebook === '') {
            data.facebook = null;
        }
        if (data.twitter === '') {
            data.twitter = null;
        }
        if (data.discord === '') {
            data.discord = null;
        }
        if (data.instagram === '') {
            data.instagram = null;
        }

        delete data.account;
        delete data.admin;
        setLoading(true);

        try {
            if (selectedAvatarFile) {
                const { signedUrl: signedUrlAvatar, fileLink: fileLinkAvatar } =
                    await GraphqlClient.getAvatarUrlToWrite(
                        web3Ctx.account.toLowerCase(),
                        {
                            fileName: selectedAvatarFile.name,
                            fileType: selectedAvatarFile.type,
                        }
                    );

                await fetchClient.writeFileToStorage(
                    signedUrlAvatar,
                    selectedAvatarFile
                );

                data.avatar = fileLinkAvatar;
            }
        } catch (e) {
            enqueueSnackbar(t('personalInfo.uploadAvatarFailed'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        try {
            if (selectedBannerFile) {
                const { signedUrl: signedUrlBanner, fileLink: fileLinkBanner } =
                    await GraphqlClient.getHeaderUrlToWrite(web3Ctx.account, {
                        fileName: selectedBannerFile.name,
                        fileType: selectedBannerFile.type,
                    });

                await fetchClient.writeFileToStorage(
                    signedUrlBanner,
                    selectedBannerFile
                );

                data.header = fileLinkBanner;
            }
        } catch (e) {
            enqueueSnackbar(t('personalInfo.uploadBannerFailed'), {
                variant: 'error',
            });
            setLoading(false);
            return;
        }

        // check if User exist
        try {
            // UpdateUser directly and check if failed
            // UpdateUser directly and check if failed
            delete data.inWhiteList;
            const updateUserRes = await GraphqlClient.updateUser(
                web3Ctx.account,
                data
            );

            web3Ctx.loadAccount(web3);
            enqueueSnackbar(t('personalInfo.updateSuccess'), {
                variant: 'success',
            });

            history.go(0);
        } catch (e) {
            enqueueSnackbar(t('personalInfo.updateFailed'), {
                variant: 'error',
            });
            setLoading(false);
        }
    };

    const renderSocialMediaTextField = () => {
        return [
            {
                label: 'Facebook',
                icon: <FacebookIcon />,
                defaultValue: facebook,
                url: 'https//:www.facebook.com/...',
            },
            {
                label: 'Twitter',
                icon: <TwitterIcon />,
                defaultValue: twitter,
                url: 'https//:www.twitter.com/...',
            },
            {
                label: 'Instagram',
                icon: <InstagramIcon />,
                defaultValue: instagram,
                url: 'https//:www.instagram.com/...',
            },
            {
                label: 'Discord',
                icon: <DiscordIcon />,
                defaultValue: discord,
                url: 'https//:www.discord.com/...',
            },
        ].map(({ label, icon, defaultValue, url }) => {
            return (
                <Grid item xs={12} sm={6} key={'account-panel-' + label}>
                    <Controller
                        defaultValue={defaultValue}
                        control={control}
                        render={({
                            field: { onChange, value, onBlur },
                            fieldState: { error },
                        }) => (
                            <TextField
                                placeholder={url}
                                label={label}
                                variant="outlined"
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            {icon}
                                        </InputAdornment>
                                    ),
                                }}
                                size={'medium'}
                                fullWidth
                                error={!!error}
                                helperText={
                                    error
                                        ? t('personalInfo.validaError.url')
                                        : null
                                }
                            />
                        )}
                        {...register(label.toLowerCase(), {
                            pattern:
                                /(((https?)):\/\/)?(www\.)?(instagram|discord|facebook|twitter)\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                        })}
                    />
                </Grid>
            );
        });
    };

    return (
        <>
            {loading && <FullScreenLoader />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Banner
                            bgUrl={userDetails.header}
                            isEditable={true}
                            onFileSelected={handleBannerFileSelected}
                        />
                        <Grid container marginTop={'-4rem'}>
                            <Grid
                                item
                                xs={12}
                                sm={3}
                                display="flex"
                                justifyContent={{
                                    xs: 'center',
                                    sm: 'flex-start',
                                }}
                                alignItems="center"
                            >
                                <BadgeAvatar
                                    avatarSrc={userDetails?.avatar}
                                    isEditable={true}
                                    onFileSelected={handleAvatarFileSelected}
                                ></BadgeAvatar>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {t('personalInfo.basicInfo')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Controller
                            defaultValue={fullName}
                            control={control}
                            inputProps={{
                                inputMode: 'string',
                                maxlength: 20,
                            }}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    label={t('personalInfo.displayName')}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    size={'medium'}
                                    error={!!error}
                                    helperText={
                                        error
                                            ? t(
                                                  'personalInfo.validaError.displayName'
                                              )
                                            : null
                                    }
                                    fullWidth
                                />
                            )}
                            {...register('fullName', {
                                maxLength: 20,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Controller
                            defaultValue={email}
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    type={'email'}
                                    label={t('personalInfo.email')}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    size={'medium'}
                                    fullWidth
                                    error={!!error}
                                    helperText={
                                        error
                                            ? t('personalInfo.validaError.url')
                                            : null
                                    }
                                />
                            )}
                            {...register('email', {
                                pattern:
                                    /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            })}
                        />
                    </Grid>
                    <Grid item xs={12} sm={9}>
                        <Controller
                            defaultValue={web}
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    label={t('personalInfo.web')}
                                    variant="outlined"
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    size={'medium'}
                                    fullWidth
                                    error={!!error}
                                    helperText={
                                        error
                                            ? t('personalInfo.validaError.url')
                                            : null
                                    }
                                />
                            )}
                            {...register('web', {
                                pattern:
                                    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                            })}
                        />
                    </Grid>

                    <Grid item xs={12} sm={9}>
                        <Controller
                            defaultValue={about}
                            name={'about'}
                            control={control}
                            render={({
                                field: { onChange, value, onBlur },
                                fieldState: { error },
                            }) => (
                                <TextField
                                    label={t('personalInfo.about')}
                                    multiline
                                    maxRows={3}
                                    minRows={3}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    variant="outlined"
                                    fullWidth
                                    error={!!error}
                                    helperText={
                                        error
                                            ? t('personalInfo.validaError.bio')
                                            : null
                                    }
                                />
                            )}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'h6'}>
                            {t('personalInfo.socialMedia')}
                        </Typography>
                    </Grid>
                    {renderSocialMediaTextField()}
                    <Grid item xs={12}>
                        <Button variant="contained" type={'submit'}>
                            {t('personalInfo.save')}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};
export default AccountPanel;
