import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import ModelViewer from '../ModelViewer/ModelViewer';
import ItemLightbox from './ItemLightbox';
import { useTranslation } from 'react-i18next';
import reactImageSize from 'react-image-size';
import ItemToolBox from './ItemToolBox';

const melodyStyle = {
    fontSize: '7rem',
    color: '#fff',
    position: 'absolute',
    top: 'calc(50% - 3rem)',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

function ItemThumbnail({ file, thumbnail, type }) {
    const { t } = useTranslation(['createItem']);
    const previewWrapperEl = useRef(null);
    const [toggler, setToggler] = useState(false);
    const [imgWidthRatio, setImgWidthRatio] = useState(null);
    const [imgHeightRatio, setImgHeightRatio] = useState(null);
    const onFullscreenClick = (e) => {
        setToggler(!toggler);
    };
    useEffect(() => {
        if (previewWrapperEl.current && type === 'audio' && thumbnail) {
            previewWrapperEl.current.style.backgroundImage = `url(${thumbnail})`;
            previewWrapperEl.current.style.backgroundSize = 'cover';
            previewWrapperEl.current.style.backgroundPosition = 'center center';
        }
    }, [previewWrapperEl]);

    useEffect(() => {
        async function getImageSize(x) {
            try {
                const { width, height } = await reactImageSize(x);
                setImgWidthRatio(50 + (width / 2 / 1100) * 100 - 5);
                setImgHeightRatio(50 + (height / 2 / 700) * 100 - 5);
            } catch {
                setImgWidthRatio(90);
                setImgHeightRatio(90);
            }
        }

        getImageSize(thumbnail);
    }, [thumbnail]);

    const renderItem = () => {
        switch (type) {
            case 'image':
                return (
                    <img
                        className="img-fluid rounded h-100"
                        style={{ margin: 'auto' }}
                        src={file}
                        alt="..."
                    />
                );
            case 'audio':
                return (
                    <>
                        <i className="las la-music" style={melodyStyle}></i>
                        <AudioPlayer
                            src={file}
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                        />
                    </>
                );

            case 'video':
                return (
                    <ReactPlayer
                        url={file}
                        controls={true}
                        width="100%"
                        height="auto"
                    />
                );
            case '3d':
                return (
                    <ModelViewer
                        modelUrl={file}
                        cameraPosition={[0, 0, 60]}
                    ></ModelViewer>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div
                className={`card shadow rounded-xl ${
                    type === 'audio' ? 'audio' : ''
                }`}
            >
                <div
                    className="card-body position-relative d-flex align-items-end"
                    ref={previewWrapperEl}
                    style={{ minHeight: 400, maxHeight: 600, height: 1 }}
                >
                    {renderItem()}
                    <ItemToolBox
                        onFullscreenClick={onFullscreenClick}
                        onlyFullscreen={true}
                    ></ItemToolBox>
                </div>
            </div>
            <ItemLightbox
                toggler={toggler}
                type={type}
                file={file}
                enlarge={true}
            ></ItemLightbox>
        </>
    );
}

export default ItemThumbnail;
