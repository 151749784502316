/*** =============================================== */
//      GRAPHQL
import { apolloClient } from '../index';

/*** =============================================== */

const runQuery = async (query, entityName, paging, args = {}) => {
    let oResult;
    const res = await apolloClient.query({
        query: query,
        variables: {
            skip: paging.skip,
            first: paging.first,
            ...args,
        },
        context: {
            clientName: 'theGraph',
        },
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data[entityName];
    }
    return oResult;
};

const runQueryWithoutPaging = async (query, entityName, args = {}) => {
    let oResult;
    const res = await apolloClient.query({
        query: query,
        variables: {
            ...args,
        },
        context: {
            clientName: 'theGraph',
        },
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data[entityName];
    }
    return oResult;
};

export const theGraphGraphqlClient = {
    runQuery: runQuery,
    runQueryWithoutPaging: runQueryWithoutPaging,
};
