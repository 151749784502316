import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import { truncateStart } from '../../helpers/utils';
import BadgeAvatar from '../BadgeAvatar/BadgeAvatar';
import { useTranslation } from 'react-i18next';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NftCard = ({
    owner,
    id,
    file,
    creatorName,
    creatorAvatar,
    isTopSeller,
}) => {
    const { t } = useTranslation(['homeBanner']);
    return (
        <>
            <Card elevation={6}>
                <Link className="text-reset" to={`/assets/${id}`}>
                    <CardMedia
                        component={'img'}
                        alt={'nft'}
                        height="50%"
                        image={`${process.env.REACT_APP_IPFS_GATEWAY}${file}`}
                    />
                </Link>
            </Card>
            <Stack direction={'row'} alignItems={'center'} marginTop={'1rem'}>
                <Link className="text-reset" to={`/users/${owner}`}>
                    <BadgeAvatar
                        size={50}
                        badgeContent={
                            isTopSeller && (
                                <span className="bg-primary badge mb-0 ms-2 text-xxs">
                                    <i className="las la-trophy me-1 text-xxs"></i>
                                    {t('topSeller')}
                                </span>
                            )
                        }
                        avatarSrc={creatorAvatar || '/images/astronaut.png'}
                    ></BadgeAvatar>
                </Link>
                <Link className="text-reset" to={`/users/${owner}`}>
                    <Typography
                        variant={'subtitle2'}
                        marginLeft={'1rem'}
                        className={'text-muted'}
                    >
                        {t('creator')}
                    </Typography>
                    <Typography variant={'h6'} marginLeft={'1rem'}>
                        @{truncateStart(creatorName, 10)}
                    </Typography>
                </Link>
            </Stack>
        </>
    );
};

export default NftCard;
