import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import { useTranslation } from 'react-i18next';

function PageBanner({ heading, bannerBg }) {
    /*** =============================================== */
    //      INITIATE AOS ANIMATION
    /*** =============================================== */
    useEffect(() => {
        AOS.init({ duration: 1000 });
    }, []);

    const { t } = useTranslation(['pageBanner']);

    return (
        <section className={`page-banner pt-5 position-relative ${bannerBg}`}>
            <div className="container pt-5 mt-5 z-index-20">
                <h1
                    className="text-center"
                    data-aos="fade-right"
                    data-aos-delay="100"
                >
                    {heading}
                </h1>

                <nav
                    aria-label="breadcrumb"
                    data-aos="fade-right"
                    data-aos-delay="200"
                >
                    <ol className="breadcrumb justify-content-center">
                        <li className="breadcrumb-item">
                            <Link
                                className="text-decoration-none d-flex align-items-center"
                                to="/"
                            >
                                {' '}
                                <i className="las la-home la-sm me-1"></i>
                                {t('home', { ns: 'pageBanner' })}
                            </Link>
                        </li>
                        <li
                            className="breadcrumb-item active"
                            aria-current="page"
                        >
                            {heading}
                        </li>
                    </ol>
                </nav>
            </div>
        </section>
    );
}

export default PageBanner;
