import React, { useEffect, useState } from 'react';
import { settings } from '../helpers/settings';

// COMPONENTS
import PageBanner from '../components/general/PageBanner';
import Dashboard from '../components/admin/Dashboard';
import WhiteList from '../components/admin/WhiteList';
import TabButton from '../components/TabButton/TabButton';
import { GraphqlClient } from '../helpers/graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';

function Admin() {
    const [isNavSelected, setIsNavSelected] = useState('dashboard');
    const [usersList, setUsersList] = useState(null);
    const [whiteList, setWhiteList] = useState(null);
    const [ownerFunds, setOwnerFunds] = useState(0);

    /*** =============================================== */
    //      CHANGE PAGE TITLE
    /*** =============================================== */
    useEffect(() => {
        document.title = `Admin Panel | ${settings.UISettings.marketplaceBrandName}`;
    }, []);

    useEffect(() => {
        const loadData = async () => {
            const usersList = await GraphqlClient.getUsersList();
            const whiteList = await GraphqlClient.getWhiteList();
            setUsersList(usersList);
            setWhiteList(whiteList);

            const marketplaceFunds = await getMarketplaceFunds();
            const auctionFunds = await getAuctionFunds();

            let ownerFunds = JSON.parse(JSON.stringify(marketplaceFunds)).map(
                (el) => {
                    return {
                        id: el.id,
                        fund: parseInt(el.fund),
                    };
                }
            );
            auctionFunds.forEach((aucFund) => {
                let index = ownerFunds.findIndex(
                    (mktFund) => mktFund.id === aucFund.id
                );
                if (index != -1) {
                    ownerFunds[index].fund += parseInt(aucFund.fund);
                } else {
                    aucFund.fund = parseInt(aucFund.fund);
                    ownerFunds.append(aucFund);
                }
            });

            setOwnerFunds(ownerFunds);
        };
        loadData();
    }, []);

    const getMarketplaceFunds = async () => {
        const GET_MARKETPLACE_FUNDS = gql`
            {
                marketplaceFunds(orderBy: fund, orderDirection: desc) {
                    id
                    fund
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_MARKETPLACE_FUNDS,
            'marketplaceFunds'
        );
    };

    const getAuctionFunds = async () => {
        const GET_AUCTION_FUNDS = gql`
            {
                auctionFunds(orderBy: fund, orderDirection: desc) {
                    id
                    fund
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_AUCTION_FUNDS,
            'auctionFunds'
        );
    };
    return (
        <>
            <PageBanner heading="Admin Panel" bannerBg="bg-light pb-0" />
            <section className="py-5 bg-light">
                <div className="container pb-5">
                    <div className="row mb-5 pb-5">
                        <div className="col-lg-8 mx-auto">
                            <div
                                className="toggle-nav"
                                data-aos="fade-up"
                                data-aos-delay="100"
                            >
                                <TabButton
                                    className={`flex-fill ${
                                        isNavSelected === 'dashboard'
                                            ? 'active'
                                            : null
                                    }`}
                                    onClick={() =>
                                        setIsNavSelected('dashboard')
                                    }
                                >
                                    Dashboard
                                </TabButton>
                                <TabButton
                                    className={`flex-fill ${
                                        isNavSelected === 'whitelist'
                                            ? 'active'
                                            : null
                                    }`}
                                    onClick={() =>
                                        setIsNavSelected('whitelist')
                                    }
                                >
                                    WhiteList
                                </TabButton>
                            </div>
                        </div>
                    </div>

                    {isNavSelected === 'dashboard' && (
                        <Dashboard
                            usersList={usersList}
                            whiteList={whiteList}
                            ownerFunds={ownerFunds}
                        />
                    )}
                    {isNavSelected === 'whitelist' && (
                        <WhiteList
                            usersList={usersList}
                            whiteList={whiteList}
                            ownerFunds={ownerFunds}
                        />
                    )}
                </div>
            </section>
        </>
    );
}

export default Admin;
