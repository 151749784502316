import React, { useContext, useState } from 'react';
import Modal from './Modal';
import { settings } from '../../helpers/settings';
import MarketplaceContext from '../../providers/marketplace-context';
import { useTranslation } from 'react-i18next';

const networks = {
    development: {
        chainId: `0x${Number(settings.networkId).toString(16)}`,
        chainName: settings.activeNetworkName,
        nativeCurrency: {
            name: `${settings.activeNetworkName} Native Token`,
            symbol: settings.currency,
            decimals: 18,
        },
        rpcUrls: [settings.rpcUrl],
        blockExplorerUrls: [settings.blockExplorerUrls],
    },
    bsctest: {
        chainId: `0x${Number(settings.networkId).toString(16)}`,
        chainName: settings.UISettings.usedNetworkName,
        nativeCurrency: {
            name: `${settings.UISettings.usedNetworkName} Native Token`,
            symbol: settings.currency,
            decimals: 18,
        },
        rpcUrls: [settings.rpcUrl],
        blockExplorerUrls: [settings.blockExplorerUrls],
    },
};

const changeNetwork = async ({ networkName }) => {
    try {
        if (!window.ethereum) throw new Error('No crypto wallet found');

        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: networks[networkName].chainId }],
        });
    } catch (switchError) {
        if (switchError.code === 4902) {
            try {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            ...networks[networkName],
                        },
                    ],
                });
            } catch (error) {
                console.log(error.message);
            }
        }
    }

    try {
        return await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [
                {
                    ...networks[networkName],
                },
            ],
        });
    } catch (error) {
        console.log(error.message);
    }
};

const handleNetworkSwitch = async (networkName) => {
    await changeNetwork({ networkName });
};

function NoContractAlert() {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const marketplaceCtx = useContext(MarketplaceContext);

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
    }

    const { t } = useTranslation(['noContractAlert']);

    return (
        <Modal
            status={isModalOpen}
            variant="modal-card-inner position-fixed"
            modalClose={closeModalHandler}
            layout={{ width: '700px', maxWidth: '100%' }}
            dismissable={false}
        >
            <div
                className="card-body text-center px-4 p-lg-5"
                style={
                    marketplaceCtx.themeMode === 'light'
                        ? { background: '#fff' }
                        : { background: '#141418' }
                }
            >
                <img
                    src="/images/metamask.png"
                    alt="metamask"
                    className="flex-shrink-0 mb-4"
                    width="65"
                />
                <div>
                    <h5 className="mb-1">
                        {t('switchNetwork', { ns: 'noContractAlert' })}{' '}
                        <span className="text-primary">
                            {settings.UISettings.usedNetworkName}
                        </span>
                    </h5>
                    <p className="text-muted mb-4">
                        {t('clickButton', { ns: 'noContractAlert' })}
                    </p>
                    <button
                        className="btn btn-primary"
                        onClick={() => handleNetworkSwitch(`bsctest`)}
                    >
                        {t('switchNetwork2', { ns: 'noContractAlert' })}
                    </button>
                </div>
                <div className="d-flex align-items-center justify-content-center mt-5">
                    <a
                        className="text-primary d-inline-block"
                        href="https://celo.org/developers/faucet"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('getFreeCelo', { ns: 'noContractAlert' })}
                    </a>
                    <i className="las la-arrow-right ms-1 mb-1 text-primary"></i>
                </div>
            </div>
        </Modal>
    );
}

export default NoContractAlert;
