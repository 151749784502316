import React, { useContext } from 'react';
import { motion } from 'framer-motion/dist/es/index';
import MarketplaceContext from '../../providers/marketplace-context';
// the hook
import { useTranslation } from 'react-i18next';

const fullScreenLoaderStyle = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    background: 'rgba(255, 255, 255, 0.97)',
    zIndex: '9999',
};
const fullScreenLoaderStyleDark = {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    top: '0',
    left: '0',
    background: 'rgba(28, 28, 34, 0.97)',
    zIndex: '9999',
};

function FullScreenLoader({ heading }) {
    const marketplaceCtx = useContext(MarketplaceContext);

    const { t } = useTranslation(['fullScreenLoader']);

    return (
        <motion.div
            className="d-flex align-items-center justify-content-center"
            style={
                marketplaceCtx.themeMode === 'light'
                    ? fullScreenLoaderStyle
                    : fullScreenLoaderStyleDark
            }
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.1 }}
        >
            <div className="row w-100">
                <div className="col-lg-6 mx-auto">
                    <div className="d-flex align-items-center justify-content-center">
                        <div className="me-3">
                            <div className="box-loader">
                                <div className="box-loader-inner"></div>
                            </div>
                        </div>
                        <div className="ms-1">
                            <p className="h3 text-uppercase mb-2">{heading}</p>
                            <p className="text-muted mb-0">
                                {t('metaMaskConnected', {
                                    ns: 'fullScreenLoader',
                                })}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </motion.div>
    );
}

export default FullScreenLoader;
