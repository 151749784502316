export const EXPLORE_INIT_NUMS = 6;
export const EXPLORE_EACH_PAGE_NUMS = 3;

export const AUCTIONS_INIT_NUMS = 6;
export const AUCTIONS_EACH_PAGE_NUMS = 3;

export const ACTIVITIES_NUMS = 300;

export const TRANSACTIONS_NUMS = 300;

export const CATEGORY_INIT_NUMS = 6;
export const CATEGORY_EACH_PAGE_NUMS = 3;

export const SEARCH_INIT_NUMS = 3;
export const SEARCH_EACH_PAGE_NUMS = 3;

export const USERPROFILE_COLLECTED_INIT_NUMS = 3;
export const USERPROFILE_CREATED_INIT_NUMS = 6;
export const USERPROFILE_COLLECTED_EACH_PAGE_NUMS = 3;
export const USERPROFILE_CREATED_EACH_PAGE_NUMS = 3;
