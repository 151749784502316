import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-dropdown-select';
import UserContext from '../../providers/user-context';
import Web3Context from '../../providers/web3-context';

// COMPONENTS
import MetaMaskLoader from '../general/MetaMaskLoader';
import WhiteListTable from '../tables/WhiteListTable';
import { GraphqlClient } from '../../helpers/graphqlClient';
import { authenticate } from '../../helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';
import { useSnackbar } from 'notistack';

function WhiteList({ usersList, whiteList, ownerFunds }) {
    const userCtx = useContext(UserContext);
    const web3Ctx = useContext(Web3Context);
    const [metaMaskOpened, setMetaMaskOpened] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [whiteListUsers, setWhiteListUsers] = useState([]);
    const [choosedAddress, setChoosedAddress] = useState('');
    const [whitelistAddress, setwhitelistAddress] = useState('');

    /*** =============================================== */
    //      SELECT ITEM TEMPLATE
    /*** =============================================== */
    const ListItem = ({ avatar, fullName, email }) => {
        return (
            <div className="d-flex align-items-center">
                <div className="author-avatar">
                    <span
                        className="author-avatar-inner"
                        style={{ background: `url(${avatar})` }}
                    ></span>
                </div>
                <div className="ms-3">
                    <p className="fw-bold text-base mb-0">{fullName}</p>
                    <p className="text-xxs mb-0">{email}</p>
                </div>
            </div>
        );
    };

    /*** =============================================== */
    //      GET USERS LIST SELECT OPTIONS
    /*** =============================================== */
    useEffect(() => {
        if (usersList && whiteList) {
            const users = usersList
                .filter((user) => {
                    return user.account !== userCtx.appOwner;
                })
                .filter(
                    (user) =>
                        !whiteList
                            .map((el) => el.account)
                            .includes(user.account)
                )
                .map((user) => {
                    return {
                        value: user.account,
                        label: <ListItem {...user} />,
                    };
                });
            setUsers(users);
        }
    }, [usersList, whiteList]);

    /*** =============================================== */
    //      GET WHITELISTED USERS SELECT OPTIONS
    /*** =============================================== */
    useEffect(() => {
        if (whiteList && usersList) {
            const users = whiteList
                .filter(
                    (user) =>
                        user.account !==
                        '0x0000000000000000000000000000000000000000'
                )
                .map((user) => {
                    let fullName = usersList.filter(
                        (el) => el.account === user.account
                    )[0].fullName;
                    let avatar = usersList.filter(
                        (el) => el.account === user.account
                    )[0].avatar;
                    let email = usersList.filter(
                        (el) => el.account === user.account
                    )[0].email;
                    return {
                        value: user.account,
                        account: user.account,
                        fullName: fullName,
                        avatar: avatar,
                        label: (
                            <ListItem
                                fullName={fullName}
                                email={email}
                                avatar={avatar}
                            />
                        ),
                    };
                });
            setWhiteListUsers(users);
        }
    }, [whiteList, usersList]);

    /*** =============================================== */

    //      ADD TO WHITELIST FUNCTION
    /*** =============================================== */
    async function addToWhiteListHandler(e) {
        e.preventDefault();

        if (choosedAddress !== '') {
            setLoading(true);
            setChoosedAddress('');

            try {
                let updateWhiteListRes = await GraphqlClient.updateWhiteList(
                    choosedAddress,
                    true
                );

                if (updateWhiteListRes === '401') {
                    // if failed, auth and generate token again
                    const generateNonceRes = await GraphqlClient.generateNonce(
                        web3Ctx.account
                    );
                    await authenticate(
                        generateNonceRes.lastNonce,
                        web3Ctx.account
                    );
                    await GraphqlClient.updateWhiteList(choosedAddress, true);
                }
                enqueueSnackbar(`Great! you have a new user to whitelist`, {
                    variant: 'success',
                });
            } catch (e) {
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
            }
            setChoosedAddress('');
            setLoading(false);
        }

        /*            userCtx.contract.methods
                .addToWhitelist(choosedAddress)
                .send({ from: web3Ctx.account })
                .once('sending', function (payload) {
                    setMetaMaskOpened(true);
                })
                .on('transactionHash', (hash) => {
                    setMetaMaskOpened(false);
                    addToast(`Great! you have a new user to whitelist`, {
                        appearance: 'success',
                    });
                })
                .on('receipt', (receipt) => {
                    setChoosedAddress('');
                    userCtx.loadWhiteListFromDB();
                    userCtx.loadActivity(userCtx.contract);
                })
                .on('error', (e) => {
                    addToast('Oops! an error occured', {
                        appearance: 'error',
                    });
                    setMetaMaskOpened(false);
                });*/
    }

    /*** =============================================== */

    //      REMOVE FROM WHITELIST FUNCTION
    /*** =============================================== */
    async function removeFromWhiteListHandler(e) {
        e.preventDefault();
        if (whitelistAddress !== '') {
            setLoading(true);
            try {
                let updateWhiteListRes = await GraphqlClient.updateWhiteList(
                    whitelistAddress,
                    false
                );

                if (updateWhiteListRes === '401') {
                    // if failed, auth and generate token again
                    const generateNonceRes = await GraphqlClient.generateNonce(
                        web3Ctx.account
                    );
                    await authenticate(
                        generateNonceRes.lastNonce,
                        web3Ctx.account
                    );
                    await GraphqlClient.updateWhiteList(
                        whitelistAddress,
                        false
                    );
                }
                enqueueSnackbar(`Great! you have removed user from whitelist`, {
                    variant: 'success',
                });
            } catch (e) {
                enqueueSnackbar('Oops! an error occured', {
                    variant: 'error',
                });
            }
            setwhitelistAddress('');
            setLoading(false);
        }

        /*           userCtx.contract.methods
                .removeFromWhitelist(whitelistAddress)
                .send({ from: web3Ctx.account })
                .once('sending', function (payload) {
                    setMetaMaskOpened(true);
                })
                .on('transactionHash', (hash) => {
                    setMetaMaskOpened(false);
                    addToast(`Great! you have a deleted a user from whitelist`, {
                        appearance: 'success',
                    });
                })
                .on('receipt', (receipt) => {
                    setwhitelistAddress('');
                    userCtx.loadWhiteListFromDB();
                    userCtx.loadActivity(userCtx.contract);
                })
                .on('error', (e) => {
                    addToast('Something went wrong when pushing to the blockchain', {
                        appearance: 'error',
                    });
                    setMetaMaskOpened(false);
                });*/
    }

    return (
        <>
            {metaMaskOpened ? <MetaMaskLoader /> : null}
            <div className="row gy-4">
                <div
                    className="col-lg-6 z-index-40"
                    data-aos="fade-right"
                    data-aos-delay="100"
                >
                    <div className="card shadow-0 p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">Add to WhiteList</h5>

                            <form onSubmit={addToWhiteListHandler}>
                                {users && (
                                    <>
                                        <Select
                                            options={users}
                                            className="form-select border-gray-300 ps-3 shadow-0 bg-white"
                                            value={choosedAddress}
                                            onChange={(values) => {
                                                setChoosedAddress(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                );
                                            }}
                                        />

                                        <button
                                            className="btn btn-primary w-100 mt-3"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading && (
                                                <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    className="fa-spin"
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                ></FontAwesomeIcon>
                                            )}
                                            Add to WhiteList
                                        </button>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    className="col-lg-6 z-index-30"
                    data-aos="fade-left"
                    data-aos-delay="200"
                >
                    <div className="card shadow-0 p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">Remove from WhiteList</h5>

                            <form onSubmit={removeFromWhiteListHandler}>
                                {whiteListUsers && (
                                    <>
                                        <Select
                                            searchable={false}
                                            options={whiteListUsers}
                                            className="form-select border-gray-300 ps-3 shadow-0 bg-white"
                                            value={whitelistAddress}
                                            onChange={(values) =>
                                                setwhitelistAddress(
                                                    values
                                                        .map((el) => el.value)
                                                        .toString()
                                                )
                                            }
                                        />

                                        <button
                                            className="btn btn-primary w-100 mt-3"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading && (
                                                <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    className="fa-spin"
                                                    style={{
                                                        marginRight: '1rem',
                                                    }}
                                                ></FontAwesomeIcon>
                                            )}
                                            Remove from WhiteList
                                        </button>
                                    </>
                                )}
                            </form>
                        </div>
                    </div>
                </div>

                <div
                    className="col-lg-12 z-index-20"
                    data-aos="fade-up"
                    data-aos-delay="300"
                >
                    <div className="card shadow-0 p-lg-3">
                        <div className="card-body p-4">
                            <h5 className="mb-4">WhiteList Users</h5>
                            <WhiteListTable
                                whiteListUsers={whiteListUsers}
                                ownerFunds={ownerFunds}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default WhiteList;
