/*** =============================================== */
//      GRAPHQL
import { gql } from '@apollo/client';
import { apolloClient } from '../index';
import { authenticate as auth, getTokenFromStorage } from './utils';

/*** =============================================== */
const GET_USER = gql`
    query ($where: GetUserInput!) {
        user(where: $where) {
            account
        }
    }
`;

const GET_USER_BASIC = gql`
    query ($where: GetUserInput!) {
        user(where: $where) {
            account
            fullName
            avatar
        }
    }
`;

const GET_USER_INFO = gql`
    query ($where: GetUserInput!) {
        user(where: $where) {
            account
            email
            web
            fullName
            avatar
            facebook
            twitter
            discord
            instagram
            header
            about
            inWhiteList
            admin
        }
    }
`;

const GET_USERS_BY_ACCOUNTS = gql`
    query ($accounts: [String!]!) {
        usersInfo(accounts: $accounts) {
            account
            fullName
            avatar
        }
    }
`;

const GET_USERS_BY_NAME = gql`
    query ($name: String!) {
        getUsersByName(name: $name) {
            account
            fullName
            avatar
        }
    }
`;

const ALL_USERS = gql`
    query {
        usersList {
            account
            email
            web
            fullName
            avatar
            facebook
            twitter
            discord
            instagram
            header
            about
        }
    }
`;

const GET_WHITELIST = gql`
    query {
        whiteList {
            account
        }
    }
`;

const ADD_USER = gql`
    mutation ($data: NewUserInput!) {
        addUser(data: $data) {
            account
            lastNonce
        }
    }
`;

const UPDATE_USER = gql`
    mutation ($where: UpdateUserWhereInput!, $data: UpdateUserDataInput!) {
        updateUser(where: $where, data: $data) {
            account
        }
    }
`;
const UPDATE_WHITELIST = gql`
    mutation ($account: String!, $inWhiteList: Boolean!) {
        updateWhiteList(account: $account, inWhiteList: $inWhiteList) {
            account
            inWhiteList
        }
    }
`;

const REQUEST_TOKEN = gql`
    mutation ($account: String!) {
        requestToken(account: $account) {
            access_token
        }
    }
`;

const GENERATE_NONCE = gql`
    mutation ($account: String!) {
        generateNonce(account: $account) {
            account
            lastNonce
        }
    }
`;

const AUTHENTICATE = gql`
    mutation ($account: String!, $signature: String!) {
        authenticate(account: $account, signature: $signature) {
            access_token
        }
    }
`;

const CREATE_NFT = gql`
    mutation ($data: NewNftInput!) {
        createNft(data: $data) {
            id
        }
    }
`;

const UPDATE_NFT = gql`
    mutation ($where: UpdateNftWhereInput!, $data: UpdateNftDataInput!) {
        updateNft(where: $where, data: $data) {
            id
        }
    }
`;

const DELETE_NFT = gql`
    mutation ($where: DeleteNftInput!) {
        deleteNft(where: $where) {
            id
        }
    }
`;

const GET_NFT_BY_ID = gql`
    query ($where: GetNftByIdInput!) {
        getNftById(where: $where) {
            id
            file
        }
    }
`;

const GET_NFT_INFO_BY_ID = gql`
    query ($where: GetNftByIdInput!) {
        getNftById(where: $where) {
            id
            file
            title
            thumbnail
            description
            category
            dateCreated
            royalties
            unlockable
            type
            formate
            owner
            dateCreated
        }
    }
`;

const GET_DRAFT_NFTS_BY_CATEGORY = gql`
    query ($paging: PagingArgs!, $category: String!) {
        getNftsByCategory(paging: $paging, category: $category) {
            id
            file
            title
            thumbnail
            description
            category
            dateCreated
            royalties
            unlockable
            type
            formate
            owner
        }
    }
`;

const GET_NFTS_BY_TITLE = gql`
    query ($paging: PagingArgs!, $title: String!) {
        getNftsByTitle(paging: $paging, title: $title) {
            title
            file
        }
    }
`;

const GET_NFTS_BY_ACCOUNT = gql`
    query ($where: GetNftByAccountInput!) {
        getDraftNftsByAccount(where: $where) {
            id
            file
            title
            thumbnail
            description
            category
            dateCreated
            royalties
            unlockable
            type
            formate
            owner
        }
    }
`;

const GET_FILE_URL_TO_WRITE = gql`
    query ($fileInfo: GetFileLinkInput!, $isThumbnail: Boolean!) {
        getFileUrlToWrite(fileInfo: $fileInfo, isThumbnail: $isThumbnail)
    }
`;

const GET_AVATAR_URL_TO_WRITE = gql`
    query ($avatarInfo: GetFileLinkInput!) {
        getAvatarUrlToWrite(avatarInfo: $avatarInfo)
    }
`;

const GET_HEADER_URL_TO_WRITE = gql`
    query ($headerInfo: GetFileLinkInput!) {
        getHeaderUrlToWrite(headerInfo: $headerInfo)
    }
`;

const GET_FILE_URL_TO_READ = gql`
    query ($id: String!, $isThumbnail: Boolean!) {
        getFileUrlToRead(id: $id, isThumbnail: $isThumbnail)
    }
`;

const DELETE_FILE_FROM_STORAGE = gql`
    query ($id: String!, $isThumbnail: Boolean!) {
        deleteFileFromStorage(id: $id, isThumbnail: $isThumbnail)
    }
`;

const DELETE_AVATAR_FROM_STORAGE = gql`
    query ($account: String!) {
        deleteAvatarFromStorage(account: $account)
    }
`;

const DELETE_HEADER_FROM_STORAGE = gql`
    query ($account: String!) {
        deleteHeaderFromStorage(account: $account)
    }
`;

const getUser = async (account) => {
    let oResult;
    try {
        const res = await apolloClient.query({
            query: GET_USER,
            variables: {
                where: { account },
            },
        });
        if (res.data) {
            oResult = res.data.user;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            e.graphQLErrors[0].extensions.response &&
            e.graphQLErrors[0].extensions.response.statusCode === 404
        ) {
            oResult = '404';
        } else {
            throw new Error(e);
        }
    }
    return oResult;
};

const getUserBasic = async (account) => {
    let oResult;
    try {
        const res = await apolloClient.query({
            query: GET_USER_BASIC,
            variables: {
                where: { account },
            },
        });
        if (res.data) {
            oResult = res.data.user;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            e.graphQLErrors[0].extensions.response &&
            e.graphQLErrors[0].extensions.response.statusCode === 404
        ) {
            oResult = '404';
        } else {
            throw new Error(e);
        }
    }
    return oResult;
};

const getUserInfo = async (account) => {
    let oResult;
    try {
        const res = await apolloClient.query({
            query: GET_USER_INFO,
            variables: {
                where: { account },
            },
            fetchPolicy: 'network-only',
        });
        if (res.data) {
            oResult = res.data.user;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            e.graphQLErrors[0].extensions.response &&
            e.graphQLErrors[0].extensions.response.statusCode === 404
        ) {
            oResult = '404';
        } else {
            throw new Error(e);
        }
    }
    return oResult;
};

const getUsersList = async (account) => {
    let oResult;
    const res = await apolloClient.query({
        query: ALL_USERS,
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data.usersList;
    }
    return oResult;
};

const getUsersByName = async (name) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_USERS_BY_NAME,
        variables: {
            name,
        },
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data.getUsersByName;
    }
    return oResult;
};

const getUsersByAccounts = async (accounts) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_USERS_BY_ACCOUNTS,
        variables: {
            accounts,
        },
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data.usersInfo;
    }
    return oResult;
};

const getWhiteList = async (account) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_WHITELIST,
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data.whiteList;
    }
    return oResult;
};

const createUser = async (account) => {
    let oResult;
    const res = await apolloClient.mutate({
        mutation: ADD_USER,
        variables: {
            data: { account },
        },
    });
    if (res.data) {
        oResult = res.data.addUser;
    }

    return oResult;
};

const generateNonce = async (account) => {
    let oResult;
    const res = await apolloClient.mutate({
        mutation: GENERATE_NONCE,
        variables: {
            account: account,
        },
    });
    if (res.data) {
        oResult = res.data.generateNonce;
    }
    return oResult;
};

const updateWhiteList = async (account, inWhiteList) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.mutate({
            mutation: UPDATE_WHITELIST,
            variables: {
                account,
                inWhiteList,
            },
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });

        if (res.data) {
            oResult = res.data.updateWhiteList;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            oResult = '401';
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const updateUser = async (account, updates) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.mutate({
            mutation: UPDATE_USER,
            variables: {
                where: { account },
                data: updates,
            },
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });

        if (res.data) {
            oResult = res.data.updateUser;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await updateUser(account, updates);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const requestToken = async (account) => {
    let oResult;
    const res = await apolloClient.mutate({
        mutation: REQUEST_TOKEN,
        variables: {
            account: account,
        },
    });
    if (res.data) {
        oResult = res.data.requestToken;
    }

    return oResult;
};

const authenticate = async (account, signature) => {
    let oResult;
    const res = await apolloClient.mutate({
        mutation: AUTHENTICATE,
        variables: {
            account: account,
            signature: signature,
        },
    });
    if (res.data) {
        oResult = res.data.authenticate;
    }
    return oResult;
};

const createNft = async (nftItem) => {
    let oResult;
    const res = await apolloClient.mutate({
        mutation: CREATE_NFT,
        variables: {
            data: nftItem,
        },
    });
    if (res.data) {
        oResult = res.data.createNft;
    }

    return oResult;
};

const deleteNft = async (id) => {
    let oResult = false;
    const res = await apolloClient.mutate({
        mutation: DELETE_NFT,
        variables: {
            where: { id },
        },
    });
    if (res.data.deleteNft) {
        oResult = true;
    }

    return oResult;
};

const updateNft = async (account, id, nftItem) => {
    let oResult;
    try {
        const token = localStorage.getItem('token');
        const res = await apolloClient.mutate({
            mutation: UPDATE_NFT,
            variables: {
                where: { id },
                data: nftItem,
            },
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = res.data.updateNft;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await getFileUrlToWrite(account, id, nftItem);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const getDraftNftsByAccount = async (owner) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_NFTS_BY_ACCOUNT,
        variables: {
            where: { owner },
        },
        fetchPolicy: 'network-only',
    });
    if (res.data) {
        oResult = res.data.getDraftNftsByAccount;
    }

    return oResult;
};

const getNftById = async (id) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_NFT_BY_ID,
        variables: {
            where: { id },
        },
    });
    if (res.data) {
        oResult = res.data.getNftById;
    }

    return oResult;
};

const getNftInfoById = async (id) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_NFT_INFO_BY_ID,
        variables: {
            where: { id },
        },
    });
    if (res.data) {
        oResult = res.data.getNftById;
    }

    return oResult;
};

const getNftsByCategory = async (paging, category) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_DRAFT_NFTS_BY_CATEGORY,
        variables: {
            paging,
            category,
        },
    });
    if (res.data) {
        oResult = res.data.getNftsByCategory;
    }
    return oResult;
};

const getNftsByTitle = async (paging, title) => {
    let oResult;
    const res = await apolloClient.query({
        query: GET_NFTS_BY_TITLE,
        variables: {
            paging,
            title,
        },
    });
    if (res.data) {
        oResult = res.data.getNftsByTitle;
    }
    return oResult;
};

const getFileUrlToWrite = async (account, fileInfo, isThumbnail) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: GET_FILE_URL_TO_WRITE,
            variables: {
                fileInfo,
                isThumbnail,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = {
                fileLink: res.data.getFileUrlToWrite[0],
                signedUrl: res.data.getFileUrlToWrite[1],
            };
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await getFileUrlToWrite(account, fileInfo, isThumbnail);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const getAvatarUrlToWrite = async (account, avatarInfo) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: GET_AVATAR_URL_TO_WRITE,
            variables: {
                avatarInfo,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = {
                fileLink: res.data.getAvatarUrlToWrite[0],
                signedUrl: res.data.getAvatarUrlToWrite[1],
            };
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await getAvatarUrlToWrite(account, avatarInfo);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const getHeaderUrlToWrite = async (account, headerInfo) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: GET_HEADER_URL_TO_WRITE,
            variables: {
                headerInfo,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = {
                fileLink: res.data.getHeaderUrlToWrite[0],
                signedUrl: res.data.getHeaderUrlToWrite[1],
            };
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await getHeaderUrlToWrite(account, headerInfo);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const getFileUrlToRead = async (account, id, isThumbnail) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: GET_FILE_URL_TO_READ,
            variables: {
                id,
                isThumbnail,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = res.data.getFileUrlToRead;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await getFileUrlToRead(account, id, isThumbnail);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const deleteFileFromStorage = async (account, id, isThumbnail) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: DELETE_FILE_FROM_STORAGE,
            variables: {
                id,
                isThumbnail,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = res.data.deleteFileFromStorage;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await deleteFileFromStorage(account, id, isThumbnail);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const deleteAvatarFromStorage = async (account) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: DELETE_AVATAR_FROM_STORAGE,
            variables: {
                account,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = res.data.deleteAvatarFromStorage;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await deleteAvatarFromStorage(account);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

const deleteHeaderFromStorage = async (account) => {
    let oResult;
    try {
        const token = getTokenFromStorage();
        const res = await apolloClient.query({
            query: DELETE_HEADER_FROM_STORAGE,
            variables: {
                account,
            },
            fetchPolicy: 'network-only',
            context: {
                headers: {
                    authorization: `Bearer ${token}`,
                },
            },
        });
        if (res.data) {
            oResult = res.data.deleteHeaderFromStorage;
        }
    } catch (e) {
        if (
            e.graphQLErrors.length > 0 &&
            ((e.graphQLErrors[0].extensions.response &&
                e.graphQLErrors[0].extensions.response.statusCode === 401) ||
                (e.graphQLErrors[0].extensions.exception &&
                    e.graphQLErrors[0].extensions.exception.status === 401))
        ) {
            // if failed, auth and generate token again
            const generateNonceRes = await generateNonce(account);
            await auth(generateNonceRes.lastNonce, account);
            oResult = await deleteHeaderFromStorage(account);
        } else {
            throw new Error(e);
        }
    }

    return oResult;
};

export const GraphqlClient = {
    getUser: getUser,
    getUserBasic: getUserBasic,
    getUserInfo: getUserInfo,
    getUsersList: getUsersList,
    getWhiteList: getWhiteList,
    createUser: createUser,
    generateNonce: generateNonce,
    requestToken: requestToken,
    updateUser: updateUser,
    authenticate: authenticate,
    updateWhiteList: updateWhiteList,
    createNft: createNft,
    updateNft: updateNft,
    getDraftNftsByAccount: getDraftNftsByAccount,
    getNftById: getNftById,
    getNftsByTitle: getNftsByTitle,
    getNftInfoById: getNftInfoById,
    getNftsByCategory: getNftsByCategory,
    deleteNft: deleteNft,
    getFileUrlToWrite: getFileUrlToWrite,
    getFileUrlToRead: getFileUrlToRead,
    deleteFileFromStorage: deleteFileFromStorage,
    deleteAvatarFromStorage: deleteAvatarFromStorage,
    deleteHeaderFromStorage: deleteHeaderFromStorage,
    getAvatarUrlToWrite: getAvatarUrlToWrite,
    getHeaderUrlToWrite: getHeaderUrlToWrite,
    getUsersByAccounts: getUsersByAccounts,
    getUsersByName: getUsersByName,
};
