import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Container from '@mui/material/Container';

import PgaeBanner from '../components/general/PageBanner';
import TermsOfServiceEn from '../helpDoc/termsOfServiceEn.md';
import TermsOfServiceCn from '../helpDoc/termsOfServiceCn.md';
import { useTranslation } from 'react-i18next';

function TermsService() {
    const { t } = useTranslation(['mdfiles']);

    const [tosFileEn, setTermsOfServiceTextEn] = useState('');
    const [tosFileCn, setTermsOfServiceTextCn] = useState('');
    // tosFileEn，tosFileEn is rendered in <ReactMarkdown>, defined by src/locales/mdfiles.js

    useEffect(() => {
        fetch(TermsOfServiceEn)
            .then((res) => res.text())
            .then((text) => setTermsOfServiceTextEn(text));
        fetch(TermsOfServiceCn)
            .then((res) => res.text())
            .then((text) => setTermsOfServiceTextCn(text));
    }, []);
    //<section className="py-5 md_margin"></section>
    return (
        <>
            <PgaeBanner heading={t('tos', { ns: 'mdfiles' })} />
            <Container maxWidth="lg">
                <ReactMarkdown
                    children={eval(t('tosfile', { ns: 'mdfiles' }))}
                ></ReactMarkdown>
            </Container>
        </>
    );
}

export default TermsService;
