import React, { useContext, useEffect, useState } from 'react';
import AuctionContext from '../../providers/auction-context';
import UserContext from '../../providers/user-context';
import Web3Context from '../../providers/web3-context';
import DataTable, { createTheme } from 'react-data-table-component';
import { formatFullDate, formatPrice } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../../helpers/theGraphClient';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

createTheme(
    'solarized',
    {
        background: {
            default: '#1c1c22',
        },
    },
    'dark'
);

function UserBidsTable() {
    const history = useHistory();
    const auctionCtx = useContext(AuctionContext);
    const web3Ctx = useContext(Web3Context);
    const userCtx = useContext(UserContext);
    const [activeBids, setActiveBids] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['nftItem']);
    /*** =============================================== */
    //      GET USER BIDS
    /*** =============================================== */
    useEffect(() => {
        if (auctionCtx.contract) {
            auctionCtx.loadUserBids(auctionCtx.contract, web3Ctx.account);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auctionCtx.contract, web3Ctx.account]);

    useEffect(() => {
        const loadBids = async () => {
            const bids = await getBids(web3Ctx.account.toLowerCase());
            const constructedBids = await Promise.all(constructBids(bids));
            setActiveBids(constructedBids);
        };

        if (web3Ctx.account) {
            loadBids();
        }
    }, [web3Ctx.account]);

    const getBids = async (user) => {
        const GET_BIDS = gql`
            query bids($user: String) {
                bids(
                    where: {
                        bidder: $user
                        amount_not: 0
                        withdraw: false
                        auction_: { active: true }
                    }
                ) {
                    token {
                        id
                        uri
                    }
                    auction {
                        id
                        active
                    }
                    bidder {
                        id
                    }
                    withdraw
                    amount
                    time
                }
            }
        `;
        return await theGraphGraphqlClient.runQueryWithoutPaging(
            GET_BIDS,
            'bids',
            { user }
        );
    };

    const constructBids = (bids) => {
        const result = bids.map(async (bid) => {
            const token = bid.token;
            const hash = bid.token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }

                const metadata = await response.json();
                let extendedToken;
                // check if token on auction
                extendedToken = {
                    tokenId: token.id,
                    amount: parseInt(bid.amount),
                    bidTime: parseInt(bid.time) * 1000,
                    withdraw: bid.withdraw,
                    auction: {
                        auctionId: bid.auction.id,
                        file: metadata.properties.file.description,
                        title: metadata.properties.name.description,
                    },
                };

                return extendedToken;
            } catch (e) {
                console.log('an NFT was blocked', e);
            }
        });
        return result;
    };
    /*** =============================================== */
    //      WITHDRAW BID FUNCTION
    /*** =============================================== */
    const withdrawBidHandler = (event, tokenId, auctionId) => {
        event.preventDefault();

        auctionCtx.contract.methods
            .withdraw(tokenId, auctionId)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    history.go(0);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar(t('error', { ns: 'nftItem' }), {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      TABLE COLUMNS
    /*** =============================================== */
    const columns = [
        {
            name: 'Auction',
            selector: (row) => row.tokenId,
            cell: (row) => (
                <div row={row}>
                    {row.auction && (
                        <div className="d-flex align-items-center">
                            <div className="author-avatar rounded-xl">
                                <span
                                    className="author-avatar-inner rounded-xl"
                                    style={{
                                        background: `url(${process.env.REACT_APP_IPFS_GATEWAY}${row.auction.file})`,
                                    }}
                                ></span>
                            </div>
                            <p className="fw-bold mb-0 ms-3">
                                {row.auction.title}
                            </p>
                        </div>
                    )}
                </div>
            ),
        },
        {
            name: 'Bid Time',
            selector: (row) => row.bidTime,
            cell: (row) => (
                <div row={row}>
                    <p className="mb-0 fw-bold">
                        {formatFullDate(row.bidTime, i18next.language)}
                    </p>
                </div>
            ),
        },
        {
            name: 'Bid Amount',
            selector: (row) => row.amount,
            cell: (row) => (
                <div row={row}>
                    <p className="mb-0 fw-bold">
                        {formatPrice(row.amount).toFixed(3)} {settings.currency}
                    </p>
                </div>
            ),
        },
        {
            name: 'Action',
            selector: (row) => row.tokenId,
            cell: (row) => (
                <div row={row}>
                    {row.auction && (
                        <button
                            className="btn btn-danger btn-sm pt-2"
                            type="button"
                            onClick={(e) =>
                                withdrawBidHandler(
                                    e,
                                    row.tokenId,
                                    row.auction.auctionId
                                )
                            }
                        >
                            {t('withdraw', { ns: 'nftItem' })}
                        </button>
                    )}
                </div>
            ),
        },
    ];

    return (
        activeBids.length > 0 && (
            <>
                <h3 className="h3 mb-4 text-center">My Bids</h3>
                <DataTable
                    columns={columns}
                    data={activeBids}
                    pagination={activeBids.length >= 10 && true}
                    responsive
                    theme="solarized"
                />
            </>
        )
    );
}

export default UserBidsTable;
