import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import MarketplaceContext from '../../providers/marketplace-context';
import { useTranslation } from 'react-i18next';
// COMPONENTS
import Loader from '../general/Loader';

function ItemAuthor({
    history,
    owner,
    creator,
    ownerName,
    ownerAvatar,
    fromDB,
}) {
    const marketplaceCtx = useContext(MarketplaceContext);
    const { t } = useTranslation(['itemInfoPanel']);
    if (!history && !fromDB) return <Loader />;

    return (
        <div className="row">
            <div className="col-xl-8">
                <ul className="list-inline d-flex align-items-lg-center flex-column flex-lg-row">
                    <li className="list-inline-item flex-shrink-0 me-4 mb-4 mb-lg-0">
                        <h6 className="mb-3">
                            {t('creater', { ns: 'itemInfoPanel' })}
                        </h6>
                        <div
                            className="d-flex align-items-center py-2 ps-2 pe-4 rounded-pill bg-white"
                            style={{
                                border:
                                    marketplaceCtx.themeMode === 'light'
                                        ? '3px solid #e9ecef'
                                        : '3px solid #282830',
                            }}
                        >
                            <Link
                                className="text-reset"
                                to={`/users/${creator.account}`}
                            >
                                <div className="author-avatar">
                                    <span
                                        className="author-avatar-inner"
                                        style={{
                                            background: `url(${
                                                creator.avatar !== ''
                                                    ? creator.avatar
                                                    : '/images/astronaut.png'
                                            })`,
                                        }}
                                    ></span>
                                </div>
                            </Link>
                            <Link
                                className="text-reset"
                                to={`/users/${creator.account}`}
                            >
                                <p className="ms-2 mb-0 text-gray-800 fw-bold">
                                    {creator.name !== ''
                                        ? creator.name
                                        : 'Adi Gallia'}
                                </p>
                            </Link>
                        </div>
                    </li>
                    <li className="list-inline-item flex-shrink-0">
                        <h6 className="mb-3">
                            {t('owner', { ns: 'itemInfoPanel' })}
                        </h6>

                        <div
                            className="d-flex align-items-center py-2 ps-2 pe-4 rounded-pill bg-white"
                            style={{
                                border:
                                    marketplaceCtx.themeMode === 'light'
                                        ? '3px solid #e9ecef'
                                        : '3px solid #282830',
                            }}
                        >
                            {ownerAvatar ? (
                                <Link
                                    className="text-reset"
                                    to={`/users/${owner}`}
                                >
                                    <div className="author-avatar">
                                        <span
                                            className="author-avatar-inner"
                                            style={{
                                                background: `url(${ownerAvatar})`,
                                            }}
                                        ></span>
                                    </div>
                                </Link>
                            ) : (
                                <Link
                                    className="text-reset"
                                    to={`/users/${owner}`}
                                >
                                    <div className="author-avatar">
                                        <span
                                            className="author-avatar-inner"
                                            style={{
                                                background: `url(/images/astronaut.png)`,
                                            }}
                                        ></span>
                                    </div>
                                </Link>
                            )}

                            <Link className="text-reset" to={`/users/${owner}`}>
                                <p className="ms-2 mb-0 text-gray-800 fw-bold">
                                    {ownerName}
                                </p>
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default ItemAuthor;
