import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PageBanner from '../components/general/PageBanner';
import AuctionItem from '../components/general/AuctionItem';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from '../helpers/theGraphClient';
import { AUCTIONS_EACH_PAGE_NUMS, AUCTIONS_INIT_NUMS } from '../helpers/pages';
import InfiniteScroll from 'react-infinite-scroller';
import { GraphqlClient } from '../helpers/graphqlClient';
import FullScreenLoader from '../components/general/FullScreenLoader';
import MetaMaskLoader from '../components/general/MetaMaskLoader';
import CollectionContext from '../providers/collection-context';
import AuctionContext from '../providers/auction-context';
import NftItemSkeleton from '../components/Skeletons/NftItemSkeleton/NftItemSkeleton';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

function Auctions() {
    const collectionCtx = useContext(CollectionContext);
    const auctionCtx = useContext(AuctionContext);

    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [items, setItems] = useState([]);

    const { t } = useTranslation(['auction']);

    useEffect(() => {
        const loadAuctions = async () => {
            const auctions = await getAuctions({
                skip: 0,
                first: AUCTIONS_INIT_NUMS,
            });

            const creators = [
                ...new Set(auctions.map((auction) => auction.creator.id)),
            ];
            const users = await GraphqlClient.getUsersByAccounts(creators);

            const extendedAuctions = await Promise.all(
                constructAuctions(auctions, users)
            );

            setItems(extendedAuctions);
            setHasMore(auctions.length === AUCTIONS_INIT_NUMS);
        };

        loadAuctions()
            .catch(console.error)
            .finally(() => {
                setIsLoading(false);
            });
    }, []);

    const getAuctions = async (paging) => {
        const GET_AUCTIONS = gql`
            query auctions($skip: Int, $first: Int) {
                auctions(skip: $skip, first: $first, where: { active: true }) {
                    id
                    token {
                        id
                        uri
                        owner {
                            id
                        }
                    }
                    creator {
                        id
                    }
                    active
                    cancel
                    endTime
                    bids {
                        bidder {
                            id
                        }
                        withdraw
                        amount
                        time
                    }
                }
            }
        `;
        return await theGraphGraphqlClient.runQuery(
            GET_AUCTIONS,
            'auctions',
            paging
        );
    };

    const constructAuctions = (auctions, users) => {
        const result = auctions.map(async (auction) => {
            const user = users.filter(
                (el) => el.account === auction.creator.id
            )[0];

            const hash = auction.token.uri;
            try {
                const response =
                    hash &&
                    hash !== '' &&
                    (await fetch(
                        `${process.env.REACT_APP_IPFS_GATEWAY}${hash}?clear`
                    ));
                if (!response.ok) {
                    console.log('IPFS call has an error');
                }
                let bids = auction.bids.map((bid) => {
                    return {
                        bidder: bid.bidder.id,
                        amount: parseInt(bid.amount),
                        bidTime: parseInt(bid.time),
                        withdraw: bid.withdraw,
                    };
                });
                const metadata = await response.json();

                let auctionData = {
                    tokenId: auction.token.id,
                    auctionId: auction.id,
                    title: metadata.properties.name.description,
                    img: metadata.properties.file.description,
                    description: metadata.properties.description.description,
                    category: metadata.properties.category.description,
                    dateCreated: metadata.properties.dateCreated.description,
                    royalties: metadata.properties.royalties.description,
                    type: metadata.properties.type.description,
                    formate: metadata.properties.formate.description,
                    unlockable: metadata.properties.unlockable.description,
                    endAt: parseInt(auction.endTime),
                    bids,
                    owner: auction.token.owner.id,
                    cancelled: auction.cancel,
                    active: auction.active,
                    user: auction.creator.id,
                    ownerName: user.fullName,
                    ownerAvatar: user.avatar,
                };

                return auctionData;
            } catch {
                console.log('an Auction was blocked');
            }
        });
        return result;
    };

    const loadMore = async () => {
        const auctions = await getAuctions({
            skip: items.length,
            first: AUCTIONS_EACH_PAGE_NUMS,
        });
        const creators = [
            ...new Set(auctions.map((auction) => auction.creator.id)),
        ];
        const users = await GraphqlClient.getUsersByAccounts(creators);

        const extendedAuctions = await Promise.all(
            constructAuctions(auctions, users)
        );
        setItems([...items, ...extendedAuctions]);
        setHasMore(auctions.length === AUCTIONS_EACH_PAGE_NUMS);
    };

    return (
        <>
            {auctionCtx.fetchingLoading ? (
                <FullScreenLoader heading={t('update', { ns: 'auction' })} />
            ) : null}
            {collectionCtx.nftIsLoading ? (
                <FullScreenLoader heading={t('fetching', { ns: 'auction' })} />
            ) : null}
            {auctionCtx.auctionTransactionLoading ? <MetaMaskLoader /> : null}
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                initialLoad={false}
            >
                <PageBanner heading={t('liveAuctions', { ns: 'auction' })} />
                <section className="pb-5">
                    <div className="container py-5">
                        <header className="mb-4">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h2 data-aos="fade-up" data-aos-delay="100">
                                        {t('allAuctions', { ns: 'auction' })}
                                    </h2>
                                    <p
                                        className="lead text-muted"
                                        data-aos="fade-up"
                                        data-aos-delay="200"
                                    >
                                        {t('placeBid', { ns: 'auction' })}
                                    </p>
                                </div>
                            </div>
                        </header>

                        {isLoading ? (
                            <Stack
                                direction={{ sm: 'column', md: 'row' }}
                                width={'100%'}
                                spacing={2}
                            >
                                <NftItemSkeleton />
                                <NftItemSkeleton />
                                <NftItemSkeleton />
                            </Stack>
                        ) : items.length === 0 ? (
                            <>
                                <h4>{t('noAuction', { ns: 'auction' })}</h4>
                                <p className="text-muted mb-3">
                                    {t('onceAuction', { ns: 'auction' })}
                                </p>
                                <Link
                                    className="btn btn-gradient-primary"
                                    to="/"
                                >
                                    {t('return', { ns: 'auction' })}
                                </Link>
                            </>
                        ) : (
                            <div className="row gy-4 mb-5 align-items-stretch">
                                {items.map((AUC, key) => {
                                    return (
                                        <div
                                            className={`col-xl-4 col-md-6 ${AUC.category}`}
                                            key={AUC.tokenId}
                                        >
                                            <AuctionItem
                                                {...AUC}
                                                nftKey={key}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                </section>
            </InfiniteScroll>
        </>
    );
}

export default Auctions;
