import { fetchClient } from './fetchClient';
import { GraphqlClient } from './graphqlClient';
import { gql } from '@apollo/client';
import { theGraphGraphqlClient } from './theGraphClient';

// IPFS CREATE HOST
const ipfsClient = require('ipfs-http-client');
const ipfs = ipfsClient.create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization:
            'Basic ' +
            Buffer.from(
                process.env.REACT_APP_INFURA_PROJECT_ID +
                    ':' +
                    process.env.REACT_APP_INFURA_PROJECT_SECRET
            ).toString('base64'),
    },
});

/**
 * upload file to IPFS and push to the blockchain
 * @param web3Ctx
 * @param collectionCtx
 * @param userCtx
 * @param openMetaMaskLoader
 * @param closeMetaMaskLoader
 * @param enqueueSnackbar
 * @param deleteNftFromDB
 * @param successMint
 * @param history
 * @param id
 * @param title
 * @param description
 * @param category
 * @param royalties
 * @param unlockable
 * @param type
 * @param formate
 * @returns {Promise<void>}
 */
const mintNft = async ({
    web3Ctx,
    collectionCtx,
    userCtx,
    openMetaMaskLoader,
    closeMetaMaskLoader,
    enqueueSnackbar,
    deleteNftFromDB,
    successMint,
    history,
    id,
    title,
    description,
    category,
    royalties,
    unlockable,
    type,
    formate,
}) => {
    let metadata = {
        title: 'Asset Metadata',
        type: 'object',
        properties: {},
    };
    openMetaMaskLoader();

    let properties = {};
    const fileLink = await GraphqlClient.getFileUrlToRead(
        web3Ctx.account,
        id,
        false
    );
    const thumbnailLink = await GraphqlClient.getFileUrlToRead(
        web3Ctx.account,
        id,
        true
    );
    // Read File from Backend
    const addFile = async (fileLink, isThumbnail) => {
        const fileStream = await fetchClient.readFileFromStorage(fileLink);
        const file = new File([fileStream], isThumbnail ? 'thumbnail' : 'file');
        // Add file to the IPFS
        const fileAdded = await ipfs.add(file);
        return fileAdded;
    };

    const [fileAdded, thumbnailAdded] = await Promise.all([
        addFile(fileLink, false),
        addFile(thumbnailLink, true),
    ]);

    if (!fileAdded) {
        console.error('Something went wrong when uploading the file');
        return;
    }
    if (!thumbnailAdded) {
        console.error('Something went wrong when updloading the thumbnail');
        return;
    }

    properties = {
        thumbnail: {
            type: 'string',
            description: thumbnailAdded.path,
        },
    };

    const today = new Date();

    Object.assign(properties, {
        name: {
            type: 'string',
            description: title,
        },
        description: {
            type: 'string',
            description: description,
        },
        file: {
            type: 'string',
            description: fileAdded.path,
        },
        category: {
            type: 'string',
            description: category,
        },
        royalties: {
            type: 'string',
            description: royalties,
        },
        unlockable: {
            type: 'string',
            description: unlockable,
        },
        dateCreated: {
            type: 'string',
            description: today,
        },
        type: {
            type: 'string',
            description: type,
        },
        formate: {
            type: 'string',
            description: formate,
        },
    });

    metadata.properties = properties;

    const metadataAdded = await ipfs.add(JSON.stringify(metadata));
    if (!metadataAdded) {
        console.error('Something went wrong when updloading the file');
        return;
    }

    /*** =============================================== */
    //      MINTING NFT
    /*** =============================================== */
    collectionCtx.contract.methods
        .MintNFT(metadataAdded.path, parseInt(royalties), category)
        .send({ from: web3Ctx.account })
        .on('transactionHash', (hash) => {
            // openMetaMaskLoader();
        })
        .once('sending', () => {
            openMetaMaskLoader();
        })
        .on('error', (e) => {
            enqueueSnackbar(
                'Something went wrong when pushing to the blockchain',
                {
                    variant: 'error',
                }
            );
            closeMetaMaskLoader();
        })
        .on('receipt', () => {
            setTimeout(() => {
                enqueueSnackbar('Great! you have succefully minted your NFT', {
                    variant: 'success',
                });
                closeMetaMaskLoader();
                // deleteNftFromDB({ web3Ctx, id, userCtx });
                updateNft(web3Ctx.account, id, metadataAdded.path);
                successMint();
                collectionCtx.loadTotalSupply(collectionCtx.contract);
                setTimeout(async () => {
                    const token = await getToken(metadataAdded.path);
                    history.push(`/assets/${token[0]?.id}`);
                    history.go(0);
                }, 5000);
            }, 10000);
        });
};

const getToken = async (uri) => {
    const GET_TOKEN = gql`
        query token($uri: String) {
            erc721Tokens(where: { uri: $uri }) {
                id
            }
        }
    `;
    return await theGraphGraphqlClient.runQueryWithoutPaging(
        GET_TOKEN,
        'erc721Tokens',
        { uri }
    );
};
/*** =============================================== */
//      DELETE MINTED NFT FROM DB
/*** =============================================== */
const deleteNftFromDB = async ({ web3Ctx, id, userCtx, deleteSuccess }) => {
    await GraphqlClient.deleteFileFromStorage(web3Ctx.account, id, false);
    await GraphqlClient.deleteFileFromStorage(web3Ctx.account, id, true);

    await GraphqlClient.deleteNft(id);

    if (deleteSuccess) deleteSuccess();
    // userCtx.loadUserCreatedNftFromDB(web3Ctx.account);
};

/*** =============================================== */
//      DELETE MINTED NFT FROM DB
/*** =============================================== */
const updateNft = async (account, id, uri) => {
    await GraphqlClient.updateNft(account, id, {
        minted: true,
        file: uri,
        thumbnail: '',
    });
};

export { mintNft, deleteNftFromDB };
