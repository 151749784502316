import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import Container from '@mui/material/Container';

import PgaeBanner from '../components/general/PageBanner';
import PrivacyPolicyEn from '../helpDoc/privacyPolicyEn.md';
import PrivacyPolicyCn from '../helpDoc/privacyPolicyCn.md';
import { useTranslation } from 'react-i18next';

function Privacy() {
    const { t } = useTranslation(['mdfiles']);

    const [privacyFileEn, setprivacyPolicyTextEn] = useState('');
    const [privacyFileCn, setprivacyPolicyTextCn] = useState('');
    // privacyFileEn,privacyFileCn is rendered in <ReactMarkdown>, defined by src/locales/mdfiles.js

    useEffect(() => {
        fetch(PrivacyPolicyEn)
            .then((res) => res.text())
            .then((text) => setprivacyPolicyTextEn(text));

        fetch(PrivacyPolicyCn)
            .then((res) => res.text())
            .then((text) => setprivacyPolicyTextCn(text));
    }, []);

    return (
        <>
            <PgaeBanner heading={t('privacy', { ns: 'mdfiles' })} />
            <Container maxWidth="lg">
                <ReactMarkdown
                    children={eval(t('privacyfile', { ns: 'mdfiles' }))}
                ></ReactMarkdown>
            </Container>
        </>
    );
}

export default Privacy;
