import React, { useContext, useEffect, useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import {
    authCloseNavbar,
    configEtherScanUrl,
    navbarChangeStyle,
} from '../../helpers/utils';
import Web3Context from '../../providers/web3-context';
import MarketplaceContext from '../../providers/marketplace-context';
import UserContext from '../../providers/user-context';
import AuctionContext from '../../providers/auction-context';
import { Jazzicon } from '@ukstv/jazzicon-react';
import { settings } from '../../helpers/settings';
import Modal from './Modal';
import ToggleModeBtn from './ToggleModeBtn';
import { useTranslation } from 'react-i18next';
import SwitchLanguageContainer from '../../containers/SwitchLanguageContainer/SwitchLanguageContainer';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import { connectWalletHandler } from '../../helpers/wallet';

function Header({ netId }) {
    const [fundsLoading, setFundsLoading] = useState(false);
    const web3Ctx = useContext(Web3Context);
    const marketplaceCtx = useContext(MarketplaceContext);
    const userCtx = useContext(UserContext);
    const auctionCtx = useContext(AuctionContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userAvatar, setUserAvatar] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation(['header']);
    const history = useHistory();

    /*** =============================================== */
    //      NAVBAR CHANGING STYLE BEHAVIOR
    /*** =============================================== */
    useEffect(() => {
        navbarChangeStyle();
        authCloseNavbar();
    }, []);

    /*** =============================================== */
    //      GET USER AVATAR
    /*** =============================================== */
    useEffect(() => {
        if (userCtx.contract && userCtx.userInformation) {
            setUserAvatar(userCtx.userInformation.avatar);
        }
    }, [userCtx.contract, userCtx.userInformation]);

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
    }

    /*** =============================================== */
    //      CLAIM AUCTIONS FUNDS
    /*** =============================================== */
    const claimFundsHandler = () => {
        auctionCtx.contract.methods
            .claimProfits()
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setFundsLoading(true);
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', (receipt) => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setFundsLoading(false);
                    closeModalHandler();
                    auctionCtx.loadUserFunds(web3Ctx.account);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar('Oops! an error occurred', {
                    variant: 'error',
                });
                setFundsLoading(false);
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      CLAIM NFTS PROFITS
    /*** =============================================== */
    const claimNFTFundsHandler = () => {
        marketplaceCtx.contract.methods
            .claimProfits()
            .send({ from: web3Ctx.account })
            .on('transactionHash', (hash) => {
                setFundsLoading(true);
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', (receipt) => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    setFundsLoading(false);
                    closeModalHandler();
                    marketplaceCtx.loadUserFunds(web3Ctx.account);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar('Oops! an error occurred', {
                    variant: 'error',
                });
                setFundsLoading(false);
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      CLAIM FUNDS EVENT SUBSCRIPTION
    /*** =============================================== */
    if (!marketplaceCtx.mktIsLoading) {
        marketplaceCtx.contract.events
            .ClaimFunds()
            .on('data', (event) => {
                /*                setTimeout(() => {
                    // marketplaceCtx.loadUserFunds(web3Ctx.account);
                    //setFundsLoading(false);
                }, 10000);*/
            })
            .on('error', (error) => {
                setFundsLoading(true);
            });
    }

    if (!marketplaceCtx.mktIsLoading) {
        auctionCtx.contract.events
            .ClaimFunds()
            .on('data', (event) => {
                /*                setTimeout(() => {
                    //auctionCtx.loadUserFunds(web3Ctx.account);
                    //setFundsLoading(false);
                }, 15000);*/
            })
            .on('error', (error) => {
                setFundsLoading(true);
            });
    }

    /*** =============================================== */
    //      GET MARKETPLACE SELLERS
    /*** =============================================== */
    useEffect(() => {
        if (!marketplaceCtx.mktIsLoading) {
            marketplaceCtx.loadSellers(marketplaceCtx.contract);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [marketplaceCtx.mktIsLoading]);

    return (
        <>
            <Modal
                status={isModalOpen}
                variant="rounded-lg shadow-lg"
                modalClose={closeModalHandler}
                layout={{ width: '600px', maxWidth: '100%' }}
            >
                <div className="card-body p-5 text-center">
                    <div className="py-xl-4">
                        <p className="h3">
                            {t('earned', { ns: 'header' })}
                            <span className="mx-1 text-primary">
                                {(marketplaceCtx.userFunds +
                                    auctionCtx.userFunds) /
                                    10 ** 18}
                            </span>
                            {settings.currency}
                        </p>
                        <p className="text-muted lead mb-3">
                            {t('assetsProfit', { ns: 'header' })}
                        </p>
                        <ul className="list-unstyled mb-0 d-inline-block">
                            {auctionCtx.userFunds > 0 && (
                                <li className="mb-2 w-100">
                                    <button
                                        className="btn w-100 btn-gradient-primary"
                                        onClick={claimFundsHandler}
                                    >
                                        <span className="lh-reset">
                                            {t('collectAuctionsProfits', {
                                                ns: 'header',
                                            })}{' '}
                                            {auctionCtx.userFunds / 10 ** 18}{' '}
                                            {settings.currency}
                                        </span>
                                    </button>
                                </li>
                            )}
                            {marketplaceCtx.userFunds > 0 && (
                                <li className="mb-2 w-100">
                                    <button
                                        className="btn w-100 btn-gradient-primary"
                                        onClick={claimNFTFundsHandler}
                                    >
                                        <span className="lh-reset">
                                            {t('collectNFTsProfits', {
                                                ns: 'header',
                                            })}{' '}
                                            {marketplaceCtx.userFunds /
                                                10 ** 18}{' '}
                                            {settings.currency}
                                        </span>
                                    </button>
                                </li>
                            )}
                            <li className="w-100">
                                <button
                                    className="btn w-100 btn-dark"
                                    onClick={closeModalHandler}
                                >
                                    {t('keepForNow', { ns: 'header' })}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </Modal>

            <nav
                className="navbar navbar-expand-xl navbar-light fixed-top"
                id="navbar"
            >
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <Box
                            component={'img'}
                            className="img-fluid"
                            src={
                                marketplaceCtx.themeMode === 'dark'
                                    ? settings.UISettings.logo
                                    : settings.UISettings.logoLight
                            }
                            alt={settings.UISettings.marketplaceBrandName}
                            sx={{
                                width: { xs: 150, sm: 200 },
                                paddingLeft: { xs: '1rem', sm: 0 },
                            }}
                        />
                    </Link>

                    <Box display={'flex'}>
                        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                            <NavLink className="nav-link" to="/search">
                                <i
                                    className="las la-search"
                                    style={{ marginTop: '0.125rem' }}
                                ></i>
                            </NavLink>
                        </Box>
                        <button
                            className="navbar-toggler shadow-0"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <i className="las la-bars"></i>
                        </button>
                    </Box>

                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <ul className="navbar-nav mx-auto navbar-nav-centered">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/" exact>
                                    {t('home', { ns: 'header' })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/explore">
                                    {t('explore', { ns: 'header' })}
                                </NavLink>
                            </li>
                            {/*                            <li className="nav-item">
                                <NavLink className="nav-link" to="/community">
                                    {t('community', { ns: 'header' })}
                                </NavLink>
                            </li>*/}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/auctions">
                                    {t('auctions', { ns: 'header' })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/activity">
                                    {t('activity', { ns: 'header' })}
                                </NavLink>
                            </li>
                            <li className="nav-item d-block d-xl-none">
                                <NavLink className="nav-link" to="/sellers">
                                    {t('sellers', { ns: 'header' })}
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/mint">
                                    {t('mintNFT', { ns: 'header' })}
                                </NavLink>
                            </li>
                        </ul>

                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0 flex-lg-row align-items-lg-center">
                            <li className="nav-item">
                                <SwitchLanguageContainer />
                            </li>
                            {false && (
                                <li className="nav-item">
                                    <ToggleModeBtn />
                                </li>
                            )}
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/search">
                                    <i
                                        className="las la-search"
                                        style={{ marginTop: '0.125rem' }}
                                    ></i>
                                </NavLink>
                            </li>

                            {web3Ctx.account && (
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className="nav-link dropdown-toggle no-caret d-flex align-items-center"
                                        id="accountDropdown"
                                        to="/"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                    >
                                        <div className="bg-gray-200 p-1 rounded-pill d-flex align-items-center">
                                            {userAvatar === '' ? (
                                                userCtx.userIsRegistered ? (
                                                    <div className="author-avatar">
                                                        <span
                                                            className="author-avatar-inner"
                                                            style={{
                                                                background: `url(/images/astronaut.png)`,
                                                            }}
                                                        ></span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '35px',
                                                            height: '35px',
                                                        }}
                                                    >
                                                        <Jazzicon
                                                            address={
                                                                web3Ctx.account
                                                            }
                                                        />
                                                    </div>
                                                )
                                            ) : (
                                                <div className="author-avatar">
                                                    <span
                                                        className="author-avatar-inner"
                                                        style={{
                                                            background: `url(${userAvatar})`,
                                                        }}
                                                    ></span>
                                                </div>
                                            )}

                                            <div className="ms-2 fw-bold text-dark pe-3">
                                                {marketplaceCtx.userFunds +
                                                    auctionCtx.userFunds >
                                                0
                                                    ? (marketplaceCtx.userFunds +
                                                          auctionCtx.userFunds) /
                                                      10 ** 18
                                                    : '0'}
                                                <span className="fw-normal text-muted ms-2">
                                                    {settings.currency}
                                                </span>
                                            </div>
                                        </div>
                                    </NavLink>
                                    <ul
                                        className="dropdown-menu dropdown-menu-end fade-down text-start"
                                        aria-labelledby="accountDropdown"
                                    >
                                        <li>
                                            {false && (
                                                <a
                                                    href={configEtherScanUrl(
                                                        web3Ctx.networkId,
                                                        web3Ctx.account
                                                    )}
                                                    className="dropdown-item d-flex align-items-center"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="las la-chart-bar me-2 text-primary"></i>
                                                    {t('trackTransactions', {
                                                        ns: 'header',
                                                    })}
                                                </a>
                                            )}
                                            <Link
                                                to={`/users/${web3Ctx.account}/activity`}
                                                className="dropdown-item d-flex align-items-center"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="las la-user-circle me-2 text-primary"></i>
                                                {t('trackTransactions', {
                                                    ns: 'header',
                                                })}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={`/users/${web3Ctx.account}/creation`}
                                                className="dropdown-item d-flex align-items-center"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="las la-user-circle me-2 text-primary"></i>
                                                {t('myProfile', {
                                                    ns: 'header',
                                                })}
                                            </Link>
                                        </li>
                                        {userCtx.userIsRegistered && (
                                            <Link
                                                to={`/users/${web3Ctx.account}/setting?tab=profile`}
                                                className="dropdown-item d-flex align-items-center"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="las la-user me-2 text-primary"></i>
                                                {t('myAccount', {
                                                    ns: 'header',
                                                })}
                                            </Link>
                                        )}
                                        {(userCtx.appOwner ===
                                            web3Ctx.account ||
                                            userCtx.userInformation?.admin) &&
                                            userCtx.userIsRegistered && (
                                                <Link
                                                    to="/admin"
                                                    className="dropdown-item d-flex align-items-center"
                                                    rel="noopener noreferrer"
                                                >
                                                    <i className="las la-cog me-2 text-primary"></i>
                                                    {t('adminPanel', {
                                                        ns: 'header',
                                                    })}
                                                </Link>
                                            )}
                                        {marketplaceCtx.userFunds +
                                            auctionCtx.userFunds >
                                            0 &&
                                            !fundsLoading && (
                                                <li className="py-2 px-0">
                                                    <button
                                                        type="button"
                                                        className="btn btn-gradient-primary w-100"
                                                        onClick={() => {
                                                            setIsModalOpen(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        {t('collectProfits', {
                                                            ns: 'header',
                                                        })}
                                                    </button>
                                                </li>
                                            )}
                                    </ul>
                                </li>
                            )}
                            {!web3Ctx.account &&
                            netId === settings.networkId &&
                            window.ethereum ? (
                                <li className="nav-item nav-item ms-lg-2">
                                    <button
                                        type="button"
                                        className="btn btn-gradient-primary btn-sm px-3 py-2 d-lg-flex align-items-center"
                                        onClick={() =>
                                            connectWalletHandler(
                                                web3Ctx,
                                                userCtx
                                            )
                                        }
                                    >
                                        <i className="las la-wallet me-2 mb-2"></i>
                                        <span className="lh-reset">
                                            {t('connectYourWallet', {
                                                ns: 'header',
                                            })}
                                        </span>
                                    </button>
                                </li>
                            ) : (
                                !window.ethereum && (
                                    <div className="bg-gray-200 p-1 rounded-pill d-flex align-items-center">
                                        <div className="author-avatar">
                                            <span
                                                className="author-avatar-inner"
                                                style={{
                                                    background: `url(/images/astronaut.png)`,
                                                }}
                                            ></span>
                                        </div>

                                        <div className="ms-2 fw-bold text-dark pe-3">
                                            {t('visitor', { ns: 'header' })}
                                        </div>
                                    </div>
                                )
                            )}
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Header;
