import React, { useContext, useMemo } from 'react';
import { formatFullDate } from '../../helpers/utils';
import { Link } from 'react-router-dom';
import MarketplaceContext from '../../providers/marketplace-context';
import AuctionContext from '../../providers/auction-context';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

function NftHistory({ history, creator, mktAddress, owner, isAuction }) {
    const transferLogBoxStyle = {
        overflowY: 'scroll',
        maxHeight: '400px',
    };
    const marketplaceCtx = useContext(MarketplaceContext);
    const auctionCtx = useContext(AuctionContext);

    const aucAddress = useMemo(() => {
        return auctionCtx.contract.options.address;
    }, [auctionCtx.contract]);

    const { t } = useTranslation(['nftHistory']);

    return (
        <div className="pb-5">
            <div
                className="rounded-xl"
                style={{
                    border:
                        marketplaceCtx.themeMode === 'light'
                            ? '3px solid #e9ecef'
                            : '3px solid #282830',
                }}
            >
                <div
                    className="p-4 rounded-xl bg-white"
                    style={history.length > 4 ? transferLogBoxStyle : null}
                >
                    {history && (
                        <div
                            className={`d-flex p-2 rounded-lg bg-light ${
                                history.length !== 0 && 'mb-2'
                            }`}
                        >
                            <div className="author-avatar mt-1">
                                <span
                                    className="author-avatar-inner"
                                    style={{
                                        background: `url(${
                                            creator && !creator.avatar
                                                ? '/images/astronaut.png'
                                                : creator.avatar
                                        })`,
                                    }}
                                ></span>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 text-sm text-muted">
                                    {t('createdBy', { ns: 'nftHistory' })}
                                    <Link
                                        className="text-reset"
                                        to={`/users/${creator.account}`}
                                    >
                                        <strong className="ms-2 text-dark">
                                            {creator && !creator.name
                                                ? 'Adi Galia'
                                                : creator.name}
                                        </strong>
                                    </Link>
                                </p>
                                <p className="mb-0 text-sm text-muted">
                                    {t('at', { ns: 'nftHistory' })}
                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                        {formatFullDate(
                                            creator.time,
                                            i18next.language
                                        )}
                                    </strong>
                                </p>
                            </div>
                        </div>
                    )}

                    {history &&
                        history.map((el, index) => {
                            return (
                                <div key={index}>
                                    {el.to.account ===
                                    mktAddress.toLowerCase() ? (
                                        <div
                                            className={`d-flex p-2 rounded-lg bg-light ${
                                                index !== history.length - 1 &&
                                                'mb-2'
                                            }`}
                                            key={index}
                                        >
                                            <div className="author-avatar mt-1">
                                                <span
                                                    className="author-avatar-inner"
                                                    style={{
                                                        background: `url(/images/mkt-avatar.png)`,
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('transferredTo', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <Link
                                                        className="text-reset"
                                                        to="/"
                                                    >
                                                        <strong className="ms-2 text-dark">
                                                            {t('marketplace', {
                                                                ns: 'nftHistory',
                                                            })}
                                                        </strong>
                                                    </Link>
                                                </p>
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('at', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                                        {formatFullDate(
                                                            el.time,
                                                            i18next.language
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ) : el.from.account ===
                                      mktAddress.toLowerCase() ? (
                                        <div
                                            className={`d-flex p-2 rounded-lg bg-light ${
                                                index !== history.length - 1 &&
                                                'mb-2'
                                            }`}
                                            key={index}
                                        >
                                            <div className="author-avatar mt-1">
                                                <span
                                                    className="author-avatar-inner"
                                                    style={{
                                                        background: `url(${
                                                            !el.to.avatar
                                                                ? '/images/astronaut.png'
                                                                : el.to.avatar
                                                        })`,
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('transferredTo', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <Link
                                                        className="text-reset"
                                                        to={`/users/${el.to.account}`}
                                                    >
                                                        <strong className="ms-2 text-dark">
                                                            {!el.to.name
                                                                ? 'Adi Gallia'
                                                                : el.to.name}
                                                        </strong>
                                                    </Link>
                                                </p>
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('at', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                                        {formatFullDate(
                                                            el.time,
                                                            i18next.language
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ) : el.from.account ===
                                      mktAddress.toLowerCase() ? (
                                        <div
                                            className={`d-flex p-2 rounded-lg bg-light ${
                                                index !== history.length - 1 &&
                                                'mb-2'
                                            }`}
                                            key={index}
                                        >
                                            <div className="author-avatar mt-1">
                                                <span
                                                    className="author-avatar-inner"
                                                    style={{
                                                        background: `url(${
                                                            !el.to.avatar
                                                                ? '/images/astronaut.png'
                                                                : el.to.avatar
                                                        })`,
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('transferredTo', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <Link
                                                        className="text-reset"
                                                        to={`/users/${el.to.account}`}
                                                    >
                                                        <strong className="ms-2 text-dark">
                                                            {!el.to.name
                                                                ? 'Adi Gallia'
                                                                : el.to.name}
                                                        </strong>
                                                    </Link>
                                                </p>
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('at', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                                        {formatFullDate(
                                                            el.time,
                                                            i18next.language
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ) : el.from.account ===
                                      aucAddress.toLowerCase() ? (
                                        <div
                                            className={`d-flex p-2 rounded-lg bg-light ${
                                                index !== history.length - 1 &&
                                                'mb-2'
                                            }`}
                                            key={index}
                                        >
                                            <div className="author-avatar mt-1">
                                                <span
                                                    className="author-avatar-inner"
                                                    style={{
                                                        background: `url(${
                                                            !el.to.avatar
                                                                ? '/images/astronaut.png'
                                                                : el.to.avatar
                                                        })`,
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('transferredTo', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <Link
                                                        className="text-reset"
                                                        to={`/users/${el.to.account}`}
                                                    >
                                                        <strong className="ms-2 text-dark">
                                                            {!el.to.name
                                                                ? 'Adi Gallia'
                                                                : el.to.name}
                                                        </strong>
                                                    </Link>
                                                </p>
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('at', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                                        {formatFullDate(
                                                            el.time,
                                                            i18next.language
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ) : el.to.account ===
                                      aucAddress.toLowerCase() ? (
                                        <div
                                            className={`d-flex p-2 rounded-lg bg-light ${
                                                index !== history.length - 1 &&
                                                'mb-2'
                                            }`}
                                            key={index}
                                        >
                                            <div className="author-avatar mt-1">
                                                <span
                                                    className="author-avatar-inner"
                                                    style={{
                                                        background: `url(/images/mkt-avatar.png)`,
                                                    }}
                                                ></span>
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('transferredTo', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <Link
                                                        className="text-reset"
                                                        to="/"
                                                    >
                                                        <strong className="ms-2 text-dark">
                                                            Marketplace
                                                        </strong>
                                                    </Link>
                                                </p>
                                                <p className="mb-0 text-sm text-muted">
                                                    {t('at', {
                                                        ns: 'nftHistory',
                                                    })}
                                                    <strong className="ms-2 fw-normal text-dark text-xs">
                                                        {formatFullDate(
                                                            el.time,
                                                            i18next.language
                                                        )}
                                                    </strong>
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        el.from.account ===
                                            aucAddress.toLowerCase() && (
                                            <div
                                                className={`d-flex p-2 rounded-lg bg-light ${
                                                    index !==
                                                        history.length - 1 &&
                                                    'mb-2'
                                                }`}
                                                key={index}
                                            >
                                                <div className="author-avatar mt-1">
                                                    <span
                                                        className="author-avatar-inner"
                                                        style={{
                                                            background: `url(${
                                                                !el.to.avatar
                                                                    ? '/images/astronaut.png'
                                                                    : el.to
                                                                          .avatar
                                                            })`,
                                                        }}
                                                    ></span>
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-0 text-sm text-muted">
                                                        {t('transferredTo', {
                                                            ns: 'nftHistory',
                                                        })}
                                                        <Link
                                                            className="text-reset"
                                                            to={`/users/${el.to.account}`}
                                                        >
                                                            <strong className="ms-2 text-dark">
                                                                {!el.to.name
                                                                    ? 'Adi Gallia'
                                                                    : el.to
                                                                          .name}
                                                            </strong>
                                                        </Link>
                                                    </p>
                                                    <p className="mb-0 text-sm text-muted">
                                                        {t('at', {
                                                            ns: 'nftHistory',
                                                        })}
                                                        <strong className="ms-2 fw-normal text-dark text-xs">
                                                            {formatFullDate(
                                                                el.time,
                                                                i18next.language
                                                            )}
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
}

export default NftHistory;
