import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import web3 from '../../connect-web3/web3';
import Web3 from 'web3';
import Web3Context from '../../providers/web3-context';
import UserContext from '../../providers/user-context';
import AuctionContext from '../../providers/auction-context';
import { formatDate, formatPrice, truncateStart } from '../../helpers/utils';
import { settings } from '../../helpers/settings';
import NftCategory from './NftCategory';
import Modal from './Modal';
import AOS from 'aos';
import ReactPlayer from 'react-player';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import * as bootstrap from 'bootstrap';
// the hook
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { connectWalletHandler } from '../../helpers/wallet';
import Skeleton from '@mui/material/Skeleton';

window.bootstrap = bootstrap;

const melodyStyle = {
    fontSize: '5rem',
    color: '#fff',
    position: 'absolute',
    top: '4rem',
    left: '50%',
    transform: 'translateX(-50%)',
};

function AuctionItem({
    img,
    thumbnail,
    title,
    owner,
    user,
    category,
    dateCreated,
    tokenId,
    auctionId,
    index,
    endAt,
    nftKey,
    noAnimation,
    unlockable,
    royalties,
    bids,
    type,
    ownerName,
    ownerAvatar,
    isLoading,
}) {
    const { enqueueSnackbar } = useSnackbar();
    const web3Ctx = useContext(Web3Context);
    const auctionCtx = useContext(AuctionContext);
    const userCtx = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bidPrice, setBidPrice] = useState('');
    const [isCurrentBidder, setIsCurrentBidder] = useState(false);
    const [auctionEnded, setAuctionEnded] = useState(false);
    const [topBidder, setTopBidder] = useState('');
    const [topBid, setTopBid] = useState(0);
    const [networkId, setNetworkId] = useState(0);
    const history = useHistory();
    const previewWrapperEl = useRef(null);

    const { t } = useTranslation(['auctionItem']);

    /*** =============================================== */
    //      GET ACTIVE NETWORK ID
    /*** =============================================== */
    useEffect(() => {
        async function getNetworkId() {
            if (window.ethereum) {
                const networkId = await web3Ctx.loadNetworkId(
                    new Web3(window.ethereum)
                );
                setNetworkId(networkId);
            }
        }

        getNetworkId();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*** =============================================== */
    //      GET TOP BID
    /*** =============================================== */
    useEffect(() => {
        if (!isLoading) {
            if (bids.length > 0) {
                const auctionBids = bids
                    .filter((bid) => bid.withdraw !== true)
                    .map((bid) => bid.amount);
                if (auctionBids.length > 0) {
                    setTopBid(Math.max(...auctionBids));
                } else {
                    setTopBid(0);
                }
            } else {
                setTopBid(0);
            }
        }
    }, [bids, web3Ctx.account, isLoading]);

    /*** =============================================== */
    //      VALIDATE IF THE USER IS CURRENT BIDDER
    /*** =============================================== */
    useEffect(() => {
        if (!isLoading) {
            if (bids.length > 0) {
                const bidders = bids
                    .filter((bid) => bid.withdraw !== true)
                    .map((bid) => bid.bidder);
                if (bidders.includes(web3Ctx.account.toLowerCase())) {
                    setIsCurrentBidder(true);
                } else {
                    setIsCurrentBidder(false);
                }
            } else {
                setIsCurrentBidder(false);
            }
        }
    }, [bids, web3Ctx.account, isLoading]);

    /*** =============================================== */
    //      GET TOP BIDDER
    /*** =============================================== */
    useEffect(() => {
        if (!isLoading) {
            if (bids.filter((bid) => bid.withdraw !== true).length > 0) {
                const auctionBids = bids
                    .filter((bid) => bid.withdraw !== true)
                    .map((bid) => bid.amount);
                const maxBid = Math.max(...auctionBids);
                const topBidder = bids
                    .filter((bid) => bid.withdraw !== true)
                    .filter((bid) => bid.amount === maxBid)[0].bidder;
                setTopBidder(topBidder);
            }
        }
    }, [bids, isLoading]);

    /*** =============================================== */
    //      CHECK IF AUCTION IS STILL OPEN
    /*** =============================================== */
    useEffect(() => {
        if (endAt <= new Date().getTime()) {
            setAuctionEnded(true);
        }
    }, [endAt]);

    const Completionist = () => (
        <div className="text-center p-4 rounded-lg bg-light mt-4">
            <h6 className="text-center mb-0 fw-bold text-uppercase letter-spacing-0">
                {t('auctionEnded', { ns: 'auctionItem' })}
            </h6>
            {web3Ctx.account.toLowerCase() === topBidder && topBid > 0 && (
                <>
                    <p className="text-muted mb-2 text-center">
                        {t('winAuction', { ns: 'auctionItem' })}
                    </p>
                    <button
                        className="btn btn-primary btn-sm py-2 px-4"
                        type="button"
                        onClick={claimNFTHandler}
                    >
                        <span className="lh-reset">
                            {t('claimNFT', { ns: 'auctionItem' })}
                        </span>
                    </button>
                </>
            )}

            {topBid === 0 && web3Ctx.account.toLowerCase() === user && (
                <>
                    <p className="text-muted mb-2 text-center">
                        {t('noOneWasInterested', { ns: 'auctionItem' })}
                    </p>
                    <button
                        className="btn btn-primary btn-sm py-2 px-4"
                        type="button"
                        onClick={cancelHandler}
                    >
                        <span className="lh-reset">
                            {t('restoreNFT', { ns: 'auctionItem' })}
                        </span>
                    </button>
                </>
            )}

            {topBid > 0 && web3Ctx.account.toLowerCase() === user && (
                <>
                    <p className="text-muted mb-2 text-center">
                        {t('hasAnotherOwner', { ns: 'auctionItem' })}
                    </p>
                </>
            )}
        </div>
    );

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return <Completionist />;
        } else {
            return (
                <div className="countdown rounded-lg bg-light mt-4 mb-5">
                    <div className="countdown-item flex-fill">
                        <div className="countdown-item-number bg-white w-100">
                            {days}
                        </div>
                        <span>{t('days', { ns: 'auctionItem' })}</span>
                    </div>
                    <div className="countdown-item flex-fill">
                        <div className="countdown-item-number bg-white w-100">
                            {hours}
                        </div>
                        <span>{t('hours', { ns: 'auctionItem' })}</span>
                    </div>
                    <div className="countdown-item flex-fill">
                        <div className="countdown-item-number bg-white w-100">
                            {minutes}
                        </div>
                        <span>{t('mins', { ns: 'auctionItem' })}</span>
                    </div>
                    <div className="countdown-item flex-fill">
                        <div className="countdown-item-number bg-white w-100">
                            {seconds}
                        </div>
                        <span>{t('secs', { ns: 'auctionItem' })}</span>
                    </div>
                </div>
            );
        }
    };

    useEffect(() => {
        AOS.init({ duration: 1000, offset: 10 });
    }, []);

    useEffect(() => {
        if (previewWrapperEl.current && type === 'audio' && thumbnail) {
            previewWrapperEl.current.style.backgroundImage = `url(${process.env.REACT_APP_IPFS_GATEWAY}${thumbnail})`;
            previewWrapperEl.current.style.backgroundSize = 'cover';
            previewWrapperEl.current.style.backgroundPosition = 'center center';
        }
    }, [previewWrapperEl]);

    /*** =============================================== */
    //      DECLARE PRICE REFERENCE
    /*** =============================================== */
    const priceRef = useRef(null);

    /*** =============================================== */
    //      CANCEL AUCTION FUNCTION
    /*** =============================================== */
    const cancelHandler = (event) => {
        auctionCtx.contract.methods
            .cancelAuction(tokenId, auctionId)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    history.push(`/assets/${tokenId}`);
                }, 15000);
            })
            .on('error', (error) => {
                auctionCtx.setAuctionTransactionLoading(false);
                enqueueSnackbar(t('error', { ns: 'auctionItem' }), {
                    variant: 'error',
                });
            });
    };

    /*** =============================================== */
    //      PLACE BID FUNCTION
    /*** =============================================== */
    const placeBidHandler = (event, nftKey) => {
        event.preventDefault();

        const enteredPrice = web3.utils.toWei(priceRef.current.value, 'ether');

        auctionCtx.contract.methods
            .bid(tokenId, auctionId, enteredPrice)
            .send({ from: web3Ctx.account, value: enteredPrice })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
                setIsModalOpen(false);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    history.push(`/nftauction/${tokenId}`);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar(t('error', { ns: 'auctionItem' }), {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      WITHDRAW BID FUNCTION
    /*** =============================================== */
    const withdrawBidHandler = (event) => {
        event.preventDefault();

        auctionCtx.contract.methods
            .withdraw(tokenId, auctionId)
            .send({ from: web3Ctx.account })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    history.push(`/nftauction/${tokenId}`);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar(t('error', { ns: 'auctionItem' }), {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    /*** =============================================== */
    //      CLAIM WINNDED NFT
    /*** =============================================== */
    const claimNFTHandler = (event) => {
        event.preventDefault();

        auctionCtx.contract.methods
            .endAuction(tokenId, auctionId)
            .send({
                from: topBidder,
            })
            .once('sending', () => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('transactionHash', (hash) => {
                auctionCtx.setAuctionTransactionLoading(true);
            })
            .on('receipt', () => {
                setTimeout(() => {
                    auctionCtx.setAuctionTransactionLoading(false);
                    history.push(`/assets/${tokenId}`);
                }, 15000);
            })
            .on('error', (error) => {
                enqueueSnackbar(t('error', { ns: 'auctionItem' }), {
                    variant: 'error',
                });
                auctionCtx.setAuctionTransactionLoading(false);
            });
    };

    // claimNFTHandler();

    /*** =============================================== */

    //      CLOSE MODAL FUNCTION
    /*** =============================================== */
    function closeModalHandler() {
        setIsModalOpen(false);
    }

    const renderPreview = () => {
        switch (type) {
            case 'image':
                return (
                    <div
                        className="w-100 h-100 card-img-holder-inner"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_IPFS_GATEWAY}${img})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    ></div>
                );
            case 'audio':
                return (
                    <>
                        <i className="las la-music" style={melodyStyle}></i>
                        <AudioPlayer
                            src={`${process.env.REACT_APP_IPFS_GATEWAY}${img}`}
                            autoPlayAfterSrcChange={false}
                            showJumpControls={false}
                        />
                    </>
                );
            case 'video':
                return (
                    <ReactPlayer
                        url={`${process.env.REACT_APP_IPFS_GATEWAY}${img}`}
                        controls={true}
                        width="100%"
                        height="auto"
                    />
                );
            case '3d':
                return (
                    <div
                        className="w-100 h-100 card-img-holder-inner"
                        style={{
                            backgroundImage: `url(${process.env.REACT_APP_IPFS_GATEWAY}${thumbnail})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                        }}
                    ></div>
                );
            default:
                return null;
        }
    };

    return (
        <>
            <div
                className={`card rounded card-hover-image position-relative ${category}`}
                data-aos={`${noAnimation ? '' : 'fade-up'}`}
                data-aos-once="true"
                data-aos-delay={(nftKey + 1) * 100}
            >
                <div className="card-body p-3 position-relative">
                    <div className="position-relative mb-4 shadow">
                        <div
                            ref={previewWrapperEl}
                            className={
                                'card-img-holder rounded overflow-hidden'
                            }
                        >
                            {isLoading ? (
                                <Skeleton
                                    animation="wave"
                                    width={'100vw'}
                                    height={'100vh'}
                                />
                            ) : (
                                renderPreview()
                            )}
                        </div>

                        {unlockable !== '' &&
                            owner === web3Ctx.account?.toLowerCase() && (
                                <div className="position-absolute top-0 end-0 m-3">
                                    {isLoading ? (
                                        <Skeleton animation="wave" />
                                    ) : (
                                        <a
                                            href={unlockable}
                                            className="btn btn-info px-3"
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            <i className="las la-cloud"></i>
                                        </a>
                                    )}
                                </div>
                            )}
                    </div>

                    <div className="fw-bold lead mb-3 d-flex align-items-center justify-content-between">
                        {isLoading ? (
                            <Skeleton animation={'wave'} width={60} />
                        ) : (
                            <Link
                                className="text-reset"
                                to={`/nftauction/${tokenId}`}
                            >
                                {truncateStart(title, 25)}
                            </Link>
                        )}

                        {isLoading ? (
                            <Skeleton animation={'wave'} width={50} />
                        ) : (
                            <div className="ms-3">
                                <NftCategory category={category} />
                            </div>
                        )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                        <div className="author position-static z-index-20 d-flex align-items-center">
                            <Link className="text-reset" to={`/users/${user}`}>
                                {isLoading ? (
                                    <Skeleton
                                        variant={'circular'}
                                        width={40}
                                        height={40}
                                    />
                                ) : (
                                    <div className="author-avatar">
                                        <span
                                            className="author-avatar-inner"
                                            style={{
                                                background: `url(${
                                                    ownerAvatar
                                                        ? ownerAvatar
                                                        : '/images/astronaut.png'
                                                })`,
                                            }}
                                        ></span>
                                    </div>
                                )}
                            </Link>
                            <div className="ms-2">
                                <p className="text-muted fw-normal mb-0 lh-1">
                                    <span className="text-xs">
                                        {t('ownedBy', { ns: 'auctionItem' })}
                                    </span>
                                    <strong className="d-block fw-bold h6 text-dark mb-0">
                                        {isLoading ? (
                                            <Skeleton
                                                animation={'wave'}
                                                width={40}
                                            />
                                        ) : (
                                            <Link
                                                className="text-reset"
                                                to={`/users/${user}`}
                                            >
                                                {truncateStart(ownerName, 10)}
                                            </Link>
                                        )}
                                    </strong>
                                </p>
                            </div>
                        </div>

                        <p className="text-muted fw-normal mb-0 lh-1">
                            <span className="text-xs">
                                {t('highestBid', { ns: 'auctionItem' })}
                            </span>
                            {isLoading ? (
                                <Skeleton
                                    animation={'wave'}
                                    width={70}
                                    className="d-block fw-bold lead text-dark h2 mb-0"
                                />
                            ) : index !== -1 ? (
                                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                    {topBid > 0
                                        ? formatPrice(topBid).toFixed(3)
                                        : 0}{' '}
                                    {settings.currency}
                                </strong>
                            ) : owner === web3Ctx.account.toLowerCase() ? (
                                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                    {t('notSet', { ns: 'auctionItem' })}
                                </strong>
                            ) : (
                                <strong className="d-block fw-bold lead text-dark h2 mb-0">
                                    {t('notSet', { ns: 'auctionItem' })}
                                </strong>
                            )}
                        </p>
                    </div>
                    {index !== -1 ? (
                        user !== web3Ctx.account?.toLowerCase() ? (
                            <>
                                <div className="card-ribbon top-0 mt-4 pt-2">
                                    {auctionEnded !== true ? (
                                        <span className="bg-danger px-2 py-1 rounded-sm">
                                            {t('openForBids', {
                                                ns: 'auctionItem',
                                            })}
                                        </span>
                                    ) : (
                                        <span className="bg-danger px-2 py-1 rounded-sm">
                                            {t('notForSale', {
                                                ns: 'auctionItem',
                                            })}
                                        </span>
                                    )}
                                    {unlockable !== '' && (
                                        <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                            {t('unlockable', {
                                                ns: 'auctionItem',
                                            })}
                                        </span>
                                    )}
                                </div>
                                {!isLoading && (
                                    <div className="card-action">
                                        {auctionEnded !== true && (
                                            <>
                                                {userCtx.userIsRegistered ? (
                                                    <>
                                                        {!isCurrentBidder && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-primary text-nowrap"
                                                                value={index}
                                                                onClick={() => {
                                                                    setIsModalOpen(
                                                                        true
                                                                    );
                                                                }}
                                                            >
                                                                <i className="lab la-ethereum me-2"></i>
                                                                {t('placeBid', {
                                                                    ns: 'auctionItem',
                                                                })}
                                                            </button>
                                                        )}
                                                        {isCurrentBidder && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-danger text-nowrap"
                                                                value={index}
                                                                onClick={
                                                                    withdrawBidHandler
                                                                }
                                                            >
                                                                <i className="lab la-ethereum me-2"></i>
                                                                {t(
                                                                    'withdrawBid',
                                                                    {
                                                                        ns: 'auctionItem',
                                                                    }
                                                                )}
                                                            </button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {window.ethereum &&
                                                            networkId ===
                                                                settings.networkId && (
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-gradient-primary btn-sm px-3 py-2 d-lg-flex align-items-center"
                                                                    onClick={() =>
                                                                        connectWalletHandler(
                                                                            web3Ctx,
                                                                            userCtx
                                                                        )
                                                                    }
                                                                >
                                                                    <i className="las la-wallet me-2 mb-2"></i>
                                                                    <span className="lh-reset">
                                                                        {t(
                                                                            'connectWalletToBid',
                                                                            {
                                                                                ns: 'auctionItem',
                                                                            }
                                                                        )}
                                                                    </span>
                                                                </button>
                                                            )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                )}

                                <Modal
                                    status={isModalOpen}
                                    variant="modal-card-inner"
                                    modalClose={closeModalHandler}
                                    layout={{
                                        width: '400px',
                                        maxWidth: '100%',
                                    }}
                                >
                                    <div className="card-body text-center py-lg-5">
                                        <form
                                            onSubmit={(e) =>
                                                placeBidHandler(e, nftKey)
                                            }
                                        >
                                            <input
                                                type="number"
                                                step="0.001"
                                                min="0.0000000000000000000000001"
                                                placeholder={`Price with ${settings.currency}...`}
                                                className="form-control mb-2"
                                                required={true}
                                                autoFocus={true}
                                                ref={priceRef}
                                                value={bidPrice}
                                                onChange={(e) =>
                                                    setBidPrice(e.target.value)
                                                }
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-primary w-100 rounded-sm mb-2"
                                            >
                                                {t('placeBid', {
                                                    ns: 'auctionItem',
                                                })}
                                            </button>
                                            <p className="mb-0 text-center text-muted">
                                                {t('findFunds', {
                                                    ns: 'auctionItem',
                                                })}{' '}
                                                <span className="text-primary">
                                                    {t('myAccount', {
                                                        ns: 'auctionItem',
                                                    })}
                                                </span>{' '}
                                                {t('page', {
                                                    ns: 'auctionItem',
                                                })}
                                            </p>
                                        </form>
                                    </div>
                                </Modal>
                            </>
                        ) : (
                            <>
                                {auctionEnded !== true && (
                                    <div className="card-action">
                                        <button
                                            type="button"
                                            value={nftKey}
                                            className="btn btn-danger text-nowrap"
                                            onClick={cancelHandler}
                                        >
                                            {t('cancelAuction', {
                                                ns: 'auctionItem',
                                            })}
                                        </button>
                                    </div>
                                )}
                            </>
                        )
                    ) : (
                        <>
                            <div className="card-ribbon top-0 mt-4 pt-2">
                                <span className="bg-danger px-2 py-1 rounded-sm">
                                    {t('notForSale2', { ns: 'auctionItem' })}
                                </span>{' '}
                                {unlockable !== '' && (
                                    <span className="px-2 py-1 rounded-sm bg-dark text-white ms-1">
                                        {t('unlockable', { ns: 'auctionItem' })}
                                    </span>
                                )}
                            </div>
                        </>
                    )}

                    {isLoading ? (
                        <Skeleton
                            animation={'wave'}
                            height={100}
                            width={'100%'}
                        />
                    ) : (
                        <Countdown
                            date={endAt}
                            renderer={renderer}
                            onComplete={() => setAuctionEnded(true)}
                        />
                    )}

                    <div className="text-muted fw-normaltext-sm d-flex align-items-center mt-4 justify-content-between">
                        <p className="mb-0 text-xs d-flex align-items-center">
                            <i className="las la-percentage me-1"></i>
                            <span className="me-1 text-primary">
                                {royalties}%
                            </span>
                            {t('royalties', { ns: 'auctionItem' })}
                        </p>
                        <p className="text-xs mb-0 d-flex align-items-center">
                            <i className="la-sm text-primary las la-clock mx-1 text-primary"></i>
                            {formatDate(dateCreated).count}{' '}
                            {t(formatDate(dateCreated).unit, {
                                ns: 'auctionItem',
                            })}
                            {t('ago', { ns: 'auctionItem' })}
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AuctionItem;
